import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';

import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';
import {ChipsModule} from 'primeng/chips';
import {DropdownModule} from 'primeng/dropdown';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {SidebarModule} from 'primeng/sidebar';
import {MenuModule} from 'primeng/menu';
import {TableModule} from 'primeng/table';
import {BadgeModule} from 'primeng/badge';

import {ToastModule} from 'primeng/toast';

import {AppRoutingModule} from './app-routing.module';

import {TabMenuModule} from 'primeng/tabmenu';

import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {FileUploadModule} from 'primeng/fileupload';

import {SliderModule} from 'primeng/slider';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {RatingModule} from 'primeng/rating';
import {ToolbarModule} from 'primeng/toolbar';

import {PasswordModule} from 'primeng/password';
import {CardModule} from 'primeng/card';

import {PublicModule} from './modules/public/public.module';
import {ProtectedModule} from './modules/protected/protected.module';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {AvatarModule} from 'primeng/avatar';
import {MenubarModule} from 'primeng/menubar';
import {AccordionModule} from 'primeng/accordion';
import {ListboxModule} from 'primeng/listbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SpeedDialModule} from 'primeng/speeddial';
import {DockModule} from 'primeng/dock';
import {TagModule} from 'primeng/tag';
import {EditorModule} from 'primeng/editor';
import {CoreModule} from "./core/core.module";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthGuardService} from "./core/service/auth-guard.service";
import {PanelComponent} from "./shared/panel/panel.component";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {MegaMenuModule} from "primeng/megamenu";
import {BrowserModule} from "@angular/platform-browser";

@NgModule({
    imports: [
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserModule,
        CoreModule.forRoot(),
        BrowserAnimationsModule,
        AutoCompleteModule,
        FormsModule,
        HttpClientModule,
        CalendarModule,
        ChipsModule,
        DropdownModule,
        InputMaskModule,
        InputNumberModule,
        CascadeSelectModule,
        MultiSelectModule,
        BadgeModule,
        TagModule,
        InputTextareaModule,
        InputTextModule,
        SidebarModule,
        MenuModule,
        AppRoutingModule,
        TableModule,
        ToastModule,
        ButtonModule,
        RippleModule,
        FormsModule,
        FileUploadModule,
        TabMenuModule,
        SliderModule,
        ContextMenuModule,
        DialogModule,
        ProgressBarModule,
        RatingModule,
        PasswordModule,
        CardModule,
        ToolbarModule,
        PublicModule,
        ProtectedModule,
        RadioButtonModule,
        ConfirmDialogModule,
        AvatarModule,
        MenubarModule,
        AccordionModule,
        ListboxModule,
        SelectButtonModule,
        OverlayPanelModule,
        SpeedDialModule,
        DockModule,
        EditorModule,
        ConfirmPopupModule,
        MegaMenuModule
    ],
  declarations: [
    AppComponent,
    PanelComponent
  ],providers: [AuthGuardService],
  bootstrap: [AppComponent],
  exports: [PanelComponent]

})

export class AppModule {
}


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
