import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { io, Socket } from "socket.io-client";
import { BehaviorSubject, Observable } from 'rxjs';
import { SwPush } from '@angular/service-worker';
import {Notification} from "../models/notification.model";
import {SocketService} from "./socket.service";
import {OtherService} from "./other.service";
import {User} from "../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class NotificationService{
  private notificationUrl = '/notifications/';
  public base_url_socket = localStorage.getItem("BASE_URL_SOCKET");
  public socket: SocketService | Socket | undefined = undefined;
  public message: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly VAPID_PUBLIC_KEY = "BAKIX3dgP3RqPA6Tee0_X9hu-oRXXw7kfSSQQsC9rqJJzQV4eDhUWYeRZOaRsMKuhXrzEPVecZeiAxpILw9cPWs";

  public activateWebsocket = false // if you want to activate websocket make this variable to true

  constructor(private http: HttpClient, private swPush: SwPush, private otherService: OtherService) {
    if(this.activateWebsocket){
      if(this.base_url_socket.indexOf('localhost') == -1){
        //websocket api gateway v2
        let stage = this.base_url_socket.substr(this.base_url_socket.indexOf(".com/") + 5, this.base_url_socket.length);
        let url = this.base_url_socket.replace("/" + stage, "");
        this.socket = new SocketService(url, stage);
        console.log("Endpoint socket: websocket api gateway v2");
      }else{
        //websocket localhost
        this.socket = io(this.base_url_socket);
        console.log("Endpoint socket: localhost");
      }
    }
  }

  sendMessage(msg: string) {
    this.socket.emit('sendMessage', msg);
  }

  getAllMessage(){
    console.log("retrieve all messages")
  }

  disconnect(){
    if(this.activateWebsocket){
      this.socket.disconnect();
    }
  }

  connect(){
    const user: User = this.otherService.getUser()
    if(user != null){
      this.socket.connect();
      if (!(this.socket instanceof SocketService)) {
        this.socket.emit('user', user._id);
      }else{
        console.log("trying to send on event user");
        this.socket.emit('message', {
          action: "user",
          data: user._id
        });
        console.log("we have been sent");
      }
      this.socket.on('connect', () => {
        if (!(this.socket instanceof SocketService)) {
          this.pushSubscription(this.socket.id);
        }
      });
    }else{
      console.log("No user found");
    }
  }

  getMessage() {
    this.socket.on('message', (message) =>{
      console.log('message: ', message)
      this.message.next(message);
    });

    return this.message.asObservable();
  }

  findNotificationByConnectionId(connectionId: string){
    let params = new HttpParams();
    params.append('connectionId', connectionId);
    return this.http.get<any>(this.notificationUrl, {params}).toPromise();
  }

  updateNotification(notification: Notification){
    return this.http.put(this.notificationUrl, notification).toPromise();
  }

  pushSubscription(socket_id: string) {
    if (!this.swPush.isEnabled) {
      console.log("swPush not enabled");
      return;
    }
    console.log("swPush is enabled");
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    }).then(async (sub) => {
      //save this sub in the database
      if (socket_id != undefined) {
        console.log("Client Socket ID is: ", socket_id);
        //find by connection id and update data
        await this.findNotificationByConnectionId(socket_id).then(async (notification: Notification[]) => {
          if (notification.length > 0) {
            notification[0].subscription = sub;
            await this.updateNotification(notification[0]);
          }
        });
      }
      console.log("subscription: ", sub);
    }, (rejected) => {
      console.log("rejected subscription: ",rejected)
    }).catch((err) => {
      console.log(err);
    });
  }

}
