import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Partner} from '../models/partner.model';


@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  public partnerUrl = '/partners/';

  constructor(private http: HttpClient) { }

  getAllPartner(limit: any, page: any, companyId: any) {
    let params = new HttpParams();
    params = params.append('limit' , limit);
    params = params.append('page' , page);
    params = params.append('companyId', companyId);
    return this.http.get<any>(this.partnerUrl, {params}).toPromise();
  }
  getPartnerById(id: any) {
    return this.http.get<any>(this.partnerUrl + id).toPromise();
  }
  addPartner(partner: Partner) {
    return this.http.post(this.partnerUrl, partner).toPromise();
  }
  updatePartner(partner: Partner) {
    return this.http.put(this.partnerUrl, partner).toPromise();
  }
  deletePartner(partnerId: string) {
    return this.http.delete(this.partnerUrl + partnerId).toPromise();
  }

}
