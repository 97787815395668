import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class BicService {

  constructor(private http: HttpClient) { }

  getBics() {
    return this.http.get<any>('../assets/json/bics.json')
      .toPromise()
      .then(res => <any[]>res)
      .then(data => { return data; });
  }
}
