import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CurrencyService {

  constructor(private http: HttpClient) { }

  getCurrencies() {
    return this.http.get<any>('../assets/json/currencies.json')
      .toPromise()
      .then(res => <any[]>res)
      .then(data => { return data; });
  }
}
