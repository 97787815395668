import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Employee} from "../models/employee.model";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService{
  public EmployeeUrl = '/employees/';

  constructor(private http: HttpClient) {
  }

  getAllEmployees(limit:any,page:any, companyId:any){
    let params = new HttpParams();
    params = params.append('limit', limit);
    params = params.append('page', page);
    params = params.append('companyId', companyId);
    return this.http.get<any>(this.EmployeeUrl, {params}).toPromise();
  }

  getEmployeeById(id: string){
    return this.http.get<any>(this.EmployeeUrl + id).toPromise();
  }

  updateEmployee(employee: Employee){
    return this.http.put(this.EmployeeUrl, employee).toPromise();
  }

  deleteEmployee(id : string){
    return this.http.delete(this.EmployeeUrl + id).toPromise();
  }

  insertEmployee(employee: Employee){
    return this.http.post(this.EmployeeUrl, employee).toPromise();
  }

  findEmployeeByEmail(email: string){
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.get<any>(this.EmployeeUrl, {params}).toPromise();
  }
}
