import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './login/signup/signup.component';
import {ResetComponent} from './login/reset/reset.component';
import {SidebarModule} from 'primeng/sidebar';
import {MenuModule} from 'primeng/menu';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {CalendarModule} from 'primeng/calendar';
import {ChipsModule} from 'primeng/chips';
import {DropdownModule} from 'primeng/dropdown';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {FileUploadModule} from 'primeng/fileupload';
import {TabMenuModule} from 'primeng/tabmenu';
import {SliderModule} from 'primeng/slider';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {RatingModule} from 'primeng/rating';
import {PasswordModule} from 'primeng/password';
import {CardModule} from 'primeng/card';
import {PublicRoutingModule} from './public-routing.module';
import {ToolbarModule} from 'primeng/toolbar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {AvatarModule} from 'primeng/avatar';
import {BadgeModule} from 'primeng/badge';
import {DockModule} from 'primeng/dock';
import {TranslateModule, TranslateLoader} from "@ngx-translate/core";
import {MessageService} from "primeng/api";
import {HttpLoaderFactory} from "../../app.module";
import {ProgressSpinnerModule} from "primeng/progressspinner";


@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ResetComponent
  ],
  exports: [
    LoginComponent
  ],
  providers: [MessageService],
    imports: [
        PublicRoutingModule,
        BadgeModule,
        AutoCompleteModule,
        FormsModule,
        HttpClientModule,
        CalendarModule,
        ChipsModule,
        DropdownModule,
        InputMaskModule,
        InputNumberModule,
        CascadeSelectModule,
        MultiSelectModule,
        InputTextareaModule,
        InputTextModule,
        SidebarModule,
        MenuModule,
        TableModule,
        ToastModule,
        ButtonModule,
        RippleModule,
        FormsModule,
        FileUploadModule,
        TabMenuModule,
        SliderModule,
        ContextMenuModule,
        DialogModule,
        ProgressBarModule,
        RatingModule,
        PasswordModule,
        CardModule,
        CommonModule,
        SidebarModule,
        MenuModule,
        ReactiveFormsModule,
        ToolbarModule,
        OverlayPanelModule,
        AvatarModule,
        DockModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ProgressSpinnerModule
    ]
})
export class PublicModule {
}

