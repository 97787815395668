export class Accounting {
  public _id: string | undefined;

  public company: string;
  public companies: string[] | undefined;
  public rh: any | undefined;
  public accounting: any | undefined;
  public status: any | undefined;
  public validity: string | Date | undefined;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;
  constructor(company_id: string) {
    this.company = company_id;
    this.companies = [];
  }
}
