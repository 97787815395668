import {ISigConf} from "./signer.model";

export class IStep {
  public status: {
    code: number,
    name: string
  };
  public sigConf: ISigConf[];
  public createdAt: string | Date | undefined;

  constructor() {
    this.sigConf = [];
  }
}
