import {Injectable} from '@angular/core';
import {Amplify, Auth} from 'aws-amplify';
import {File} from "../models/file.model";
import {sha256} from "js-sha256";
import Storage from "@aws-amplify/storage";


@Injectable({
  providedIn: 'root'
})

export class AmplifyService {

  config: any;

  constructor() {
  }

  signIn(username: any, password: any) {
    return Auth.signIn(username, password);
  }

  signUp(username: any, password: any, email: any) {
    return Auth.signUp({
      username, password,
      attributes: {email}
    });
  }

  signOut() {
    let language;
    let base_url;
    let base_url_socket;
    try {
      language = localStorage.getItem("language");
      base_url = localStorage.getItem("BASE_URL");
      base_url_socket = localStorage.getItem("BASE_URL_SOCKET");
    } catch (err) {
      console.log(err);
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("language", language);
      localStorage.setItem("BASE_URL", base_url);
      localStorage.setItem("BASE_URL_SOCKET", base_url_socket);
      return Auth.signOut();
    }

  }

  sendForgotPassword(username: any) {
    return Auth.forgotPassword(username);
  }

  updatePassword(oldPassword: string ,password: string): Promise<any>{
    return new Promise<any>((async (resolve, reject) => {
      this.getCurrentUser().then((user) => {
        Auth.changePassword(user, oldPassword, password).then((result) => {
          resolve(result);
        }).catch((err)=> {
          reject(err);
        });
      }).catch((err) => {
        reject(err);
      });
    }));
  }

  changePassword(username: any, code: any, newPassword: any) {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  getCurrentUser() {
    return Auth.currentAuthenticatedUser();
  }

  getUserInfo() {
    return Auth.currentUserInfo();
  }

  upload(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      fetch('../../../config/config.json').then(res => {
        res.json().then((res) => {
          Auth.configure(res.amplify.Auth);
          this.config = res.amplify;
          let path = new Date().getUTCFullYear() + "/" + Number(new Date().getUTCMonth() + 1) + "/" + new Date().getUTCDate() + "/"
          this.config.Storage.customPrefix.public += path;
          Amplify.configure(this.config);
        })
          .then(() => {
            this.getCurrentUser()
              .then((user) => {
                try {
                  let extension='';
                  let hash = sha256.hmac(new Date(Date.now()).toString(), file.name);
                  JSON.parse(JSON.stringify(file)).hash = hash;

                  //if type different -1 found, the extension image display dans s3
                  if( file.type.indexOf("image/")!= -1){
                    extension = file.type.substr(6);
                    Storage.put(hash+'.'+extension, file, this.config);
                  }

                  //if type different -1 found, the extension  pdf display dans s3
                  if( file.type.indexOf("application/pdf")!= -1){
                    Storage.put(hash+'.pdf', file, this.config);
                  }
                  resolve({path: this.config.Storage.customPrefix.public, fileHash: hash});
                } catch (err) {
                  reject(err);
                }
              })
              .catch((err) => {
                reject(err);
              });
          });
      });
    });
  }

  uploadFromVariable(file: any){
    return new Promise<any>((resolve, reject) => {
      fetch('../../../config/config.json').then((rep) => {
        let path = new Date().getUTCFullYear() + "/" + Number(new Date().getUTCMonth() + 1) + "/" + new Date().getUTCDate() + "/"
        let conf;
        rep.json().then((res) => {
          conf = res.amplify;
          conf.Storage.customPrefix.public += path;
          Amplify.configure(conf);
        }).then(() => {
          let hash = sha256.hmac(new Date(Date.now()).toString(), "file.pdf")
          Storage.put(hash + '.pdf', file, conf);
          resolve({path: conf.Storage.customPrefix.public, fileHash: hash});
        });
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async deleteFile(fileName: string, pathFile: string, level: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      fetch('../../../config/config.json').then(res => {
        res.json().then((res) => {
          Auth.configure(res.amplify.Auth);
          this.config = res.amplify;
          Amplify.configure(this.config);
        }).then(() => {
          this.getCurrentUser()
            .then(async (user) => {
              await Storage.remove(pathFile + fileName, {level: level.toLowerCase()}).then((data) => {
                resolve(data);
              }).catch((err) => {
                console.log(err);
                reject(err);
              });
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            })
            .catch((e) => {
              console.log(e);
              reject(e);
            })
        });
      });
    });
  }


  async getFile(fileName: string, pathFile: string, level: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      fetch('../../../config/config.json').then(res => {
        res.json().then((res) => {
          Auth.configure(res.amplify.Auth);
          this.config = res.amplify;
          Amplify.configure(this.config);
        }).then(() => {
          console.warn("pathFile",pathFile ,"fileName",fileName);
          Storage.get(pathFile + fileName, {level: level.toLowerCase()}).then((data) => {
            resolve(data);
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
        })
          .catch((e) => {
            console.log(e);
            reject(e);
          })
      });
    });
  }
}
