import { Component, OnInit } from "@angular/core";
import { AccountService } from "portago/src/app/core/service/account.service";
import { CompanyService } from "portago/src/app/core/service/company.service";
import { OperationService } from "portago/src/app/core/service/operation.service";
import { MessageService, ConfirmationService } from "primeng/api";
import { DialogService, DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.css'],
    providers: [DialogService, OperationService, MessageService, CompanyService, AccountService, ConfirmationService, DynamicDialogConfig],

})

export class ProjectComponent implements OnInit {
    ngOnInit(): void {
    }
}