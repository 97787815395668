import {Employee} from "./employee.model";
import {Company} from "./company.model";

export class CRA{
  public _id: string | undefined;

  public employee_id: string;
  public company_id: string;
  public employee: Employee;
  public company: Company;
  public worked: number | undefined;
  public leave_took: number | undefined;
  public description: string | undefined;
  public files: string[];
  public contract_id: string | undefined;
  public invoice_id: string | undefined;
  public status: {name: string, code: number} | undefined;
  public from : string | Date | undefined;
  public to: string | Date | undefined;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.files = [];
    this.employee = new Employee();
    this.company = new Company();
  }
}
