import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Operation} from '../models/operation.model';
import {Account} from '../models/account.model';


@Injectable({
  providedIn: 'root'
})
export class OperationService {
  public operationUrl = '/operations/';

  constructor(private http: HttpClient) {
  }

  getAllOperation(limit: any, page: any, accountId: any, shortDesc?: any, operationDate?: any, valueDate?: any, amount?: any, bankName?: any) {
    let params = new HttpParams();
    params = params.append('limit', limit);
    params = params.append('page', page);
    params = params.append('accountId', accountId);
    if(shortDesc != undefined){
      params = params.append('shortDesc', shortDesc);
    }
    if(operationDate != undefined){
      params = params.append('operationDate', operationDate);
    }
    if(valueDate != undefined){
      params = params.append('valueDate', valueDate);
    }
    if(amount != undefined){
      params = params.append('amount', amount);
    }
    if(bankName != undefined){
      params = params.append('bankName', bankName);
    }
    return this.http.get<any>(this.operationUrl, {params}).toPromise();
  }

  getOperationById(id: any) {
    return this.http.get<any>(this.operationUrl + id).toPromise();
  }

  addOperation(operation: Operation) {
    return this.http.post(this.operationUrl, operation).toPromise();
  }

  editOperation(operation: Operation) {
    return this.http.put(this.operationUrl, operation).toPromise();
  }

  deleteOperation(operationId: string) {
    return this.http.delete(this.operationUrl + operationId).toPromise();
  }

  findOneOperation(operationDate: any, valueDate: any, amount: any){
    let params = new HttpParams();
    params = params.append('operationDate', operationDate);
    params = params.append('valueDate', valueDate);
    params = params.append('amount', amount);
    return this.http.get(this.operationUrl , {params}).toPromise();
  }
}
