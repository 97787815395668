import {Component, OnInit} from '@angular/core';
import {AccountService} from "../../../core/service/account.service";
import {Account} from "../../../core/models/account.model";
import {Chart, ChartOptions, ChartType, Color, registerables} from 'chart.js';
import {OperationService} from "../../../core/service/operation.service";
import {ActivatedRoute, Params} from "@angular/router";
import {Operation} from "../../../core/models/operation.model";
import {CRA} from "../../../core/models/cra.model";
import {Invoice} from "../../../core/models/invoice.model";
import {charts} from "highcharts";
import {config} from "rxjs";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  account_id: string;
  from: Date;
  to: Date;
  result: any;
  obj1: any;
  data: any;
  chart: any = [];
  accountTypeData = [];
  accountAmountData = [];
  accountAccountIdDataLabel = [];
  public accountUrl = '/accounts/';
  public operationUrl = 'operations/';
  private page: number;
  graphScriptLoaded: boolean = false;
  myChart: any;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private operationService: OperationService)
  {
    this.from =new Date( '01/01/2021');
    this.to =new Date( '12/31/2021');
  }

  multiAxisData: any;
  multiAxisData1: any;
  accounts: Account[];
  account: Account;
  barChartLabels: any;
  barChartData: any;
  barChartLegend: any;
  barChartType: any;

  async ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.account_id = params['accountId'];

      }
    );
    console.log(this.account_id);
    Chart.register(...registerables);
    this.myChart = undefined;
  }

  async update($event: MouseEvent) {
    if(this.myChart != undefined){
      this.myChart.destroy();
    }
    let data: any = await this.accountService.getOperationStatisticsFromTo(this.account_id, this.from, this.to);
    console.log("account", this.account_id);
    console.log("from", this.from);
    console.log("to", this.to);
    console.log("this dataFromTo", data)
    const canvas = <HTMLCanvasElement>document.getElementById('myChart');
    const ctx = canvas.getContext('2d');
    this.myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data.map(x => x.y),
        datasets: [{
          label: 'type of sum amount',
          data: data.map(x => x.x),
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      }
    });
  }
}
