import {Component, Input, OnInit, EventEmitter} from '@angular/core';
import {Invoice, IStep} from "../../../core/models/invoice.model";
import {TranslateService} from "@ngx-translate/core";
import {InvoiceService} from '../../../core/service/invoice.service';
import {Map} from "../../../core/models/otherModels/map.model";
import {CompanyService} from "../../../core/service/company.service";
import {ContractService} from "../../../core/service/contract.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {OtherService} from '../../../core/service/other.service'
import {TemplateService} from "../../../core/service/template.service";
import {Company} from "../../../core/models/company.model";
import {Contract} from "../../../core/models/contract.model";
import {User} from "../../../core/models/user.model";
import {Template} from "../../../core/models/template.model";
import {File} from "../../../core/models/file.model";
import {FileService} from "../../../core/service/file.service";
import {AmplifyService} from "../../../core/service/amplify-service";
import {Payment} from "../../../core/models/otherModels/payment.model";
import {PayLink} from "../../../core/models/otherModels/payLink.model";
import {EmployeeService} from "../../../core/service/employee.service";
import {Employee} from "../../../core/models/employee.model";


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  selectedInvoices: Invoice[];
  invoices: Invoice[];
  invoice: Invoice;
  invoiceDialog: boolean;
  companyIdSelected : string;
  contractIdSelected: string;
  status: any[];
  statusSelected: string;
  errorsListDialog: any[];
  errorsListPaymentDialog: any[];
  headerDialog: string = "";
  headerDialogCode: number;
  checkUploaded: boolean;
  showDetailsDialog: boolean;
  contentFileDialog: boolean;
  stepsDialog: boolean;
  paymentDialog: boolean;
  details: Map[];
  justDisplayDetails: boolean;
  detail: Map;
  contracts: Contract[];
  user: User;
  page: number;
  pageTemplate: number;
  company: Company;
  selectedColumns: any[];
  cols: any[];
  selectedTemplate: Template;
  templateDialog: boolean;
  templates: Template[];
  selectedFile: File;
  uploadedFile: boolean;
  fileToDisplay: any;
  steps: IStep[];
  payLink: PayLink;
  disableDataPayLink: boolean;
  loading: boolean;

  constructor(private amplifyService: AmplifyService,
              private confirmationService: ConfirmationService,
              public fileService: FileService,
              private employeeService: EmployeeService,
              public translateService: TranslateService,
              public otherService:OtherService,
              private messageService: MessageService,
              private invoiceService : InvoiceService,
              private companyService: CompanyService,
              private contractService: ContractService,
              private templateService: TemplateService) {
  }

  async ngOnInit() {
    this.loading = true;
    this.company = new Company();
    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.company = result
      }
    })
    this.selectedTemplate = new Template();
    this.page = 1;
    this.pageTemplate = 1;
    this.user = this.otherService.getUser();
    //if code header equal to 1 that's mean the dialog should be an add function else if header code equal to 2 that's mean the dialog should be an update function
    this.headerDialogCode = 0;
    this.contracts = [];
    this.invoices = [];
    this.disableDataPayLink = false;
    this.invoiceDialog = false;
    this.contentFileDialog = false;
    this.templateDialog = false;
    this.justDisplayDetails = false;
    this.showDetailsDialog = false;
    this.paymentDialog = false;
    this.detail = new Map();
    this.setErrorListDialog();
    this.setErrorsListPaymentDialog();
    this.status = [
      {
        name: await this.translateService.instant('draft'),
        code: 0
      },
      {
        name: await this.translateService.instant('generated'),
        code: 1
      },
      {
        name: await this.translateService.instant('sent'),
        code: 2
      },
      {
        name: await this.translateService.instant('paid'),
        code: 3
      }
    ];
    this.contractService.getAllContracts(10, 1,this.company._id).then((contracts: Contract[]) => {
      this.contracts = contracts.filter(contract => contract.steps[contract.steps.length - 1].status.code != 4);
    });
    await this.loadInvoices();
    await this.loadTemplates();
    this.setCols();
    this.loading = false;
  }

  setErrorListDialog(){
    this.errorsListDialog = [
      {
        "key": "contract_id",
        "value": true
      },
      {
        "key": "company_id",
        "value": true
      },
      {
        "key": "total",
        "value": true
      },
      {
        "key": "amount",
        "value": true
      },
      {
        "key": "vat",
        "value": true
      },
      {
        "key" : "invoice",
        "value": true
      },
      {
        "key" : "status",
        "value" : true
      },
      {
        "key": "template",
        "value" : true
      }
    ]
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols(){
    this.cols = [
      {
        header: this.translateService.instant('contract_name'),
        field: "contract",
        subField: "name"
      },
      {
        header: this.translateService.instant('number'),
        field: "number"
      },
      {
        header: this.translateService.instant('amount'),
        field: "amount"
      },
      {
        header: this.translateService.instant('vat'),
        field: "vat"
      },
      {
        header: this.translateService.instant('total'),
        field: "total"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('amount'),
        field: "amount"
      },
      {
        header: this.translateService.instant('vat'),
        field: "vat"
      },
      {
        header: this.translateService.instant('total'),
        field: "total"
      },
      {
        header: this.translateService.instant('contract_name'),
        field: "contract",
        subField: "name"
      }
    ];
  }

  calcTotalInvoice(){
    if(this.invoice.amount != undefined && this.invoice.vat != undefined){
      this.invoice.total = ((this.invoice.amount * this.invoice.vat) / 100) + this.invoice.amount;
      this.generateRender()
    }
  }

  async loadInvoices() {
    if(this.company._id != undefined){
      await this.otherService.loadInvoices(this.page, this.company._id).then((invoices: Invoice[]) => {
        this.invoices = invoices;
      });
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  async loadTemplates() {
    await this.templateService.getAllTemplates( this.company._id, 'I', 10, this.pageTemplate).then((templates) => {
      this.templates = templates.map(item => {
        let template = item
        template.createdAt = this.otherService.formatDate(item.createdAt);
        return template
      });
    })
  }

  async updateInvoice(invoice: Invoice) {
    this.checkUploaded = false;
    this.invoice = {...invoice};
    this.invoice.total = - this.invoice.total;
    this.invoice.amount = - this.invoice.amount;
    this.setErrorListDialog();
    this.headerDialog = await this.translateService.instant('update_invoice');
    this.headerDialogCode = 2;
    this.invoiceDialog = true;
  }

  onChangeContract(event){
    this.invoice.vat = event.value.vat;
    this.generateRender();
  }

  addDetail(): void{
    const existingKeyIndex = this.invoice.details.findIndex((detail) => {
      return this.detail.key == detail.key;
    });
    if(existingKeyIndex != -1){
      //modify value
      this.details[existingKeyIndex].value = this.detail.value;
    }else{
      if(this.detail.key != "" && this.detail.value != ""){
        this.details.push(this.detail);
      }
    }
    this.detail = new Map();
  }

  detailsDialog(){
    this.details = this.invoice.details;
    this.showDetailsDialog = true;
    this.justDisplayDetails = false;
    this.invoiceDialog = false;
  }

  async nextPage() {
    if (this.invoices.length >= 10) {
      this.page++;
      await this.loadInvoices();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadInvoices();
    }
  }

  hideDetailsDialog(){
    if(!this.justDisplayDetails){
      this.invoiceDialog = true;
    }
    this.showDetailsDialog = false;
    this.justDisplayDetails = false;
  }

  addDetailsToInvoice(){
    if(this.invoice.details.length == 0){
      this.invoice.details = this.details;
      this.details = [];
      this.showDetailsDialog = false;
      this.justDisplayDetails = false;
      return;
    }
    let findIt = false;
    for(let i=0; i < this.details.length; i++){
      let j = -1;
      while(!findIt && j < this.invoice.details.length){
        j++;
        if(this.details[i].key == this.invoice.details[j].key){
          this.invoice.details[j].value = this.details[i].value;
          findIt = true;
        }
      }
      if(!findIt){
        this.invoice.details.push(this.details[i]);
      }
    }
    this.showDetailsDialog = false;
    this.justDisplayDetails = false;
    this.invoiceDialog = true;
  }

  displayDetailsOfInvoice(details: Map[]){
    this.details = details;
    this.justDisplayDetails = true;
    this.showDetailsDialog = true;
  }

  async addInvoice() {
    this.invoice = new Invoice();
    this.invoice.steps.push(<IStep>{status: this.status[0]})
    this.checkUploaded = false;
    this.headerDialog = await this.translateService.instant('add_invoice');
    this.headerDialogCode = 1;
    this.setErrorListDialog();
    this.invoiceDialog = true;
  }

  hideInvoiceDialog(): void{
    this.checkUploaded = false;
    this.invoiceDialog = false;
    this.headerDialogCode = 0;
    this.headerDialog = "";
    this.invoice = new Invoice();
  }

  async sendInvoice(invoice: Invoice) {
    let lastStep = invoice.steps[invoice.steps.length - 1];
    if(lastStep.payLink == undefined){
      this.payLink = new PayLink();
      this.disableDataPayLink = false;
      this.payLink.token = this.company.ngPayment_token;
      this.payLink.amount = invoice.amount;
      if(invoice.contract.employee != undefined){
        await this.employeeService.getEmployeeById(invoice.contract.employee).then((employee: Employee) => {
          this.payLink.email = employee.email;
          this.payLink.firstname = employee.name;
        });
      }
    }else{
      this.payLink = lastStep.payLink;
      this.disableDataPayLink = true;
    }
    this.invoice = invoice;
    this.paymentDialog = true;
  }

  async saveInvoice() {
    this.invoice.company_id = this.company._id;

    this.errorsListDialog[this.otherService.indexErrorsInListFind('contract_id', this.errorsListDialog)].value = this.invoice.contract != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('company_id', this.errorsListDialog)].value = this.invoice.company_id != undefined && this.invoice.company_id !== "";
    this.errorsListDialog[this.otherService.indexErrorsInListFind('amount',this.errorsListDialog)].value = this.invoice.amount >= 0;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('total',this.errorsListDialog)].value = this.invoice.total >= 0;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('invoice', this.errorsListDialog)].value = this.uploadedFile || (this.invoice.render != undefined && this.invoice.render != "");

    if (this.errorsListDialog.find(elemnt => elemnt.value == false)) {
      console.log(this.errorsListDialog);
      return;
    }

    this.invoice.details = this.details;
    this.invoice.contract_id = this.invoice.contract._id;

    if (this.headerDialogCode == 1) {
      //add invoice
      this.invoice.r_invoice_id = undefined;
      await this.invoiceService.insertInvoice(this.invoice).then(async (invoice: Invoice) => {
        this.invoice._id = invoice._id
        this.invoice.createdAt = this.otherService.formatDate(invoice.createdAt);
        this.invoice.updatedAt = this.otherService.formatDate(invoice.updatedAt);
        this.invoice.number = invoice.number;
        this.invoices.unshift(this.invoice);
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_invoice_added'),
          life: 3000,
        });
      }).catch(async (err) => {
        console.log(err);
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    } else if (this.headerDialogCode == 2) {
      //update invoice
      await this.contractService.finOne(this.invoice.contract_id).then(async (contractObject) => {
        this.invoice.contract = contractObject;
        this.invoice.r_invoice_id = this.invoice._id;
        this.invoice._id = undefined;
        await this.invoiceService.insertInvoice(this.invoice).then(async (invoice: Invoice) => {
          this.invoice._id = invoice._id;
          this.invoice.createdAt = this.otherService.formatDate(invoice.createdAt);
          this.invoice.updatedAt = this.otherService.formatDate(invoice.updatedAt);
          this.invoices.unshift(this.invoice);
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('msg_invoice_updated'),
            life: 3000,
          });
        }).catch(async (err) => {
          console.log(err);
          this.messageService.add({
            severity: 'Error',
            summary: await this.translateService.instant('msg_error'),
            detail: err.message,
            life: 3000,
          });
        })
      })
    }
    this.invoiceDialog = false;
    this.checkUploaded = false;
  }

  async onUpload(event) {
    this.uploadedFile = false;
    this.otherService.ngOnUpload(event, this.company, this.user).then((fileId: string[]) => {
      this.invoice.file = fileId[0];
      this.invoice.steps.push(<IStep>{status: this.status[1]});
      this.errorsListDialog[this.otherService.indexErrorsInListFind('invoice',this.errorsListDialog)].value = true;
      this.uploadedFile = true;
      this.checkUploaded = true;
    })
  }

  async pay(invoice: Invoice) {
    if(this.company.ngPayment_token != undefined && this.company.ngPayment_token != "" && this.company.paymentVendor != undefined){
      await this.invoiceService.paymentInvoice(invoice).then(async (paymentObject: Payment) => {
        let step = <IStep>{
          status: this.status[2],
          payment: paymentObject
        }
        invoice.steps.push(step);
        await this.invoiceService.updateInvoice(invoice);
      }).catch(async (err) => {
        console.log(err);
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      })
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_payment_token'),
        life: 3000,
      });
    }
  }

  async showFiles(invoice: Invoice) {
    await this.otherService.showFiles(invoice.file).then((data) => {
      this.fileToDisplay = data;
      this.contentFileDialog = true;
    }).catch((err) => {
      console.log(err);
    });
  }

  displayStatusOfInvoice(steps: any){
    this.steps = steps.map(item => {
      let step = item
      item.createdAt != undefined ? step.createdAt = this.otherService.formatDate(item.createdAt) : step.createdAt = undefined;
      return step
    });
    this.stepsDialog = true;
  }

  setErrorsListPaymentDialog(){
    this.errorsListPaymentDialog = [
      {
        'key' : 'last_name',
        'value' : true
      },
      {
        'key' : 'first_name',
        'value' : true
      },
      {
        'key' : 'email',
        'value' : true
      },
      {
        'key' : 'amount',
        'value' : true
      },
      {
        'key' : 'phone',
        'value' : true
      }
    ]
  }

  async sendLink() {
    this.errorsListPaymentDialog[this.otherService.indexErrorsInListFind('last_name', this.errorsListPaymentDialog)].value = this.payLink.lastname != undefined && this.payLink.lastname != "";
    this.errorsListPaymentDialog[this.otherService.indexErrorsInListFind('first_name', this.errorsListPaymentDialog)].value = this.payLink.firstname != undefined && this.payLink.firstname != "";
    this.errorsListPaymentDialog[this.otherService.indexErrorsInListFind('email', this.errorsListPaymentDialog)].value = this.payLink.email != undefined && this.payLink.email != "";
    this.errorsListPaymentDialog[this.otherService.indexErrorsInListFind('amount', this.errorsListPaymentDialog)].value = this.payLink.amount != undefined && this.payLink.amount >= 0;
    this.errorsListPaymentDialog[this.otherService.indexErrorsInListFind('phone', this.errorsListPaymentDialog)].value = this.payLink.phone != undefined && this.payLink.phone != "";

    if (this.errorsListPaymentDialog.find(element => element.value == false)) {
      console.log(this.errorsListPaymentDialog);
      return;
    }

    let step = <IStep>{
      payment: this.invoice.steps[this.invoice.steps.length - 1].payment,
      status: this.invoice.steps[this.invoice.steps.length - 1].status,
      payLink: this.payLink
    };
    this.invoice.steps.push(step);

    await this.invoiceService.updateInvoice(this.invoice).then(async (invoice: Invoice) => {
      let index = this.invoices.findIndex(i => i._id == invoice._id);
      this.invoice.createdAt = invoice.createdAt;
      this.invoice.updatedAt = invoice.updatedAt;
      this.invoices[index] = this.invoice;
    });
    this.paymentDialog = false;

  }

  async createPaymentLink(invoice: Invoice) {
    await this.invoiceService.createPaymentLink(invoice).then(async (rep: any) => {
      if(rep.paylink != undefined){
        this.confirmationService.confirm({
          message: await this.translateService.instant('pay_link') + ": " + rep.paylink,
          header: await this.translateService.instant('get_pay_link'),
          icon: 'pi pi-check-circle',
          accept: async () => {
            this.copyClipBoard(rep.paylink);
          }
        });
      }
    });
  }

  copyClipBoard(link: string){
    let copied: EventEmitter<string> = new EventEmitter<string>();
    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData || window["clipboardData"];
      clipboard.setData("text", link);
      e.preventDefault();

      copied.emit(link);
    };
    document.addEventListener("copy", listener, false)
    document.execCommand("copy");
    document.removeEventListener("copy", listener, false);
  }

  async checkPayment(invoice: Invoice) {
    let lastStep = invoice.steps[invoice.steps.length - 1];
    if (lastStep.payLink.hash != undefined) {
      await this.invoiceService.checkPayment(invoice._id, lastStep.payLink).then(async (rep: any) => {
        console.log(rep);
        if (rep.data.paid != undefined && rep.data.paid) {
          let step = <IStep>{
            payment: invoice.steps[invoice.steps.length - 1].payment,
            payLink: invoice.steps[invoice.steps.length - 1].payLink,
            status: this.status[3]
          }
          invoice.steps.push(step);
          await this.invoiceService.updateInvoice(invoice).then(() => {
            let index = this.invoices.findIndex(i => i._id == invoice._id);
            this.invoices[index] = invoice;
          });
        }
      })
    }
  }

  generateRender(){
    if(this.selectedTemplate._id != undefined){
      this.invoice.render = this.otherService.generateRender(this.selectedTemplate, this.invoice, 'I')
    }
  }

  async generateInvoiceFile(invoice: Invoice) {
    if (invoice.render) {
      invoice.file = await this.otherService.uploadFromRender(invoice.render, invoice, 'I', this.user, this.company);
      let step = <IStep>{
        status : {
          name: this.status[1].name,
          code: this.status[1].code
        },
        createdAt: invoice.steps[0].createdAt
      };
      invoice.steps.push(step);
      await this.invoiceService.updateInvoice(invoice).then(async (res: Invoice) => {
        let index = this.contracts.findIndex(c => c._id == invoice._id);
        invoice.createdAt = res.createdAt;
        invoice.updatedAt = res.updatedAt;
        this.invoices[index] = invoice;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('invoice_generated'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    }
  }

  disableUploadFile(): boolean{
    try{
      if(this.selectedTemplate._id != undefined || this.invoice.render != undefined){
        return true
      }else{
        return false
      }
    }catch (err){
      return false
    }
  }

}
