import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Contract} from "../models/contract.model";

@Injectable({
  providedIn: 'root'
})
export class ContractService{
  public contractUrl = '/contracts/';

  constructor(private http:HttpClient) {}

  getAllContracts(limit:any,page:any, companyId : any){
    let params = new HttpParams();
    params = params.append('limit', limit);
    params = params.append('page', page);
    params = params.append('companyId' , companyId)
    return this.http.get<any>(this.contractUrl, {params}).toPromise();
  }

  finOne(id: string){
    return this.http.get<any>(this.contractUrl + id).toPromise();
  }

  insert(contract : Contract, templateId?: string){
    let params = new HttpParams();
    if(templateId){
      params = params.append('templateId', templateId);
    }
    return this.http.post(this.contractUrl, contract, {params}).toPromise();
  }

  update(contract : Contract){
    return this.http.put(this.contractUrl, contract).toPromise();
  }

  delete(id : string){
    return this.http.delete(this.contractUrl + id).toPromise();
  }

  toSign(contract: Contract){
    return this.http.patch<any>(this.contractUrl, contract).toPromise();
  }

  sign(contract: Contract){
    return this.http.patch<any>(this.contractUrl + "signature", contract).toPromise();
  }

  findContractByEmployeeId(employeeId: string){
    let params = new HttpParams();
    params = params.append('employeeId' , employeeId)
    return this.http.get<any>(this.contractUrl, {params}).toPromise();
  }

}
