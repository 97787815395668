import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AmplifyService} from '../../../../core/service/amplify-service';
import {MessageService} from 'primeng/api';
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../../../core/service/notification.service";


@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  user: any;
  resetForm: FormGroup|undefined;
  forgotPassword: boolean ;
  msgErr: string;

  constructor(private router: Router ,private notificationService: NotificationService, private formBuilder: FormBuilder, private amplifyService: AmplifyService, private messageService: MessageService, private translate: TranslateService) {
    this.forgotPassword = false;
  }
  ngOnInit(): void {
    this.notificationService.disconnect();
    this.msgErr = "";
    this.resetForm = this.formBuilder.group({
      userName: ['', Validators.required],
      userPassword: '',
      userCode: ''
    });
  }

  reset() {
    if(this.resetForm.invalid){
      return;
    }else if (this.forgotPassword === false){
      this.amplifyService.sendForgotPassword(this.resetForm.get('userName').value)
        .then(async (data) => {
          this.forgotPassword = true;
          this.messageService.add({
            severity: 'success',
            summary: await this.translate.instant('msg_successful'),
            detail: await this.translate.instant('msg_code_sent'),
            life: 3000,
          });
        }).catch((err) => {
          this.msgErr = err.message;
        });
    }else if(this.forgotPassword === true){
      this.amplifyService.changePassword(this.resetForm.get('userName').value, this.resetForm.get('userCode').value, this.resetForm.get('userPassword').value)
        .then(async (data) => {
          this.messageService.add({
            severity: 'success',
            summary: await this.translate.instant('msg_successful'),
            detail: this.translate.instant('msg_password_change'),
            life: 3000,
          });
          this.router.navigate(['/']);
        })
        .catch(async (err) => {
          console.log(err);
          this.messageService.add({
            severity: 'error',
            summary: await this.translate.instant('msg_error'),
            detail: err.message,
            life: 3000,
          });
        });
    }
  }
}
