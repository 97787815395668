import {Injectable} from "@angular/core";
import {User} from "../models/user.model";
import {HttpClient} from "@angular/common/http";
import {Company} from "../models/company.model";
import {Template} from "../models/template.model";
import pdfMake from 'pdfmake/build/pdfmake';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {File} from "../models/file.model";
import {AmplifyService} from "./amplify-service";
import {FileService} from "./file.service";
import {InvoiceService} from "./invoice.service";
import {ContractService} from "./contract.service";
import {CompanyService} from "./company.service";
import {ProofService} from "./proof.service";
import {Proof} from "../models/proof.model";
import {CRA} from "../models/cra.model";
import {CRAService} from "./cra.service";
import {Employee} from "../models/employee.model";
import {EmployeeService} from "./employee.service";
import {Contract} from "../models/contract.model";
import {Invoice} from "../models/invoice.model";
import {TemplateService} from "./template.service";
import {Salary} from "../models/salary.model";
import {SalaryService} from "./salary.service";
import {History} from "../models/otherModels/proofHistory.model";
import {Partner} from "../models/partner.model";
import {PartnerService} from "./partner.service";
import {Accounting} from "../models/accounting.model";
import {AccountingService} from "./accounting.service";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export enum ProofStatus {
  not_valid = 0,
  to_be_validated = 1,
  valid = 2,
  waiting = 3
}

@Injectable()
export class OtherService {

  constructor(private http: HttpClient,
              private CRAService: CRAService,
              private accountingService: AccountingService,
              private partnerService: PartnerService,
              private salaryService: SalaryService,
              private templateService: TemplateService,
              private employeeService: EmployeeService,
              private proofService: ProofService,
              private messageService: MessageService,
              public translateService: TranslateService,
              private companyService: CompanyService,
              private amplifyService: AmplifyService,
              private fileService: FileService,
              private invoiceService: InvoiceService,
              private contractService: ContractService) {
  }

  indexErrorsInListFind(key: string, errorsListDialog: any[]): number {
    try {
      return errorsListDialog.findIndex(e => e.key === key);
    } catch (err) {
      console.log("errorsListDialog: ", err);
      return -1;
    }
  }

  getBackEndVersion(): any {
    return this.http.get('/version/').toPromise();
  }

  getUser(): User {
    try {
      let user = JSON.parse(localStorage.getItem('userObject'));
      return user;
    } catch (err) {
      console.log("error getting user: ", err);
      return null;
    }
  }

  setUser(user: User) {
    localStorage.setItem("userObject", JSON.stringify(user));
  }

  setCompany(company: Company) {
    localStorage.setItem("companyObject", JSON.stringify(company));
  }

  async loadSalaries(pageSalary: number, companyId: string, employeeId?: string): Promise<Salary[]> {
    return new Promise<Salary[]>(async (resolve, reject) => {
      await this.salaryService.getAllSalaries(10, pageSalary, companyId, employeeId).then(resultSalaries => {
        let salaries = resultSalaries.map(item => {
          let salary = item
          salary.createdAt = this.formatDate(item.createdAt);
          salary.from = this.formatDate(item.from);
          salary.to = this.formatDate(item.to);
          return salary
        });
        resolve(salaries);
      }).catch(err => {
        reject(err)
      })
    })
  }

  generateRender(selectedTemplate: Template, object: Contract | Invoice | Salary, type: string): string {
    const body = selectedTemplate.render.substring(selectedTemplate.render.indexOf("<body>"), selectedTemplate.render.indexOf("</body>") + 7)
    let render = body;
    let i;
    let value = "";
    let variable = "";
    while ((i = render.indexOf('${')) != -1) {
      let toReplace = render.substring(i, render.indexOf('}') + 1);
      if (type == 'I') {
        variable = toReplace.substring("${invoice.".length, toReplace.indexOf("}"));
      } else if (type == 'C') {
        variable = toReplace.substring("${contract.".length, toReplace.indexOf("}"));
      } else if (type == 'S') {
        variable = toReplace.substring("${salary.".length, toReplace.indexOf("}"));
      } else {
        render = "";
      }
      try {
        if (variable.indexOf('.', variable.indexOf('.') + 1) != -1) {
          let subSubVariable = variable.substr(variable.indexOf('.', variable.indexOf('.') + 1) + 1, variable.length);
          let subVariable = variable.substring(variable.indexOf('.') + 1, variable.indexOf('.', variable.indexOf('.') + 1));
          variable = variable.substr(0, variable.indexOf('.'));
          value = object[variable][subVariable][subSubVariable];
        } else if (variable.indexOf('.') != -1) {
          let subVariable = variable.substr(variable.indexOf('.') + 1, variable.length);
          variable = variable.substr(0, variable.indexOf('.'));
          value = object[variable][subVariable];
        } else {
          value = object[variable];
        }
      } catch (err) {
        value = "undefined";
      }
      render = render.replace(toReplace, value);
    }
    const templateFinal: string = selectedTemplate.render.replace(body, render);
    return templateFinal
  }

  getPdfFromRender(render: string): Promise<string> {
    return new Promise<any>(async resolve => {
      const iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(render);
      iframe.contentWindow.document.close();
      const documentHTML = iframe.contentWindow.document.body;
      const options = {
        allowTaint: true,
        useCORS: true,
        quality: 4,
        scale: 5,
        removeContainer: false
      }
      await html2canvas(documentHTML , options).then(canvas => {
        let pdf = new jsPDF('portrait', 'pt', "a4");
        const canvasData = canvas.toDataURL('image/png');
        const dataWidth = 210;
        const dataHeight = canvas.height * dataWidth / canvas.width;
        pdf.addImage(canvasData, 'PNG', 0, 0, dataWidth, dataHeight);
        const out = pdf.output('blob');
        const reader = new FileReader();
        reader.readAsDataURL(out);
        reader.onloadend = function () {
          resolve(reader.result)
        }
      });
    })
  }

  convertBase64ToUint8Array(fileBase64: string): Promise<Uint8Array>{
    return new Promise<Uint8Array>((resolve, reject) => {
      try{
        const BASE64_MARKER = ';base64,';
        const base64Index = fileBase64.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        const base64 = fileBase64.substring(base64Index);
        const raw = window.atob(base64);
        const rawLength = raw.length;
        let view = new Uint8Array(new ArrayBuffer(rawLength));
        for (let i = 0; i < rawLength; i++) {
          view[i] = raw.charCodeAt(i);
        }
        resolve(view);
      }catch (err){
        reject(err)
      }
    })
  }

  uploadFromRender(render: string, object: Contract | Invoice | Salary, type: string, user: User, company: Company): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try{
        const fileBase64: string = await this.getPdfFromRender(render);
        const uInt8Array : Uint8Array = await this.convertBase64ToUint8Array(fileBase64)
        const data = await this.amplifyService.uploadFromVariable(uInt8Array);
        let file = new File();
        file.type = "PDF";
        file.path = data.path;
        if (type == 'I') {
          file.name = "Invoice_" + (object as Invoice).contract.name + ".pdf";
        } else if (type == 'C') {
          file.name = "Contract_" + (object as Contract).name + ".pdf"
        } else {
          file.name = "Salary_" + object._id + ".pdf";
        }
        file.hash = data.fileHash + '.pdf';
        file.owner = user;
        file.company = company;
        const resultFile: File = await this.fileService.addFile(file).then((file: File) => { return file });
        resolve(resultFile._id);
      }catch (err) {
        reject(err);
      }
    });
  }

  getAccounting(): any {
    try {
      let accounting = JSON.parse(localStorage.getItem('accountingObject'));
      return accounting
    } catch (err) {
      return null;
    }
  }

  getCompany(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      try {
        let company = JSON.parse(localStorage.getItem('companyObject'));
        if (company != null) {
          resolve(company);
        } else {
          resolve(false);
        }
      } catch (err) {
        resolve(false);
      }
    });
  }

  convertStringToArray(str: string): string[] {
    if (str === "") {
      return [];
    }
    let separator = [";", ",", "/"];
    let i = -1;
    let findSeparator = false;
    let array = [];
    while (i < separator.length && !findSeparator) {
      i++;
      if (str.indexOf(separator[i]) !== -1) {
        array = str.split(separator[i]);
        findSeparator = true;
      }
    }
    if (array.length == 0 && !findSeparator) {
      array.push(str);
    }
    return array;
  }

  formatDate(date: string | Date) {
    if (date != "" && date != undefined) {
      date = new Date(date);
      return date.toISOString().replace('T', ' ').substr(0, date.toISOString().indexOf('.'));
    } else {
      return new Date().toISOString();
    }
  }

  async ngOnUpload(event, company: Company, user: User): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      let files_id = [];
      for (let i = 0; i < event.files.length; i++) {
        if (event.files[i].type.indexOf("image/") != -1 || event.files[i].type.indexOf("pdf") != -1) {
          let selectedFile = new File();
          selectedFile.name = event.files[0].name;
          event.files[i].type.indexOf("pdf") != -1 ? selectedFile.type = "PDF" : selectedFile.type = event.files[i].type.substring(event.files[i].type.indexOf("/") + 1, event.files[i].type.length).toUpperCase();
          selectedFile.company = company;
          selectedFile.owner = user;
          await this.amplifyService.upload(event.files[i]).then(async (fileAws: any) => {
            selectedFile.path = fileAws.path;
            selectedFile.hash = fileAws.fileHash + "." + selectedFile.type.toLowerCase();
            await this.fileService.addFile(selectedFile).then((file: File) => {
              files_id.push(file._id);
            }).catch((err) => {
              reject(err)
            });
          }).catch((err) => {
            reject(err);
          });
        } else {
          reject({code: 400, message: await this.translateService.instant('invalid_file')});
        }
      }
      resolve(files_id);
    })
  }

  async showFiles(fileId: string): Promise<any> {
    return new Promise<any>((async (resolve, reject) => {
      await this.fileService.getFileById(fileId).then(async (file: File) => {
        let level = file.path.substr(0, file.path.indexOf("/"));
        await this.amplifyService.getFile(file.hash, file.path.replace(level + "/", ""), level).then((awsFile) => {
          resolve({
            source: awsFile,
            name: file.name
          })
        }).catch(async (err) => {
          reject(err);
        })
      }).catch((err) => {
        reject(err);
      });
    }))
  }

  async loadCompanies(page: number, userId: string): Promise<Company[]> {
    return new Promise<Company[]>((async (resolve, reject) => {
      let resultCompanies: Company[];
      await this.companyService.getAllCompany(10, page, userId).then((companies: Company[]) => {
        resultCompanies = companies.map(c => {
          let company = c
          company.createdAt = this.formatDate(c.createdAt);
          return company
        });
        resolve(resultCompanies);
      }).catch((err) => {
        reject(err);
      });
    }))
  }

  async loadEmployees(pageEmployee: number, companyId: string): Promise<Employee[]> {
    return new Promise<Employee[]>(async (resolve, reject) => {
      await this.employeeService.getAllEmployees(10, pageEmployee, companyId).then((resultEmployees: Employee[]) => {
        let employees = resultEmployees.map(item => {
          let employee = item
          employee.createdAt = this.formatDate(item.createdAt);
          return employee
        });
        resolve(employees);
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async loadInvoices(pageInvoice: number, companyId: string, contractId?: string): Promise<Invoice[]> {
    return new Promise<Invoice[]>(async (resolve, reject) => {
      await this.invoiceService.getAllInvoices(10, pageInvoice, companyId, contractId)
        .then(data => {
          let invoices = data.map(item => {
            let invoice = item
            invoice.createdAt = this.formatDate(item.createdAt);
            return invoice
          });
          resolve(invoices);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  async loadTemplates(companyId: string, pageTemplate?: number, type?: string): Promise<Template[]> {
    return new Promise<Template[]>(async (resolve, reject) => {
      await this.templateService.getAllTemplates(companyId, type, 10, pageTemplate).then((resultTemplates: Template[]) => {
        let templates = resultTemplates.map(item => {
          let template = item
          template.createdAt = this.formatDate(item.createdAt);
          return template
        });
        resolve(templates);
      }).catch((err) => {
        reject(err);
      })
    });
  }

  async loadContracts(pageContract: number, companyId: string): Promise<Contract[]> {
    return new Promise<Contract[]>(async (resolve, reject) => {
      await this.contractService.getAllContracts(10, pageContract, companyId).then((resultContracts: Contract[]) => {
        let contracts = resultContracts.map(item => {
          let contract = item
          contract.createdAt = this.formatDate(item.createdAt);
          contract.steps.map(step => {
            if (step.createdAt != undefined) this.formatDate(step.createdAt)
          });
          return contract
        });
        resolve(contracts);
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async loadFiles(pageFile: number, companyId: string): Promise<File[]> {
    return new Promise<File[]>(async (resolve, reject) => {
      await this.fileService.getAllFile(10, pageFile, companyId).then((resultFiles: File[]) => {
        let files = resultFiles.map(item => {
          let file = item
          file.createdAt = this.formatDate(item.createdAt);
          return file
        });
        resolve(files);
      }).catch((err) => {
        reject(err);
      })
    });
  }

  async validateProof(proofId: string): Promise<boolean> {
    let ListStatus = await this.getStatusProof();
    console.log("list of status: ", ListStatus);
    return new Promise<boolean>((async (resolve, reject) => {
      await this.proofService.getProofById(proofId).then(async (proof: Proof) => {
        let keyHistory;
        let validateDate = false;
        proof.selected != undefined ? keyHistory = proof.selected : keyHistory = "h" + (proof.histories.length - 1);

        if (proof.histories.length > 0) {
          let histories = new Map(proof.histories.map((value, index) => ['h' + index, value]));
          let history: History = histories.get(keyHistory);
          let dateUntilString = history[keyHistory].to;

          let date = new Date();
          let dateSting = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
          validateDate = new Date(dateSting) < new Date(dateUntilString);
        }

        if (proof.histories.length > 0 && validateDate && proof.histories[proof.histories.length - 1][keyHistory].status.code == ListStatus[2].code) {
          await this.proofService.validateProof(proofId, ListStatus[2]).then(() => {
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        } else {
          resolve(false)
        }
      }).catch((err) => {
        reject(err);
      })
    }));
  }

  async getStatusProof(): Promise<{ code: number, name: string }[]> {
    return [
      {
        name: await this.translateService.instant('not_valid'),
        code: ProofStatus.not_valid
      },
      {
        name: await this.translateService.instant('to_be_validated'),
        code: ProofStatus.to_be_validated
      },
      {
        name: await this.translateService.instant('valid'),
        code: ProofStatus.valid
      },
      {
        name: await this.translateService.instant('waiting'),
        code: ProofStatus.waiting
      }
    ]
  }

  async loadProofs(pageProof: number, companyId: string): Promise<Proof[]> {
    return new Promise<Proof[]>(async (resolve, reject) => {
      await this.proofService.getAllProof(10, pageProof, companyId).then((resultProofs: Proof[]) => {
        let proofs = resultProofs.map(item => {
          let proof = item
          proof.createdAt = this.formatDate(item.createdAt);
          if (proof.histories != undefined && proof.histories.length > 0) {
            let histories = proof.histories.map((history, index) => {
              history["h" + index].from = this.formatDate(history["h" + index].from);
              history["h" + index].to = this.formatDate(history["h" + index].to);
              return history;
            });
            proof.histories = histories;
          }
          return proof
        });
        resolve(proofs);
      }).catch((err) => {
        reject(err);
      })
    });
  }

  loadCRAs(pageCra: number, companyId: string, employeeId?: string): Promise<CRA[]> {
    return new Promise<CRA[]>((resolve, reject) => {
      this.CRAService.getAllCRA(10, pageCra, companyId, employeeId).then((resultCRAs: CRA[]) => {
        let CRAs = resultCRAs.map(item => {
          let cra = item
          cra.createdAt = this.formatDate(item.createdAt);
          if (cra.from != undefined) {
            cra.from = this.formatDate(cra.from);
          }
          if (cra.to != undefined) {
            cra.to = this.formatDate(cra.to);
          }
          return cra
        });
        resolve(CRAs)
      }).catch((err) => {
        reject(err);
      })
    });
  }

  async loadPartners(pagePartner: number, companyId: string): Promise<Partner[]> {
    return new Promise<Partner[]>((async (resolve, reject) => {
      await this.partnerService.getAllPartner(10, pagePartner, companyId).then((partnersResult: Partner[]) => {
        let partners = partnersResult.map(c => {
          let partner = c
          partner.createdAt = this.formatDate(c.createdAt);
          return partner
        });
        resolve(partners)
      }).catch((err) => {
        reject(err);
      });
    }))
  }

  async loadAccounting(pageAccounting: number, companyId: string) {
    return new Promise<Accounting[]>((async (resolve, reject) => {
      await this.accountingService.getAllAccounting(10, pageAccounting, companyId).then((accounting: Accounting[]) => {
        let manyAccounting = accounting.map(acc => {
          let accTmp = acc
          accTmp.createdAt = this.formatDate(acc.createdAt);
          accTmp.validity = this.formatDate(acc.validity);
          return accTmp;
        });
        resolve(manyAccounting)
      }).catch((err) => {
        reject(err);
      });
    }));
  }

  toMap(array) {
    let map = new Map<string, string>();
    for (let row of JSON.parse(array)) {
      map.set(row[0], row[1]);
    }
    return map;
  }

  async getSession(): Promise<any> {
    return new Promise<any>(async resolve => {
      let jwtToken = null;
      jwtToken = localStorage.getItem("jwtToken");
      if (jwtToken == null || jwtToken == "" || jwtToken == undefined) {
        await this.amplifyService.getUserInfo()
          .then((data) => {
            jwtToken = data.signInUserSession.accessToken.jwtToken;
          })
          .catch((err) => jwtToken = null);
      }
      resolve(jwtToken);
    })
  }

  changeStatusProof(proof: Proof, histories: any, status: any[], keyHistory?: any): History {
    if (keyHistory == undefined) {
      keyHistory = proof.selected;
    }

    let targetHistory: History = status[1];
    let date = new Date();
    let dateSting = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();

    let history: History = histories.get(keyHistory);
    let dateUntilString = history[keyHistory].to;

    if (proof.histories.length > 0 && new Date(dateSting) < new Date(dateUntilString)) {
      if (history[keyHistory].status.code == status[2].code) {
        targetHistory.status = status[2]; //valid
      } else if (history[keyHistory].status.code == status[0].code) {
        targetHistory.status = status[3]; //waiting
      } else {
        targetHistory.status = status[1]; //to_be_validated
      }
    } else if (proof.histories.length == 0 || new Date(dateSting) > new Date(dateUntilString) || history[keyHistory].status.code != status[2].code) {
      targetHistory.status = status[0]; //not_valid
    }

    return targetHistory;
  }

  async saveProofDatabase(errorListDialog: any[], history: History, proof: Proof, proofs: Proof[], company: Company): Promise<Proof> {
    return new Promise<Proof>(async (resolve, reject) => {
      errorListDialog[this.indexErrorsInListFind('from', errorListDialog)].value = history.from != undefined;
      errorListDialog[this.indexErrorsInListFind('to', errorListDialog)].value = history.to != undefined;
      errorListDialog[this.indexErrorsInListFind('proof_name', errorListDialog)].value = proof.name != undefined && proof.name != "";
      errorListDialog[this.indexErrorsInListFind('files', errorListDialog)].value = (history.files != undefined && history.files.length > 0);
      if (errorListDialog.find(elemnt => elemnt.value == false)) {
        console.log(errorListDialog);
        reject(errorListDialog)
      }
      history.from = new Date(history.from).toISOString();
      history.to = new Date(history.to).toISOString();
      proof.histories.push(history);
      proof.company_id = company._id;
      await this.proofService.addProof(proof).then(async (proofDB: Proof) => {
        proofs.push(proofDB);
        resolve(proofDB);
      }).catch((err) => {
        console.log("save proof other service ", err);
        reject(err);
      });
    });
  }
}
