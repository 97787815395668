import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';



@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor() {
  }

  public BASE_URL: any;
  public BASE_URL_SOCKET: any;
  public token: any;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.BASE_URL = localStorage.getItem('BASE_URL');
    this.BASE_URL_SOCKET = localStorage.getItem('BASE_URL_SOCKET');
    for (let i = 0, len = localStorage.length; i < len; ++i) {
      if (localStorage.key(i).includes('idToken')) {
        this.token = localStorage.getItem(localStorage.key(i));
      }
    }
    if (this.token != null) {
      if (req.url.substr(0, 3) === '../') {
        const authReq = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + this.token),
        });
        return next.handle(authReq).pipe();
      } else {
        const authReq = req.clone(
          {
            headers: req.headers.set('Authorization', 'Bearer ' + this.token),
            url: this.BASE_URL + req.url

          });
        return next.handle(authReq).pipe();
      }
    } else {
      if (req.url.substr(0, 3) === '../') {
        const authReq = req.clone(
          {}
        );
        return next.handle(authReq).pipe();
      } else {
        const authReq = req.clone(
          {
            url: this.BASE_URL + req.url
          }
        );
        return next.handle(authReq).pipe();
      }
    }
  }
}
