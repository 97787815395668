import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {OtherService} from "../../../core/service/other.service";
import {Template} from "../../../core/models/template.model";
import {Company} from "../../../core/models/company.model";
import {TemplateService} from "../../../core/service/template.service";
import {MessageService} from "primeng/api";

enum codeTypes{
  C = 'C',
  I = 'I',
  S = 'S'
}

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})

export class TemplateComponent implements OnInit {
  page: number;
  selectedColumns: any[];
  cols: any[];
  selectedTemplates: Template[];
  templates: Template[];
  company: Company;
  templateDialog: boolean;
  loading: boolean;
  template: Template;
  header: string;
  headerDialogCode: number;
  errorsListDialog: any[];
  types: any[];
  type: any;


  constructor(private translateService: TranslateService, public otherService: OtherService, private templateService:TemplateService, private messageService: MessageService) { }

  async ngOnInit() {
    this.loading = true;
    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.company = result
      }else{
        this.company = new Company();
      }
    });
    //if code header equal to 1 that's mean the dialog should be add else if header code equal to 2 that's mean the dialog should be update
    this.headerDialogCode = 0;
    this.header = "";
    this.templateDialog = false;
    this.template = new Template();
    await this.loadTemplates();
    this.setErrorListDialog();
    this.page = 1;
    this.types = [
      {
        name: await this.translateService.instant('contract'),
        code: codeTypes.C
      },
      {
        name: await this.translateService.instant('invoice'),
        code: codeTypes.I
      },
      {
        name: await this.translateService.instant('salary'),
        code: codeTypes.S
      }
    ]
    this.setCols();
    this.loading = false;
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setErrorListDialog(){
    this.errorsListDialog = [
      {
        "key": "company_id",
        "value": true
      },
      {
        "key": "name",
        "value": true
      },
      {
        "key": "type",
        "value": true
      },
      {
        "key": "render",
        "value": true
      }
    ]
  }

  setCols(){
    this.cols = [
      {
        header: this.translateService.instant('name'),
        field: "name"
      },
      {
        header: this.translateService.instant('type'),
        field: "type"
      },
      {
        header: this.translateService.instant('render'),
        field: "render"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('name'),
        field: "name"
      },
      {
        header: this.translateService.instant('type'),
        field: "type"
      }
    ];
  }

  async loadTemplates() {
    if(this.company._id != undefined){
      await this.otherService.loadTemplates(this.company._id, this.page).then((templates: Template[]) => {
        this.templates = templates;
      });
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  async nextPage() {
    if (this.templates.length >= 10) {
      this.page++;
      await this.loadTemplates();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadTemplates();
    }
  }

  async addTemplate() {
    if (this.company._id != undefined) {
      this.headerDialogCode = 1;
      this.header = await this.translateService.instant('add_template');
      this.setErrorListDialog();
      this.template = new Template();
      this.templateDialog = true;
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  hideTemplateDialog(){
    this.header = "";
    this.headerDialogCode = 0;
    this.templateDialog = false;
  }

  async editTemplate(template: Template) {
    if (this.company._id != undefined) {
      this.template = template;
      if(template.type == codeTypes.I){
        this.type = this.types[1];
      }else if(template.type == codeTypes.C){
        this.type = this.types[0];
      }else{
        this.type = this.types[2];
      }
      this.template.updatedAt = undefined;
      this.template.createdAt = undefined;
      this.headerDialogCode = 2;
      this.header = await this.translateService.instant('update_template');
      this.setErrorListDialog();
      this.templateDialog = true;
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  deleteTemplates(){
    for(let i= 0 ;i < this.selectedTemplates.length; i++){
      this.deleteOneTemplate(this.selectedTemplates[i]);
    }
  }

  deleteOneTemplate(template: Template){
    this.templateService.deleteTemplate(template._id).then(async () => {
      let index = this.templates.findIndex(t => t._id == template._id);
      this.templates.splice(index, 1);
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('msg_template_deleted'),
        life: 3000,
      });
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    })
  }

  async saveTemplate() {
    this.errorsListDialog[this.otherService.indexErrorsInListFind('name', this.errorsListDialog)].value = this.template.name != undefined && this.template.name != "";
    this.errorsListDialog[this.otherService.indexErrorsInListFind('type', this.errorsListDialog)].value = this.type != undefined && this.type != null;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('render', this.errorsListDialog)].value = this.template.render != undefined && this.template.render != "";

    if (this.errorsListDialog.find(elemnt => elemnt.value == false)) {
      console.log(this.errorsListDialog);
      return;
    }

    this.template.type = this.type.code;
    this.template.company_id = this.company._id;
    if(this.headerDialogCode == 1){
      //add template
      this.templateService.addTemplate(this.template).then(async (template: Template) => {
        this.template._id = template._id;
        this.template.createdAt = this.otherService.formatDate(template.createdAt);
        this.template.updatedAt = this.otherService.formatDate(template.updatedAt);
        this.templates.unshift(this.template);
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_template_added'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    }else if(this.headerDialogCode == 2){
      //update template
      this.templateService.updateTemplate(this.template).then(async (template: Template) => {
        let index = this.templates.findIndex(t => t._id == this.template._id);
        this.template.updatedAt = this.otherService.formatDate(template.updatedAt);
        this.templates[index] = this.template;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_template_updated'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    }
    this.templateDialog = false;
  }

}
