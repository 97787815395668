export class Proof {
  public _id: string | undefined;

  public name: string;
  public company_id: string;
  public selected: string | undefined;
  public histories: any[];

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.name = "";
    this.histories = [];
  }
}
