import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AmplifyService} from '../../../../core/service/amplify-service';
import {MessageService} from "primeng/api";
import {NotificationService} from "../../../../core/service/notification.service";



@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit {
  user: any;
  username: any;
  password:  any;
  email: any;

  signupForm: FormGroup|undefined;
    constructor(private router: Router ,private notificationService: NotificationService, private formBuilder: FormBuilder, private amplifyService: AmplifyService,private messageService: MessageService, ) {

    }

  ngOnInit(): void {
    this.notificationService.disconnect();
    this.signupForm = this.formBuilder.group({
      userName: ['', Validators.required],
      userPassword: ['', Validators.required],
      userEmail: ['', Validators.required, Validators.email],
    });
  }

  async signup() {
    if (this.signupForm.invalid) {
      return;
    }
    await this.amplifyService.signUp(this.signupForm.get('userName').value, this.signupForm.get('userPassword').value, this.signupForm.get('userEmail').value)
      .then(async (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Confirm your account. an email has been sent',
          life: 3000,
        });
        await this.router.navigate(['/login']);
      }).catch((err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.message,
          life: 3000,
        });
        console.log(err);
      });
  }
}
