import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MegaMenuItem, MenuItem, PrimeNGConfig } from 'primeng/api';
import { AmplifyService } from '../../core/service/amplify-service';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { OtherService } from "../../core/service/other.service";
import { environment } from '../../../environments/environment';
import { Company } from "../../core/models/company.model";
import { Accounting } from "../../core/models/accounting.model";
import { User } from "../../core/models/user.model";
import { CompanyService } from "../../core/service/company.service";
import { EmployeeService } from "../../core/service/employee.service";
import { PublicRoutingModule } from "../../modules/public/public-routing.module";
import { NotificationService } from "../../core/service/notification.service";


@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {
  timeStamp: string;
  accounting: Company; //selected company
  company: Company; //selected company
  //dashboard: Dashboard;
  salary: Company; //selected company

  manyAccounting: Company[]; //accounting of the owner (connected user)
  companies: Company[]; //companies of the owner (connected user)
  salaries: Company[]; //salaries of the owner (connected user)

  owner: User; //connected user

  //those attributes for the UI
  itemsCompany: MegaMenuItem[];
  itemsAccounting: MegaMenuItem[];
  itemsSalary: MegaMenuItem[];
  itemsMenu: MenuItem[];
  user: MenuItem[];
  userInfo: any = null;

  versionBackend: string;
  versionFrontend: string;
  session: boolean;
  lang: string;

  constructor(private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private amplifyService: AmplifyService,
    private router: Router,
    public translate: TranslateService,
    public otherService: OtherService,
    private employeeService: EmployeeService,
    private companyService: CompanyService,
    private publicRoutingModule: PublicRoutingModule,
    private notificationService: NotificationService
  ) {
    this.itemsMenu = [];
    this.itemsCompany = [];
    this.companies = [];
    this.manyAccounting = [];
    this.salaries = [];
    this.session = false;
  }

  doSomething(data: any): void {
    console.log('Picked date: ', data);
  }

  async ngOnInit() {
    let finance_block = {
      label: 'Finance',
      items: [
        { label: 'Company', icon: 'pi pi-fw pi-compass', routerLink: '/home/company' },
        { label: 'Partner', icon: 'pi pi-fw pi-briefcase', routerLink: '/home/partner' },
        { label: 'Account', icon: 'pi pi-fw pi-credit-card', routerLink: '/home/account' }
      ]
    };
    this.itemsMenu.push(finance_block);
    this.company = new Company();
    this.router.events.subscribe(async event => {
      if (event instanceof NavigationStart) {
        this.primengConfig.ripple = true;
        this.session = await this.getJwtToken() != null;
        let canLoad = this.publicRoutingModule.routes.find(route => route.path == event.url.substr(1, event.url.length)) == undefined;
        if (!this.session && canLoad) {
          await this.amplifyService.signOut();
          try {
            this.notificationService.disconnect();
          } catch (err) {
            console.warn("error disconnect socket in panel.component: ", err);
          }

          await this.router.navigate(['login']);
        } else {
          try {
            this.notificationService.connect();
          } catch (err) {
            console.warn("error connect socket in panel.component: ", err);
          }
        }

        this.user = [
          {
            label: 'Profile',
            items: [
              { label: 'Profil', icon: 'pi pi-fw pi-user-edit', routerLink: '/home/user' },
              { label: 'Message', icon: 'pi pi-fw pi-send', routerLink: '/home/message' },
              { label: 'Notification', icon: 'pi pi-fw pi-globe', routerLink: '/home/notification' },
              { label: 'Sign Out', icon: 'pi pi-fw pi-sign-out', command: () => this.logout() },
            ]
          }

        ];
        this.owner = this.otherService.getUser();
        if (this.owner != null && this.owner.roles != undefined) {
          let accounting_block = {
            label: 'Accounting',
            items: [
              { label: 'Accounting', icon: 'pi pi-fw pi-info-circle', routerLink: '/home/accounting' }
            ]
          };
          let document_block = {
            label: 'Document',
            items: [
              { label: 'Contract', icon: 'pi pi-fw pi-file-pdf', routerLink: '/home/contract' },
              { label: 'Invoice', icon: 'pi pi-fw pi-book', routerLink: '/home/invoice' },
              { label: 'File', icon: 'pi pi-fw pi-file-pdf', routerLink: '/home/file' },
              { label: 'Template', icon: 'pi pi-fw pi-cloud-download', routerLink: '/home/template' },
              { label: 'Proof', icon: 'pi pi-fw pi-paperclip', routerLink: '/home/proof' }
            ]
          };
          let rh_block = {
            label: 'RH',
            items: [
              { label: 'Activity reports', icon: 'pi pi-fw pi-align-justify', routerLink: '/home/cra' },
              { label: 'Pay statement ', icon: 'pi pi-fw pi-check', routerLink: '/home/salary' },
              { label: 'Employees', icon: 'pi pi-fw pi-users', routerLink: '/home/employee' }
            ]
          };

          if (this.owner.roles.accounting != undefined && this.owner.roles.accounting.length > 0 && this.itemsMenu.find(item => item.label == 'Accounting') == undefined) {
            this.itemsMenu.unshift(accounting_block);
          }
          if (this.owner.roles.companies != undefined && this.owner.roles.companies.length > 0 && this.itemsMenu.find(item => item.label == 'Document') == undefined && this.itemsMenu.find(item => item.label == 'RH') == undefined) {
            this.itemsMenu.push(document_block);
            this.itemsMenu.push(rh_block);
          }
          if (((this.owner.roles.salaries != undefined && this.owner.roles.salaries.length > 0) || (this.owner.roles.rh != undefined && this.owner.roles.rh.length > 0)) && this.owner.roles.accounting == undefined && this.owner.roles.companies == undefined) {
            this.itemsMenu = [rh_block];
          }

          if (this.owner.roles.companies != undefined) {
            this.companies = [];
            this.itemsCompany = [
              {
                label: await this.translate.instant('company'), icon: 'pi pi-fw pi-briefcase',
                items: [
                  []
                ]
              }
            ]
            for (let company_id of this.owner.roles.companies) {
              await this.companyService.getCompanyById(company_id).then(async (company: Company) => {
                if (company != null) {
                  this.companies.push(company);
                  this.itemsCompany[0].items[0].push({
                    label: await this.translate.instant('company_name'),
                    items: [{
                      label: company.name,
                      command: () => this.defineCompany(company)
                    }]
                  });
                }
              });
            }
          }

          if (this.owner.roles.accounting != undefined) {
            this.manyAccounting = [];
            this.itemsAccounting = [
              {
                label: await this.translate.instant('accounting'), icon: 'pi pi-fw pi-id-card',
                items: [
                  []
                ]
              }
            ]
            for (let company_id of this.owner.roles.accounting) {
              await this.companyService.getCompanyById(company_id).then(async (company: Company) => {
                if (company != null) {
                  this.manyAccounting.push(company);
                  this.itemsAccounting[0].items[0].push({
                    label: await this.translate.instant('company_name'),
                    items: [{
                      label: company.name,
                      command: () => this.defineCompany(company)
                    }]
                  });
                }
              });
            }
          }

          if (this.owner.roles.salaries != undefined || this.owner.roles.rh != undefined) {
            this.salaries = [];
            this.itemsSalary = [
              {
                label: await this.translate.instant('salaries'), icon: 'pi pi-fw pi-users',
                items: [
                  []
                ]
              }
            ]
            for (let company_id of this.owner.roles.salaries) {
              await this.companyService.getCompanyById(company_id).then(async (company: Company) => {
                if (company != null) {
                  this.salaries.push(company);
                  this.itemsSalary[0].items[0].push({
                    label: await this.translate.instant('company_name'),
                    items: [{
                      label: company.name,
                      command: () => this.defineCompany(company)
                    }]
                  });
                }
              });
            }
          }
        }

        await this.otherService.getCompany().then((result: any) => {
          if (result != false) {
            this.company = result
          } else {
            this.company = new Company();
          }
        });
      }
    });

    await this.otherService.getBackEndVersion().then((version: any) => {
      if (typeof version == 'string') {
        this.versionBackend = version;
      }
    });

    this.versionFrontend = environment.appVersion;
    this.timeStamp = environment.timeStamp;

  }

  defineCompany(company: Company) {
    if (company != undefined) {
      this.otherService.setCompany(company);
      this.company = company;
    }
  }


  logout(): void {
    this.session = false;
    this.amplifyService.signOut();
    try {
      this.notificationService.disconnect();
    } catch (err) {
      console.warn("error disconnect socket in panel.component: ", err);
    }
    this.router.navigateByUrl('/login').then(() => {
      window.location.reload();
    });
  }

  async useLanguage(language: string) {
    localStorage.setItem("language", language);
    await this.translate.use(language);
  }

  async getJwtToken() {
    return await this.otherService.getSession();
  }

  async resetCompanyAccounting() {
    localStorage.removeItem('companyObject');
    localStorage.removeItem('accountingObject');
    await this.router.navigateByUrl('/home').then(() => {
      window.location.reload();
    });
  }
}
