export class Benefit{
  public name: string | undefined;
  public rate: number | undefined;
  public reminder: boolean | undefined;
  public invoiceTemplate: string | undefined;
  public deadline: Date | string | undefined;
  constructor() {
    this.reminder = false;
  }
}
