import {Component, Input, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {Salary} from '../../../core/models/salary.model';
import {SalaryService} from '../../../core/service/salary.service';
import {Router} from '@angular/router';
import {Company} from '../../../core/models/company.model';
import {CompanyService} from '../../../core/service/company.service';
import {EmployeeService} from "../../../core/service/employee.service";
import {OperationService} from '../../../core/service/operation.service';
import {TranslateService} from "@ngx-translate/core";
import {OtherService} from "../../../core/service/other.service";
import {User} from "../../../core/models/user.model";
import {File} from "../../../core/models/file.model";
import {Employee} from "../../../core/models/employee.model";
import {Template} from "../../../core/models/template.model";
import {TemplateService} from "../../../core/service/template.service";
import {Proof} from "../../../core/models/proof.model";
import {ProofService} from "../../../core/service/proof.service";
import {FileService} from "../../../core/service/file.service";
import {UserService} from "../../../core/service/user.service";
import {Invoice, IStep} from "../../../core/models/invoice.model";

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.css'],
  providers: [DialogService, OperationService, MessageService, CompanyService, SalaryService, ConfirmationService, DynamicDialogConfig],

})
export class SalaryComponent implements OnInit {
  fileDialog: boolean;
  loading: boolean;
  companyList: string [];
  salary: Salary;
  salaries: Salary[];
  selectedSalaries: Salary[];
  salaryDialog: boolean;
  checkUpload: boolean;
  company: Company;
  employees: Employee[];
  user: User;
  page: number;
  selectedColumns: any[];
  cols: any[];
  errorsListDialog: any[];
  headerDialogCode: number;
  header: string;
  operation_types: string[];
  detailsDialog: boolean;
  isNotSalary: boolean;
  templateDialog: boolean;
  contentFileDialog: boolean;
  details: Map<string, string>;
  justDisplayDetails: boolean;
  key: string
  value: string;
  detailsKeys: any;
  selectedFile: File;
  pageTemplate: number;
  templates: Template[];
  selectedTemplate: Template;
  fileToDisplay: any;


  constructor(
    public dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private salaryService: SalaryService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private router: Router,
    private operationService: OperationService,
    public config: DynamicDialogConfig,
    private translateService: TranslateService,
    public otherService: OtherService,
    private templateService: TemplateService,
    public fileService: FileService
  ) {
  }

  async ngOnInit() {
    this.loading = true;
    this.user = this.otherService.getUser();
    this.isNotSalary = (this.user.roles.rh != undefined || this.user.roles.companies != undefined || this.user.roles.accounting != undefined);
    this.salary = new Salary();
    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.company = result
      }else{
        this.company = new Company();
      }
    });
    this.salaryDialog = false;
    this.templateDialog = false;
    this.contentFileDialog = false;
    this.checkUpload = false;
    this.setErrorListDialog();
    this.headerDialogCode = 0;
    this.header = "";
    this.key = "";
    this.value = "";
    this.salary = new Salary();
    this.detailsKeys = [];
    this.details = new Map<string, string>();
    this.page = 1;
    this.pageTemplate = 1;
    this.salaries = [];
    //if code header equal to 1 that's mean the dialog should be add else if header code equal to 2 that's mean the dialog should be update
    this.headerDialogCode = 0;
    this.setCols();
    await this.loadSalaries();
    await this.loadTemplates();
    this.loading = false;
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setErrorListDialog(){
    this.errorsListDialog = [
      {
        "key": "template",
        "value" : true
      },
      {
        'key': 'employee',
        'value': true
      },
      {
        'key': 'company',
        'value': true
      },
      {
        'key': 'from',
        'value': true
      },
      {
        'key': 'to',
        'value': true
      },
      {
        'key': 'global_cost',
        'value': true
      },
      {
        'key': 'net_salary',
        'value': true
      },
      {
        'key': 'leave_earned',
        'value': true
      },
      {
        'key': 'leave_took',
        'value': true
      },
      {
        'key': 'details',
        'value': true
      },
      {
        'key': 'render',
        'value': true
      },
      {
        'key': 'file',
        'value': true
      }
    ];
  }

  setCols() {
    this.cols = [
      {
        header: this.translateService.instant('employee'),
        field: "employee",
        subField: "name"
      },
      {
        header: this.translateService.instant('date_from'),
        field: "from"
      },
      {
        header: this.translateService.instant('date_to'),
        field: "to"
      },
      {
        header: this.translateService.instant('company'),
        field: "company",
        subField: "name"
      },
      {
        header: this.translateService.instant('global_cost'),
        field: "global_cost"
      },
      {
        header: this.translateService.instant('net_salary'),
        field: "net_salary"
      },
      {
        header: this.translateService.instant('leave_earned'),
        field: "leave_earned"
      },
      {
        header: this.translateService.instant('leave_took'),
        field: "leave_took"
      },
      {
        header: this.translateService.instant('details'),
        field: "details"
      },
      {
        header: this.translateService.instant('render'),
        field: "render"
      },
      {
        header: this.translateService.instant('file'),
        field: "file",
        subField: "name"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('employee'),
        field: "employee",
        subField: "name"
      },
      {
        header: this.translateService.instant('date_from'),
        field: "from"
      },
      {
        header: this.translateService.instant('date_to'),
        field: "to"
      },
      {
        header: this.translateService.instant('global_cost'),
        field: "global_cost"
      },
      {
        header: this.translateService.instant('net_salary'),
        field: "net_salary"
      },
      {
        header: this.translateService.instant('leave_earned'),
        field: "leave_earned"
      },
      {
        header: this.translateService.instant('leave_took'),
        field: "leave_took"
      }
    ];
  }

  async nextPage() {
    if (this.salaries.length >= 10) {
      this.page++;
      await this.loadSalaries();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadSalaries();
    }
  }

  async loadSalaries() {
    if(this.company._id != undefined){
      if(this.isNotSalary){
        await this.otherService.loadSalaries(this.page, this.company._id).then((salaries: Salary[]) => {
          this.salaries = salaries;
        });
      }else{
        let employee: Employee[] = await this.employeeService.findEmployeeByEmail(this.user.email);
        if(employee.length != 0){
          await this.otherService.loadSalaries(this.page, this.company._id, employee[0]._id).then((salaries: Salary[]) => {
            this.salaries = salaries;
          });
        }
      }
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  async openNew() {
    if (this.company._id != undefined) {
      this.header = await this.translateService.instant('add_salary');
      await this.otherService.loadEmployees(this.page, this.company._id).then((employees: Employee[]) => {
        this.employees = employees;
      });
      this.salary = new Salary();
      this.checkUpload = false;
      this.setErrorListDialog();
      this.headerDialogCode = 1;
      this.salaryDialog = true;
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  async onUpload(event) {
    await this.otherService.ngOnUpload(event,this.company,this.user).then((fileId: string[]) => {
      this.salary.file = fileId[0];
      this.errorsListDialog[this.otherService.indexErrorsInListFind('file',this.errorsListDialog)].value = true;
      this.checkUpload = true;
    });
  }

  async deleteSelectedSalaries() {
    this.confirmationService.confirm({
      message: '\n' + await this.translateService.instant('msg_confirm_deletes'),
      header: await this.translateService.instant('confirm'),
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        for (let i = 0; i < this.selectedSalaries.length; i++) {
          await this.salaryService.deleteSalary(this.selectedSalaries[i]._id);
        }
        await this.loadSalaries();
        this.selectedSalaries = null;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_salaries_deleted'),
          life: 3000,
        });
        this.hideDialogSalary();
      },
    });

  }

  async editSalary(salary: Salary) {
    this.header = await this.translateService.instant('update_salary');
    await this.otherService.loadEmployees(this.page, this.company._id).then((employees: Employee[]) => {
      this.employees = employees;
    });
    this.setErrorListDialog();
    this.checkUpload = false;
    this.salary = {...salary};
    this.headerDialogCode = 2;
    this.salaryDialog = true;
  }

  async deleteSalary(salary: Salary) {
    this.confirmationService.confirm({
      message: await this.translateService.instant('msg_confirm_delete') + ' ' + salary._id + '?',
      header: await this.translateService.instant('confirm'),
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await this.salaryService.deleteSalary(salary._id).then(async () => {
          let index = this.salaries.findIndex(s => s._id == salary._id);
          this.salaries.splice(index, 1);
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('msg_salary_deleted'),
            life: 3000,
          });
        });
      },
    });
    this.hideDialogSalary();
  }

  hideDialogSalary() {
    this.salaryDialog = false;
    this.headerDialogCode = 0;
    this.header = "";
  }

  async saveSalary() {
    this.loading = true;
    this.salary.updatedAt = undefined;
    this.salary.createdAt = undefined;
    this.salary.company = this.company;
    this.salary.company_id = this.company._id;
    this.salary.employee_id = this.salary.employee._id;

    let date_to = new Date(this.salary.to);
    let date_from = new Date(this.salary.from);
    this.errorsListDialog[this.otherService.indexErrorsInListFind('from', this.errorsListDialog)].value = !(date_from > date_to) && this.salary.from != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('to', this.errorsListDialog)].value = !(date_from > date_to) && this.salary.to != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('employee', this.errorsListDialog)].value = this.salary.employee._id != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('leave_earned', this.errorsListDialog)].value = this.salary.leave_earned != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('leave_took', this.errorsListDialog)].value = this.salary.leave_took != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('global_cost', this.errorsListDialog)].value = this.salary.global_cost != undefined && !isNaN(+this.salary.global_cost);
    this.errorsListDialog[this.otherService.indexErrorsInListFind('net_salary', this.errorsListDialog)].value = this.salary.net_salary != undefined && !isNaN(+this.salary.net_salary);
    this.errorsListDialog[this.otherService.indexErrorsInListFind('file', this.errorsListDialog)].value = this.salary.file != undefined || this.salary.render != undefined;

    if (this.errorsListDialog.find(elemnt => elemnt.value == false)) {
      console.log(this.errorsListDialog);
      this.loading = false;
      return;
    }

    if (this.headerDialogCode == 1) {
      //add salary
      await this.salaryService.addSalary(this.salary).then(async (salary: Salary) => {
        salary.createdAt = this.otherService.formatDate(salary.createdAt);
        salary.from = this.otherService.formatDate(salary.from);
        salary.to = this.otherService.formatDate(salary.to);
        this.salaries.unshift(salary);
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_salary_added'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    } else if (this.headerDialogCode == 2) {
      //update salary
      await this.salaryService.updateSalary(this.salary).then(async () => {
        let index = this.salaries.findIndex(s => s._id == this.salary._id);
        this.salaries[index] = this.salary;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_salary_updated'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      })
    }
    this.loading = false;
    this.hideDialogSalary();
  }

  openCloseDialogToAddDetail() {
    this.salaryDialog = !this.salaryDialog;
    this.detailsDialog = !this.detailsDialog;
  }

  addDetail() {
    try {
      this.details.set(this.key.toString().trim(), this.value.toString());
      this.detailsKeys = [...this.details.keys()];
      this.key = "";
      this.value = "";
    } catch (err) {
      console.log(err);
    }
  }

  async showFiles(fileId: string) {
    await this.otherService.showFiles(fileId).then((data) => {
      this.fileToDisplay = data;
      this.contentFileDialog = true;
    }).catch((err) => {
      console.log(err);
    });
  }

  addDetailsToSalary() {
    for (let key of this.detailsKeys) {
      console.log(key);
    }
    this.salary.details = this.details;
    this.openCloseDialogToAddDetail();
  }


  async generateSalaryFile(salary: Salary) {
    if (salary.render) {
      salary.file = await this.otherService.uploadFromRender(salary.render, salary, 'S', this.user, this.company);
      await this.salaryService.updateSalary(salary).then(async (res: Salary) => {
        let index = this.salaries.findIndex(c => c._id == salary._id);
        salary.createdAt = res.createdAt;
        salary.updatedAt = res.updatedAt;
        this.salaries[index] = salary;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('salary_generated'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    }
  }

  generateRender(){
    if(this.selectedTemplate._id != undefined){
      let from = this.salary.from;
      let to = this.salary.to;
      this.salary.from? this.salary.from = this.otherService.formatDate(this.salary.from) : this.salary.from = undefined;
      this.salary.to? this.salary.to = this.otherService.formatDate(this.salary.to) : this.salary.to = undefined;
      this.salary.render = this.otherService.generateRender(this.selectedTemplate, this.salary, 'S');
      this.salary.from = from;
      this.salary.to = to;
    }
  }

  async loadTemplates() {
    await this.templateService.getAllTemplates(this.company._id, 'S', 10, this.pageTemplate).then((templates) => {
      this.templates = templates.map(item => {
        let template = item
        template.createdAt = this.otherService.formatDate(item.createdAt);
        return template
      });
    })
  }

  disableUploadFile(): boolean{
    try{
      if(this.selectedTemplate._id != undefined || this.salary.render != undefined){
        return true
      }else{
        return false
      }
    }catch (err){
      return false
    }
  }
}

