import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {File} from "../models/file.model";
import axios from 'axios'

@Injectable({
  providedIn: 'root'
})
export class FileService {

  public fileUrl = '/files/';

  constructor(private http:HttpClient) {}

  getAllFile(limit:any, page: any, companyId: any){
    let params = new HttpParams();
    params = params.append("limit", limit);
    params = params.append("page", page);
    params = params.append("companyId", companyId);
    return this.http.get<any>(this.fileUrl, {params}).toPromise();
  }

  getAllFileWithUser(userId: any){
    let params = new HttpParams();
    params = params.append("userId", userId);
    return this.http.get<any>(this.fileUrl,{params}).toPromise();
  }

  getFileById(id: any) {
    return this.http.get<any>(this.fileUrl + id).toPromise();
  }
  downloadFile(url: any) {
    axios({
      url: url, //your url image.source
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
  addFile(file: File) {
    return this.http.post(this.fileUrl,file).toPromise();
  }
  updateFile(file: File){
    return this.http.put(this.fileUrl, file).toPromise();
  }
  deleteFile(fileId: string) {
    return this.http.delete(this.fileUrl + fileId).toPromise();
  }
}
