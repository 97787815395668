import {Company} from "./company.model";
import {Partner} from "./partner.model";
import {Invoice} from "./invoice.model";
import {Map} from "./otherModels/map.model";
import {IStep} from "./otherModels/step.model";
import {Benefit} from "./otherModels/benefit.model";

export class Contract{
  public _id: string | undefined;

  public company_id: string | undefined;
  public company: Company;
  public partner: Partner;
  public name: string ;
  public vat: number | undefined;
  public invoices: Invoice[] | undefined;
  public details : Map[] | undefined;
  public currency: any | undefined;
  public benefit: Benefit | undefined;
  public file: string | undefined;
  public render: string | undefined;
  public steps: IStep[] | undefined;
  public uuid: string;
  public employee: string | undefined;
  public relaunch: boolean | undefined;
  public unit_price: number | undefined;
  public proofs: string[] | undefined ;
  public deadline: number | undefined;
  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.company = new Company();
    this.partner = new Partner();
    this.details = [];
    this.steps = [];
    this.steps.push(new IStep());
    this.proofs = [];
    this.name = "";
    this.benefit = new Benefit();
  }
}
