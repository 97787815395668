import {Component, Input, OnInit, NO_ERRORS_SCHEMA} from '@angular/core';
import {ConfirmationService, FilterService, MessageService, PrimeNGConfig} from 'primeng/api';
import {Operation} from '../../../../core/models/operation.model';
import {OperationService} from '../../../../core/service/operation.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ActivatedRoute, NavigationStart, Params} from '@angular/router';
import {AccountService} from "../../../../core/service/account.service";
import {Account} from "../../../../core/models/account.model";
import {TranslateService} from "@ngx-translate/core";
import {Api} from "../../../../core/models/api.model";
import {FileService} from "../../../../core/service/file.service";
import {File} from "../../../../core/models/file.model";
import {AmplifyService} from "../../../../core/service/amplify-service";
import {OtherService} from "../../../../core/service/other.service";
import {Company} from "../../../../core/models/company.model";
import * as xlsx from "xlsx";
import {User} from "../../../../core/models/user.model";
type AOA = any[][];
import {ExcelService} from "../../../../core/service/excel.service";
import * as moment from 'moment';


function createUTCdateForISO(dateString) {
  const offset = new Date().getTimezoneOffset();
  const myDate = Date.parse(dateString) - (offset * 60 * 1000);
  const dateAsISO = new Date(myDate).toISOString();
  return dateAsISO;
}

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.css'],
  providers: [DialogService, AccountService, MessageService, OperationService, ConfirmationService]

})

export class OperationComponent implements OnInit {
  operation: Operation;
  op: Operation;
  operations: Operation[];
  account: Account;
  selectedOperations: Operation[];
  submitted: boolean;
  operationDialog: boolean;
  ImportOperationDialog: boolean;
  ImportOperationDialogPop: boolean;
  operationDialogSearch: boolean;
  page: number;
  account_id: string;
  cols: any[];
  selectedColumns: any[];
  tmp: any[];
  company: Company;
  files: File[];
  operation_type: any[];
  operation_types: string[];
  selectedFiles: File[];
  filesToDiplay: any[];
  displayDetailsOfFilesDialog: boolean;
  confirmationVerification: boolean;
  images: any;
  owner: any;
  alert: string;
  hide: boolean;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  short_desc: any;
  operation_date: any;
  value_date: any;
  amount: number;
  bank_name: string;
  operationsExcel: Operation[];
  colsExcel: any[];
  selectedColumnsExcel: any[];
  header: string;
  user: User;
  contentFileDialog: boolean;
  private yourArrayWithoutDuplicates: [];
  private a: string;
  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }
  @Input() get selectedColumnsFunctionExcel(): any[] {
    return this.selectedColumnsExcel;
  }

  constructor(
    private amplifyService: AmplifyService,
    private filterService: FilterService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    public otherService: OtherService,
    private operationService: OperationService,
    private accountService: AccountService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    public fileService: FileService,
    private excelService: ExcelService
  ) {
    // this.filesToDiplay= [];
    this.operations = [];
    this.operation = new Operation();
    this.operationsExcel= [];
    this.op = new Operation();
    this.alert = "";
    this.hide= false;
  }


  async ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.account_id = params['id'];
      }
    );
    this.short_desc = undefined;
    this.operation_date = undefined;
    this.value_date = undefined;
    this.amount = undefined;
    this.bank_name = undefined;
    this.contentFileDialog = false;
    this.account = new Account();
    this.user = this.otherService.getUser();
    this.otherService.getCompany().then((company: Company) => {
      this.company = company;
    })
    this.owner = this.otherService.getUser();
    this.files = await this.fileService.getAllFileWithUser(this.owner._id);
    this.operations = [];
    this.operationsExcel= [];
    this.page = 1;
    this.operationDialog=false
    this.operation = new Operation();
    this.op = new Operation();
    await this.loadOperations();
    console.log(this.account_id);
    // console.log("this bic",this.account.bic );
    await this.accountService.getAccountById(this.account_id).then((account: Account) => {
      this.account = account;
    }).catch((err) => {
      console.log(err);
    });

    this.setCols();
    // this.setColsExcel();
  }


  ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569)*86400*1000));
  }

  async nextPage() {
    if (this.operations.length >= 10) {
      this.page++;
      await this.loadOperations();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadOperations();
    }
  }

  async loadOperations() {
    await this.operationService.getAllOperation(10, this.page, this.account_id, this.short_desc, this.operation_date, this.value_date, this.amount, this.bank_name).then((operations: Operation[]) => {
      this.operations = operations.map(c => {
        let operation = c
        operation.createdAt = this.otherService.formatDate(c.createdAt);
        return operation
      });
    });
    console.log(this.operations)
  }


  /*addOperation() {
    if (this.account._id != undefined) {
      this.operation = new Operation();
      this.operationDialog = true;
    } else {
      console.log('erreur');
    }
  }*/

  async deleteSelectedOperations() {
    for (let i = 0; i < this.selectedOperations.length; i++) {
      await this.deleteOneOperation(this.selectedOperations[i]);
    }
  }

  async editOperation(operation: Operation) {

    this.operation = {...operation};
    this.header = await this.translateService.instant('operation_account');
    this.operationDialog = true;

  }

  hideDialog() {
    this.operationDialog = false;
    this.operationDialogSearch = false;
    this.displayDetailsOfFilesDialog = false;
    //this.confirmationImportAll=false;
    this.confirmationVerification=false;
  }

  async saveOperation() {
    this.operation.updatedAt = undefined;
    this.operation.createdAt = undefined;
    this.operation.account_id = this.account_id;
    // this.operation.files = [];
    /*  for (let f of this.selectedFiles) {
        this.operation.files.push(f._id);
      }*/
    //if does not exist , create a new one
    if (this.operation._id == undefined) {
      this.operationService.addOperation(this.operation).catch((err) => {
        console.log(err)
      });
      this.operations.push(this.operation);
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('msg_operation_created'),
        life: 3000
      });
    } else {
      //search the operation if exist so update it
      this.operationService.editOperation(this.operation)
        .then(async () => {
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('msg_operation_updated'),
            life: 3000
          });
        })
        .catch(async (err) => {
          console.log(err);
          this.messageService.add({
            severity: 'error',
            summary: await this.translateService.instant('msg_error'),
            detail: err,
            life: 3000
          });
        });
      await this.loadOperations();
      this.operationDialog = false;
    }
    this.operations = [...this.operations];
    this.operationDialog = false;
  }

  async deleteOneOperation(operation: Operation) {
   // console.log("the operations is: ", this.operations)
    this.operationService.deleteOperation(operation._id).then(async () => {
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('msg_operation_deleted'),
        life: 3000,
      });
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    });
    await this.loadOperations();
    this.operationDialog = false;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols() {
    this.cols = [
      /* {
         header: this.translateService.instant('account'),
         field: "_id"
       },*/
      {
        header: this.translateService.instant('short_desc'),
        field: "short_desc"
      },
      /* {
         header: this.translateService.instant('description'),
         field: "description"
       },*/
      {
        header: this.translateService.instant('operation_date'),
        field: "operation_date"
      },
      {
        header: this.translateService.instant('value_date'),
        field: "value_date"
      },
      {
        header: this.translateService.instant('amount'),
        field: "amount"
      },
      {
        header: this.translateService.instant('type'),
        field: "type"
      },

      {
        header: this.translateService.instant('comment'),
        field: "comment"
      },
      {
        header: this.translateService.instant('bank_name'),
        field: "bank_name"
      }
    ];
    this.selectedColumns = [
      /* {
         header: this.translateService.instant('account'),
         field: "_id"
       },*/
      {
        header: this.translateService.instant('short_desc'),
        field: "short_desc"
      },
      /*{
        header: this.translateService.instant('description'),
        field: "description"
      },*/
      {
        header: this.translateService.instant('operation_date'),
        field: "operation_date"
      }
    ];
  }

  async displayDetailsOfFile(files: string[]) {
    this.tmp = [];
   // console.warn("hi", files);
    for (let f of files) {
      await this.fileService.getFileById(f)
        .then(async (data) => {
          let level = data.path.substr(0, data.path.indexOf("/"));
          await this.amplifyService.getFile(data.hash, data.path.replace(level + "/", ""), level).then((awsFile) => {
            console.warn("acc", awsFile.toString());
            this.tmp.push({source: awsFile, name: data.name});
          }).catch((err) => {
            console.warn("err");
          })
        });
    }
    this.filesToDiplay = this.tmp;
   // console.warn("filesToDiplay", this.filesToDiplay);
   // console.warn("acc", this.filesToDiplay);
   // console.warn("filesToDiplay2", this.filesToDiplay);
    this.displayDetailsOfFilesDialog = true;

  }

  download(url: any) {
    console.log(url);
    this.fileService.downloadFile(url);
  }

  async searchOperation() {
    this.short_desc? this.short_desc = this.short_desc.toUpperCase().trim() : this.short_desc = undefined;
    this.value_date? this.value_date= this.value_date.trim() : this.value_date = undefined;
    this.operation_date? this.operation_date = this.operation_date.trim() : this.operation_date = undefined;
    await this.loadOperations();
    this.short_desc = undefined;
    this.operation_date = undefined;
    this.value_date = undefined;
    this.amount = undefined;
    this.operationDialogSearch = false;
  }


  importOperation() {
    if (this.account._id != undefined) {
      this.operation = new Operation();
      this.ImportOperationDialog = true;
    } else {
      console.log('erreur');
    }
  }





  data: AOA = [[1, 2], [3, 4]];
  wopts: xlsx.WritingOptions = {bookType: 'xlsx', type: 'array'};
  fileName: string = 'SheetJS.xlsx';


  onFileChange(evt: any) {

    if (this.account._id != undefined) {
      this.operation = new Operation();
      // this.ImportOperationDialogPop = true;

      /* wire up file reader */
      const target: DataTransfer = <DataTransfer>(evt.target);
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: xlsx.WorkBook = xlsx.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: xlsx.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.data = <AOA>(xlsx.utils.sheet_to_json(ws, {header: 1}));
       // console.log("this data xls dans json", this.data);


       // console.log(this.account.iban.substr(this.account.iban.length - 15));
      //  console.log((this.data[4][0] && this.data[4][2]).trim().substr(this.account.iban.length - 10).slice(4,8));

        this.operationsExcel = [];
        let bank_name;
        let comment;
        let description;
        let account_id;
        let arrayTitle = [];
        let indexOperation = -1;
        let indexValue = -1;
        let indexLibel = -1;
        this.account.iban=this.account.iban.substr(this.account.iban.length - 15);

       // if (this.account.bic == "BSTUTNTTINT" && this.data[7].length == 9 && this.account.iban==(this.data[4][0] && this.data[4][2]).trim().substr(this.account.iban.length - 10))

          if (this.account.bic == "BSTUTNTTINT" && this.data[7].length == 9 && this.account.iban == this.data[4][2].trim())
        {
         // console.log("this iban ",(this.account.iban.substr(this.account.iban.length - 10).slice(4,8)));
         // console.log("this iban avec sous", this.data[4][2].trim().substr(this.account.iban.length - 15));
          //console.log("this 4 2" ,this.data[4][2]);
          bank_name = this.data[0][0];
          description = this.data[2][0];
          //account_id = (this.data[4][0] && this.data[4][2]).trim();
          for (let row = 7; row < this.data.length; row++) {
           // console.log("1 ",this.data[row].length)
            if (this.data[row].length > 3) {
              if (arrayTitle.length == 0) {
                arrayTitle = this.data[row];
                indexOperation = arrayTitle.findIndex(str => str.toUpperCase().indexOf('OPERATION') != -1);
                indexValue = arrayTitle.findIndex(str => str.toUpperCase().indexOf('VAL') != -1);
                indexLibel = arrayTitle.findIndex(str => str.toUpperCase().indexOf('LIBEL') != -1);
              } else {
                let op = new Operation();
                op.bank_name = bank_name;
                op.comment = " ";
                op.account_id = this.account._id;
                op.files = [];
                op.short_desc = this.data[row][indexLibel];
                op.description = description;

                let dateString = this.data[row][indexOperation]; // Oct 23
                let dateMomentObject = moment(dateString, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
                op.operation_date = dateMomentObject.toDate().toISOString(); //
             //   console.log("op.operation_date",op.operation_date);




                let dateStringValue = this.data[row][indexValue]; // Oct 23
                let dateMomentObjectValue = moment(dateStringValue, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
                op.value_date = dateMomentObjectValue.toDate().toISOString(); //


                let x= Number(this.data[row][7])||0;
                let y= Number(this.data[row][6])||0;

                op.amount=x-y;
                this.operationsExcel.push(op);
              }
            }
          }
        } else if (this.account.bic == "BNPAFRPPXXX" && this.data[2].length == 6 && this.account.iban.substr(this.account.iban.length - 10).slice(4,8)==this.data[0][2].replace(/\*/g, '')) {
          bank_name = this.data[0][0];
          comment = this.data[0][1];
          //account_id = this.data[0][2];
          for (let row = 2; row < this.data.length; row++) {
           // console.log("2 ",this.data[row].length)
            if (this.data[row].length > 3) {
              if (arrayTitle.length == 0) {
                arrayTitle = this.data[row];
              } else {
                let op = new Operation();
                op.bank_name = bank_name;
                op.comment = comment;
                op.account_id = this.account._id;
                op.files = [];
                op.short_desc = this.data[row][1];
                op.description = this.data[row][3];
                op.operation_date = this.ExcelDateToJSDate(this.data[row][0]).toISOString();
               // console.log(" op.operation_date ", op.operation_date );
                op.value_date = this.ExcelDateToJSDate(this.data[row][4]).toISOString();
                op.amount = Number(this.data[row][5]) || 0;
                this.operationsExcel.push(op);
              }
            }}
        }else{
          console.log("format not found");
          alert("format not found or iban ");
        }

        console.log("this.operationsExcel ",this.operationsExcel);
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      console.log('erreur');
    }

  }



  async addOperationImport(op: Operation) {

    if (this.operations.indexOf(op) !== -1) {
      this.alert = "La valeur existe!"
    } else {
      this.alert ="La valeur n'existe pas!";
    }
    this.confirmationService.confirm({
      message: this.alert + 'Are you sure to add ',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        op.value_date = new Date(op.value_date).toISOString();
        op.operation_date= new Date(op.operation_date).toISOString();
        await this.operationService.addOperation(op).then(async (operation: Operation) => {
          op._id = operation._id;
          let index = this.operationsExcel.findIndex(o => o._id == op._id);
          this.operations.push(op);
          this.operationsExcel.splice(index, 1);
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('msg_operation_created'),
            life: 3000
          });
        })
      },
    });
    await this.loadOperations();
    this.operations = [...this.operations];
  }


  async addOperationImportAll() {

    let duplicatedOperations = [];
    for(let op of this.operationsExcel ){
      op.value_date = new Date(op.value_date).toISOString();
      op.operation_date= new Date(op.operation_date).toISOString();
      await this.operationService.findOneOperation(op.operation_date, op.value_date, op.amount).then((operations: Operation[]) => {
        duplicatedOperations = duplicatedOperations.concat(operations)
      });
      if(duplicatedOperations.find(opr => opr.operation_date == op.operation_date && opr.value_date == op.value_date && opr.amount == op.amount) == undefined){
        await this.operationService.addOperation(op).then(async (operation: Operation) => {
          this.operations.push(operation);
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('msg_operation_created'),
            life: 3000
          });
        })
      }
    }
    this.hide=true;
    this.operationsExcel = duplicatedOperations;

  }



  async addOperationImportDuplicate(op: Operation) {
    //document.getElementById("addOperationImport").disabled = true;
    op._id = undefined;
    op.createdAt =undefined;
    op.updatedAt = undefined;
    this.confirmationService.confirm({
      message: 'Are you sure to add this duplicate ',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        op.value_date = new Date(op.value_date).toISOString();
        op.operation_date= new Date(op.operation_date).toISOString();
        await this.operationService.addOperation(op).then(async (operation: Operation) => {
          op._id = operation._id;
          let index = this.operationsExcel.findIndex(o => o._id == op._id);
          this.operations.push(op);
          this.operationsExcel.splice(index, 1);
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('msg_operation_created'),
            life: 3000
          });
        })
      },
    });
    await this.loadOperations();
    this.operations = [...this.operations];
  }



  async onUpload(event) {
    await this.otherService.ngOnUpload(event, this.company, this.user).then((files: string[]) => {
      this.operation.files = files;
    });
  }
  filesToUploads: File[];

  filesToDisplay: any[];
  async showFiles(operation: Operation) {
    let array = [];
    for (let fileId of operation.files) {
      await this.otherService.showFiles(fileId).then((fileAws: any) => {
        array.push({source: fileAws.source, name: fileAws.name});
      });
    }
    this.filesToDisplay = array;
    this.contentFileDialog = true;
  }

  exportExcel(): void
  {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: xlsx.WorkSheet =xlsx.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    xlsx.writeFile(wb, this.fileName);

  }


  downloadModelTN() {
   let modelTN: any = [
     {"ATTIJARI BANK":"","field2":"","field3":"","field4":"","field5":"","field6":"","field7":"","field8":"","field9":""},
     {"ATTIJARI BANK":"LISTE DES DERNIERS MOUVEMENTS ENTRE 16/12/2021 ET 05/01/2022","field2":"","field3":"","field4":"","field5":"","field6":"","field7":"","field8":"","field9":""},
     {"ATTIJARI BANK":"","field2":"","field3":"","field4":"","field5":"","field6":"","field7":"","field8":"","field9":""},
     {"ATTIJARI BANK":"COMPTE","field2":"","field3":"xxxxxxxxxxxxxxxxxx","field4":"","field5":"","field6":"","field7":"","field8":"","field9":""},
     {"ATTIJARI BANK":"NOM ET PRENOM / RAISON SOCIALE :","field2":"","field3":"S�T� xxxxx","field4":"","field5":"","field6":"","field7":"","field8":"","field9":""}
     ,
     {"ATTIJARI BANK":"","field2":"","field3":"","field4":"","field5":"","field6":"","field7":"","field8":"","field9":""},
     {"ATTIJARI BANK":"DATE OPERATION","field2":"DATE VALEUR","field3":"LIBELLE","field4":"","field5":"","field6":"REFERENCE","field7":"DEBIT (TND)","field8":"CREDIT (TND)","field9":"SOLDE (TND)"}
   ];
      this.excelService.exportAsExcelFile(modelTN, 'sample');
  }

  downloadModelFR(){
let modelFR: any= [
  {"Compte de ch�ques":"","Compte de ch�quess":"","****xxxx":"","xx/xx/xxxx":"","field5":"","xxxxxxxamount total":""}
  ,
  {"Compte de ch�ques":"Date operation ","Compte de ch�quess":"Libelle court","****xxxx":"Type operation","xx/xx/xxxx":"Libelle operation","field5":"Valeur","xxxxxxxamount total":"Montant operation en euro"}

]
    this.excelService.exportAsExcelFile(modelFR, 'sample');
  }



}
