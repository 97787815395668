import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Message} from '../../core/models/message.module';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public messageUrl = '/messages/';

    constructor(private http: HttpClient) { }

  getAllMessages(limit: any, page: any, companyId: any){
    let params = new HttpParams();
    params = params.append('limit' , limit);
    params = params.append('page' , page);
    params = params.append('companyId', companyId);
    return this.http.get<any>(this.messageUrl, {params}).toPromise();
  }

  getMessageById(id: any){return this.http.get<any>(this.messageUrl +id).toPromise(); }


  addMessage(message: Message){ return this.http.post(this.messageUrl,message).toPromise();}


  updateMessage(message: Message){return this.http.put(this.messageUrl,message).toPromise();}



  deleteMessage(messageId: string){return this.http.delete(this.messageUrl +messageId).toPromise();}

}
