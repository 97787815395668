import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Partner} from "../models/partner.model";
import {Salary} from "../models/salary.model";

@Injectable({
  providedIn: 'root'
})

export class SalaryService {
  public salaryUrl = '/salaries/';

  constructor(private http: HttpClient) { }

  getAllSalaries(limit: any, page: any, companyId : any, employeeId?: any) {
    let params = new HttpParams();
    params = params.append('limit' , limit);
    params = params.append('page' , page);
    params = params.append('companyId' , companyId);
    if(employeeId != undefined){
      params = params.append('employeeId' , employeeId);
    }
    return this.http.get<any>(this.salaryUrl, {params}).toPromise();
  }

  getSalaryById(id: any) {
    return this.http.get<any>(this.salaryUrl + id).toPromise();
  }

  addSalary(Salary: Salary) {
    return this.http.post(this.salaryUrl, Salary).toPromise();
  }

  updateSalary(Salary: Salary) {
    return this.http.put(this.salaryUrl, Salary).toPromise();
  }

  deleteSalary(id: string) {
    return this.http.delete(this.salaryUrl + id).toPromise();
  }

  getAllSalary(number: number, page: number) {

  }
}
