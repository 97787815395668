import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Accounting} from "../models/accounting.model";

@Injectable({
  providedIn: 'root'
})
export class AccountingService{
  public AccountingUrl = '/accounting/';

  constructor(private http: HttpClient) {
  }

  getAllAccounting(limit:any,page:any, companyId:any){
    let params = new HttpParams();
    params = params.append('limit', limit);
    params = params.append('page', page);
    params = params.append('companyId', companyId);
    return this.http.get<any>(this.AccountingUrl, {params}).toPromise();
  }

  getAccountingById(id: string){
    return this.http.get<any>(this.AccountingUrl + id).toPromise();
  }

  updateAccounting(accounting: Accounting){
    return this.http.put(this.AccountingUrl, accounting).toPromise();
  }

  deleteAccounting(id : string){
    return this.http.delete(this.AccountingUrl + id).toPromise();
  }

  insertAccounting(accounting: Accounting){
    return this.http.post(this.AccountingUrl, accounting).toPromise();
  }
}
