import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AmplifyService} from "../../../core/service/amplify-service";
import {User} from "../../../core/models/user.model";
import {UserService} from "../../../core/service/user.service";
import {OtherService} from "../../../core/service/other.service";
import {TranslateService} from "@ngx-translate/core";
import {EmployeeService} from "../../../core/service/employee.service";
import {NotificationService} from "../../../core/service/notification.service"


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  msgErr: string;
  password: any;
  signInForm: any; //type FormGroup
  jwtToken: string;
  user: User;
  loading: boolean;

  constructor(private router: Router, private notificationService: NotificationService, private translateService: TranslateService, private formBuilder: FormBuilder, private amplifyService: AmplifyService, private userService: UserService, public otherService: OtherService, private employeeService: EmployeeService) {
  }

  async ngOnInit() {
    try{
      this.notificationService.disconnect();
    }catch (err) {
      console.warn("error connect socket in login.component: ", err);
    }
    this.jwtToken = '';
    this.loading = false;
    this.msgErr = '';
    this.signInForm = this.formBuilder.group({
      userName: ['', Validators.required],
      userPassword: ['', Validators.required],
    });

    await this.amplifyService.getUserInfo()
      .then((data) => {
        this.jwtToken = data.signInUserSession.accessToken.jwtToken;
        localStorage.setItem("jwtToken", this.jwtToken);
      })
      .catch((err) => {
        this.jwtToken = undefined;
        localStorage.removeItem("jwtToken");
      })

  }

  async signin() {
    if (this.signInForm.invalid) {
      this.msgErr = this.signInForm.value;
      return;
    } else {
      this.loading = true;
      await this.amplifyService.signIn(this.signInForm.get('userName').value.trim().replace(/\s/g, ''), this.signInForm.get('userPassword').value)
        .then(async (data) => {
          this.jwtToken = data.signInUserSession.accessToken.jwtToken;
          this.user = new User(data.username.trim().replace(/\s/g, ''), data.attributes.email.trim().replace(/\s/g, ''));
          this.user.email_verified = data.attributes.email_verified;
          this.user.sub = data.attributes.sub;
          this.user.clientId = data.pool.clientId;
          this.user.userPoolId = data.pool.userPoolId;

          await this.userService.ajoutUser(this.user).then(async (user: User) => {
            this.user._id = user._id;
            this.user.roles = user.roles;
            this.otherService.setUser(this.user);
            localStorage.setItem("jwtToken", this.jwtToken);
            await this.router.navigate(['/home']);
          }).catch(async (err) => {
            this.msgErr = err.message;
          });
        })
        .catch((err) => {
          this.msgErr = err.message;
        });
      this.loading = false;
    }
  }


}
