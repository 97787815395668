import {Inject, Injectable} from "@angular/core";
import { Socket } from 'ngx-socket-io';


@Injectable({
  providedIn: 'root'
})
export class SocketService extends Socket{
  constructor(@Inject('url') private url: string, @Inject('path') private path: string) {
    super({
      url: url,
      options: {
        path: '/' + path,
        transports: ['websocket'],
        upgrade: false
      }
    });
  }
}
