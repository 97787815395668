import { Component, OnInit } from '@angular/core';
import {User} from "../../../core/models/user.model";
import {OtherService} from "../../../core/service/other.service";
import {AmplifyService} from "../../../core/service/amplify-service";
import {UserService} from "../../../core/service/user.service";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  user: User;
  password: string;
  confirmPassword: string;
  verifier: boolean;
  oldPassword: string;
  msgErr: string;

  constructor(public otherService: OtherService, private amplifyService: AmplifyService, private userService: UserService, private messageService: MessageService, private translateService: TranslateService, private router: Router) { }

  ngOnInit(): void {
    this.user = this.otherService.getUser();
    this.password = "";
    this.confirmPassword = "";
    this.verifier = true;
    this.msgErr = "";
  }

  save() {
    this.msgErr = "";
    if (this.confirmPassword == this.password) {
      this.verifier = true;
      this.amplifyService.updatePassword(this.oldPassword, this.password).then(async (data) => {
        this.oldPassword = "";
        this.password = "";
        this.confirmPassword = "";
        if (data == 'SUCCESS') {
          this.user.createdAt = undefined;
          this.user.updatedAt = undefined;
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('msg_updated_data'),
            life: 3000,
          });
          await this.router.navigate(['/home']);
        }
      }).catch((err) => {
        console.log("err: ", err);
        this.msgErr = err.message;
      })
    } else {
      this.verifier = false;
    }
  }

}
