export class Template {
  public _id: string | undefined;

  public company_id: string;
  public name: string;
  public type: string | undefined;
  public render: string | string;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.name = '';
    this.company_id = '';
  }
}
