import {Account} from "./account.model";

export class Operation {
  public _id?: string | undefined;

  public account_id: string;
  public short_desc : string |undefined ;
  public description : string|undefined;
  public operation_date : string | Date | undefined;
  public value_date : string | Date | undefined;
  public amount : number;
  public type: string|undefined;
  public files: string[];
  public comment: string|undefined;
  public bank_name: string|undefined;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.account_id = undefined;
    this.amount = 0;
    this.files = [];

  }
}



