import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { CurrencyService } from '../../../core/service/currency.service';
import { BicService } from '../../../core/service/bic.service';
import { IbanService } from '../../../core/service/iban.service';
import { Account } from '../../../core/models/account.model';
import { AccountService } from '../../../core/service/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '../../../core/models/company.model';
import { CompanyService } from '../../../core/service/company.service';
import { OperationService } from '../../../core/service/operation.service';
import { TranslateService } from "@ngx-translate/core";
import { OtherService } from "../../../core/service/other.service";
import { User } from "../../../core/models/user.model";
import { json } from "stream/consumers";
import { FileService } from "../../../core/service/file.service";
import { File } from "../../../core/models/file.model";
import { Partner } from "../../../core/models/partner.model";
import { Contract } from "../../../core/models/contract.model";
import { Message } from "../../../core/models/message.module";
import { Employee } from "../../../core/models/employee.model";
import { Operation } from "../../../core/models/operation.model";
import { EmployeeService } from "../../../core/service/employee.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DialogService, OperationService, MessageService, CompanyService, AccountService, ConfirmationService, DynamicDialogConfig],

})

export class HomeComponent implements OnInit {

  filteredCurrencies: any[];
  filteredBics: any[];
  bics: any[];
  filteredIbans: any[];
  ibans: any[];
  currencies: any[];
  companyList: string[];
  account: Account;
  accounts: Account[];
  operations: Operation[];
  employees: Employee[];
  selectedAccounts: Account[];
  accountDialog: boolean;
  fileDialog: boolean;
  inCompany: boolean;
  company: Company;
  dockItems: MenuItem[];
  user: User;
  contentFileDialog: boolean;

  //files: File[];
  selectedFiles: File[];
  page: number;
  pageFile: number;
  selectedColumns: any[];
  cols: any[];
  errorsListDialog: any[];
  headerDialogCode: number;
  header: string;
  currency: any;
  bic: any;
  iban: string;
  operation_type: string;
  operation_types: string[];
  company_id: string;

  bank_name: string;
  short_desc: any;
  operation_date: any;
  value_date: any;
  amount: number;
  checkedList: any;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  operation: Operation;
  operation_id: Operation;
  account_id: string;
  companies: Company[];


  constructor(
    public dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private accountService: AccountService,
    private employeeService: EmployeeService,
    private companyService: CompanyService,
    private router: Router,
    private currencyService: CurrencyService,
    private bicService: BicService,
    private ibanService: IbanService,
    private operationService: OperationService,
    public config: DynamicDialogConfig,
    private translateService: TranslateService,
    public otherService: OtherService,
    public fileService: FileService
  ) {
    this.operations = [];
    this.bank_name = undefined;
    this.short_desc = undefined;
    this.operation_date = undefined;
    this.value_date = undefined;
    this.amount = undefined;
  }

  async ngOnInit() {

    this.errorsListDialog = [
      {
        'key': 'company',
        'value': true
      },
      {
        'key': 'name',
        'value': true
      },
      {
        'key': 'country',
        'value': true
      },
      {
        'key': 'address',
        'value': true
      },
      {
        'key': 'currency',
        'value': true
      },
      {
        'key': 'iban',
        'value': true
      },
      {
        'key': 'bic',
        'value': true
      },
      {
        'key': 'bank_name',
        'value': true
      },
      {
        'key': 'files',
        'value': true
      },
      {
        'key': 'operation_types',
        'value': true
      }
    ];
    this.company = new Company();
    this.fileDialog = false;
    this.user = this.otherService.getUser();
    this.accounts = [];
    this.operations = [];
    this.employees = [];
    this.headerDialogCode = 0;
    this.contentFileDialog = false;
    this.header = "";
    this.account = new Account();
    await this.otherService.getCompany().then((company: Company) => {
      this.company = company;
      this.company_id = company.country;
    }).catch(() => {
      this.company = new Company();
    });
    this.account.country = { "name": this.company.country, "code": "" };
    await this.loadCompaniesOfUsers();
    await this.loadAccounts();
    console.log(this.company)

    this.setCols();
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  defineCompany(company: Company) {
    if (company != undefined) {
      this.otherService.setCompany(company);
      this.company = company;
      this.router.navigateByUrl('/home/project');
    }
  }

  async loadCompaniesOfUsers() {
    if (this.user.roles != undefined) {
      let companies = [];
      for (let key of Object.keys(this.user.roles)) {
        companies = companies.concat(this.user.roles[key]);
      }
      let uniqueCompaniesIds = [...new Set(companies)];
      this.companies = [];
      for (let company_id of uniqueCompaniesIds) {
        await this.companyService.getCompanyById(company_id).then((company: Company) => {
          if (company != null) {
            company.createdAt = this.otherService.formatDate(company.createdAt);
            this.companies.push(company);
          }
        });
      }

    }
  }


  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }


  setCols() {
    this.cols = [
      {
        header: this.translateService.instant('amount'),
        field: "amount"
      }
      , {
        header: this.translateService.instant('account_name'),
        field: "name"
      },
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      },
      {
        header: this.translateService.instant('country'),
        field: "company",
        subField: "country"
      },
      {
        header: this.translateService.instant('currency'),
        field: "currency"
      },
      {
        header: this.translateService.instant('bank_iban'),
        field: "iban"
      },
      {
        header: this.translateService.instant('bank_bic'),
        field: "bic"
      },
      {
        header: this.translateService.instant('bank_name'),
        field: "bank_name"
      },
      {
        header: this.translateService.instant('operation_types'),
        field: "operation_types"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('account_name'),
        field: "name"
      },
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      }
    ];
  }

  async loadAccounts() {
    await this.accountService.getAllAccount(10, this.page, this.company._id).then((accounts: Account[]) => {
      this.accounts = accounts;
      //console.log("my accounts",this.accounts);
      this.accounts = accounts.map(item => {
        let account = item
        account.createdAt = this.otherService.formatDate(item.createdAt);
        return account;

      });
    });
  }

  async loadOperations() {
    await this.operationService.getAllOperation(10, this.page, this.account_id, this.short_desc, this.operation_date, this.value_date, this.amount, this.bank_name).then((operations: Operation[]) => {
      this.operations = operations.map(c => {
        let operation = c
        operation.createdAt = this.otherService.formatDate(c.createdAt);
        return operation
      });
    });
    console.log(this.operations)
  }
}

