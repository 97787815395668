import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Partner} from "../models/partner.model";
import {Template} from "../models/template.model";

@Injectable({
  providedIn: 'root'
})

export class TemplateService {
  public templateUrl = '/templates/';

  constructor(private http: HttpClient) { }

  getAllTemplates(companyId: any, type?: any, limit?: any, page?: any,) {
    let params = new HttpParams();
    params = params.append('companyId', companyId);
    if(limit){
      params = params.append('limit' , limit);
    }
    if(page){
      params = params.append('page' , page);
    }
    if(type != undefined){
      params = params.append('type', type);
    }
    return this.http.get<any>(this.templateUrl, {params}).toPromise();
  }

  getTemplateById(id: any) {
    return this.http.get<any>(this.templateUrl + id).toPromise();
  }

  addTemplate(template: Template) {
    return this.http.post(this.templateUrl, template).toPromise();
  }

  updateTemplate(template: Template) {
    return this.http.put(this.templateUrl, template).toPromise();
  }

  deleteTemplate(id: string) {
    return this.http.delete(this.templateUrl + id).toPromise();
  }
}
