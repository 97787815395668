import {Component, Input, OnInit} from '@angular/core';
import {CRA} from "../../../core/models/cra.model";
import {TranslateService} from "@ngx-translate/core";
import {OtherService} from "../../../core/service/other.service";
import {CRAService} from "../../../core/service/cra.service";
import {Company} from "../../../core/models/company.model";
import {MessageService} from "primeng/api";
import {Employee} from "../../../core/models/employee.model";
import {User} from "../../../core/models/user.model";
import {Invoice, IStep} from "../../../core/models/invoice.model";
import {Contract} from "../../../core/models/contract.model";
import {EmployeeService} from "../../../core/service/employee.service";
import {ContractService} from "../../../core/service/contract.service";
import {InvoiceService} from "../../../core/service/invoice.service";

@Component({
  selector: 'app-cra',
  templateUrl: './cra.component.html',
  styleUrls: ['./cra.component.css']
})
export class CraComponent implements OnInit {
  selectedCRAs: CRA[];
  CRAs: CRA[];
  CRA: CRA;
  page: number;
  pageEmployee: number;
  pageInvoice: number;
  pageContract: number;
  selectedColumns: any[];
  errorsListDialog: any[];
  cols: any[];
  company: Company;
  headerDialogCode: number;
  header: string;
  craDialog: boolean;
  checkUploaded: boolean;
  isNotSalary: boolean;
  isRh: boolean;
  isAccounting: boolean;
  loading: boolean;
  employees: Employee[];
  user: User;
  invoices: Invoice[];
  invoice: Invoice;
  contracts: Contract[];
  contract: Contract;
  status: any[];
  selectedStatus: any;

  constructor(private translateService: TranslateService,
              private contractService: ContractService,
              public otherService:OtherService,
              private CRAService: CRAService,
              private messageService: MessageService,
              private invoiceService : InvoiceService,
              private employeeService: EmployeeService) { }

  async ngOnInit() {
    this.loading = true;
    this.setErrorListDialog();
    this.invoice = new Invoice();
    this.contract = new Contract();
    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.company = result
      }else{
        this.company = new Company();
      }
    });
    this.user = this.otherService.getUser();
    this.isNotSalary = (this.user.roles.rh != undefined || this.user.roles.companies != undefined || this.user.roles.accounting != undefined);
    this.isRh = this.user.roles.rh != undefined;
    this.isAccounting = (this.user.roles.accounting != undefined && this.user.roles.accounting.length > 0);
    this.page = 1;
    this.pageEmployee = 1;
    this.pageInvoice = 1;
    this.pageContract = 1;
    //if code header equal to 1 that's mean the dialog should be added else if header code equal to 2 that's mean the dialog should be updated
    this.headerDialogCode = 0;
    this.header = "";
    this.craDialog = false;
    this.checkUploaded = false;
    this.status = [
      {
        code: 0,
        name: await this.translateService.instant('to_verify')
      },
      {
        code: 1,
        name: await this.translateService.instant('valid')
      },
      {
        code: 2,
        name: await this.translateService.instant('not_valid')
      }
    ];
    this.selectedStatus = this.status[0];
    this.invoices = [];

    let employeeId = undefined;
    if(this.isNotSalary){
      await this.otherService.loadEmployees(this.pageEmployee, this.company._id).then((employees: Employee[]) => {
        this.employees = employees;
      });
      if(!this.isRh){
        await this.otherService.loadContracts(this.pageContract, this.company._id).then((contracts: Contract[]) => {
          this.contracts = contracts;
        });
      }
    }else{
      await this.employeeService.findEmployeeByEmail(this.user.email.trim()).then((employee: Employee[]) => {
        if(employee.length > 0){
          this.employees = employee;
          employeeId = employee[0]._id;
        }else{
          this.employees = [];
          this.employees.push(new Employee());
        }
      });
      await this.contractService.findContractByEmployeeId(this.employees[0]._id).then((contracts: Contract[]) => {
        this.contracts = contracts;
      });
    }

    this.setCols();
    await this.loadCRAs(employeeId);
    this.loading = false;
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols(){
    this.cols = [
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      },
      {
        header: this.translateService.instant('employee_name'),
        field: "employee",
        subField: "name"
      },
      {
        header: this.translateService.instant('worked'),
        field: "worked"
      },
      {
        header: this.translateService.instant('leave_took'),
        field: "leave_took"
      },
      {
        header: this.translateService.instant('description'),
        field: "description"
      },
      {
        header: this.translateService.instant('status'),
        field: "status",
        subField: "name"
      },
      {
        header: this.translateService.instant('date_from'),
        field: "from"
      },
      {
        header: this.translateService.instant('date_to'),
        field: "to"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      },
      {
        header: this.translateService.instant('employee_name'),
        field: "employee",
        subField: "name"
      },
      {
        header: this.translateService.instant('worked'),
        field: "worked"
      },
      {
        header: this.translateService.instant('leave_took'),
        field: "leave_took"
      },
      {
        header: this.translateService.instant('date_to'),
        field: "to"
      },
      {
        header: this.translateService.instant('status'),
        field: "status",
        subField: "name"
      }
    ];
  }

  setErrorListDialog(){
    this.errorsListDialog = [
      {
        "key": "company_id",
        "value": true
      },
      {
        "key": "employee_id",
        "value": true
      },
      {
        "key": "company",
        "value": true
      },
      {
        "key": "employee",
        "value": true
      },
      {
        "key": "from",
        "value": true
      },
      {
        "key": "to",
        "value": true
      }
    ]
  }

  async loadCRAs(employeeId? :string) {
    if(this.company._id != undefined){
      await this.otherService.loadCRAs(this.page, this.company._id, employeeId).then((CRAs: CRA[]) => {
        this.CRAs = CRAs;
      });
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  async loadInvoices() {
    if(this.CRA.contract_id != undefined){
      await this.otherService.loadInvoices(this.pageInvoice, this.company._id, this.CRA.contract_id).then((invoices: Invoice[]) => {
        this.invoices = invoices;
      });
    }
  }

  hideCRADialog(){
    this.CRA.createdAt = this.otherService.formatDate(this.CRA.createdAt);
    this.CRA.updatedAt = this.otherService.formatDate(this.CRA.updatedAt);
    this.CRA.to = this.otherService.formatDate(this.CRA.to);
    this.CRA.from = this.otherService.formatDate(this.CRA.from);
    this.craDialog = false;
    this.checkUploaded = false;
  }

  async onUpload(event: any) {
    await this.otherService.ngOnUpload(event, this.company, this.user).then((files: string[]) => {
      this.CRA.files = files;
      this.checkUploaded = true;
    });
  }

  async saveCRA() {
    if(!this.isNotSalary){
      this.CRA.employee = this.employees[0];
    }
    let date_to = new Date(this.CRA.to);
    let date_from = new Date(this.CRA.from);
    this.errorsListDialog[this.otherService.indexErrorsInListFind('from', this.errorsListDialog)].value = !(date_from > date_to) && this.CRA.from != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('to', this.errorsListDialog)].value = !(date_from > date_to) && this.CRA.to != undefined && date_from.getMonth() == date_to.getMonth();
    this.errorsListDialog[this.otherService.indexErrorsInListFind('employee', this.errorsListDialog)].value = this.CRA.employee._id != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('company', this.errorsListDialog)].value = this.company._id != undefined;

    if (this.errorsListDialog.find(elemnt => elemnt.value == false)) {
      console.log(this.errorsListDialog);
      return;
    }

    this.CRA.invoice_id = this.invoice._id;
    this.CRA.contract_id = this.contract._id;

    this.CRA.company = this.company;
    this.CRA.company_id = this.company._id;
    this.CRA.employee_id = this.CRA.employee._id;

    try{
      if (this.headerDialogCode == 1) {
        //add CRA
        await this.CRAService.addCRA(this.CRA).then(async (CRA: CRA) => {
          CRA.createdAt = this.otherService.formatDate(CRA.createdAt);
          CRA.from = this.otherService.formatDate(CRA.from);
          CRA.to = this.otherService.formatDate(CRA.to);
          CRA.updatedAt = this.otherService.formatDate(CRA.updatedAt);
          this.CRAs.unshift(CRA);
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('cra_added'),
            life: 3000,
          });
        })
      } else if (this.headerDialogCode == 2) {
        //update CRA
        this.CRA.status = this.selectedStatus;
        await this.CRAService.updateCRA(this.CRA).then(async (CRA: CRA) => {
          let index = this.CRAs.findIndex(oneCRA => oneCRA._id == CRA._id);
          this.CRA.from = this.otherService.formatDate(CRA.from);
          this.CRA.to = this.otherService.formatDate(CRA.to);
          this.CRA.createdAt = this.otherService.formatDate(CRA.createdAt);
          this.CRA.updatedAt = this.otherService.formatDate(CRA.updatedAt);
          this.CRAs[index] = this.CRA;
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('cra_updated'),
            life: 3000,
          });
        })
      }

      if(this.contract._id != undefined){
        this.CRA.contract_id = this.contract._id;

        this.contract.employee = this.CRA.employee._id;
        await this.contractService.update(this.contract);

        this.CRA.employee.contract.push(this.contract._id);
        await this.employeeService.updateEmployee(this.CRA.employee);
      }
    }catch (err){
      console.log(err)
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    }
    this.hideCRADialog();
  }

  async addCRA() {
    if(this.company._id != undefined){
      this.setErrorListDialog();
      this.checkUploaded = false;
      this.CRA = new CRA();
      this.CRA.status = this.status[0];
      this.headerDialogCode = 1;
      this.header = await this.translateService.instant('add_cra');
      this.craDialog = true;
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  async deleteCRAs() {
    for (let CRA of this.selectedCRAs) {
      await this.deleteOneCRA(CRA);
    }
  }

  async editCRA(oneCRA: CRA) {
    this.setErrorListDialog();
    this.checkUploaded = false;
    this.CRA = oneCRA;
    this.CRA.from = new Date(oneCRA.from);
    this.CRA.to = new Date(oneCRA.to);
    this.headerDialogCode = 2;
    if(this.CRA.contract_id != undefined){
      const index = this.contracts.findIndex(c => c._id == this.CRA.contract_id);
      if(index != -1){
        this.contract = this.contracts[index];
      }else{
        await this.contractService.finOne(this.CRA.contract_id).then((contract: Contract) => {
          this.contract = contract;
        });
      }
    }
    await this.loadInvoices();
    this.header = await this.translateService.instant('update_cra');
    this.craDialog = true;
  }

  async deleteOneCRA(oneCRA: CRA) {
    await this.CRAService.deleteCRA(oneCRA._id).then(async () => {
      let index = this.CRAs.findIndex(cra => cra._id == oneCRA._id);
      this.CRAs.splice(index, 1);
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('cra_deleted'),
        life: 3000,
      });
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    })
  }

  async nextPage() {
    if (this.CRAs.length >= 10) {
      this.page++;
      await this.loadCRAs();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadCRAs();
    }
  }

  async generateInvoice(oneCRA: CRA) {
    if (oneCRA.invoice_id != undefined) {
      this.messageService.add({
        severity: 'info',
        summary: await this.translateService.instant('message_information'),
        detail: await this.translateService.instant('cra_has_invoice'),
        life: 3000,
      });
    }else if(oneCRA.contract_id != undefined){
      const index = this.contracts.findIndex(c => c._id == oneCRA.contract_id);
      let contract;
      if(index != -1){
        contract = this.contracts[index];
      }else{
        await this.contractService.finOne(oneCRA.contract_id).then((contractDB: Contract) => {
          if(contractDB != null){
            contract = contractDB;
          }
        }).catch((err) => {
          contract = null;
        });
      }
      let invoice: Invoice = new Invoice();
      invoice.contract = contract;
      invoice.contract_id = contract._id;
      invoice.company_id = this.company._id;
      let step : IStep = <IStep>{
        status: {
          name: await this.translateService.instant('generated'),
          code: 1
        }
      }
      invoice.steps.push(step);
      await this.invoiceService.insertInvoice(invoice).then(async (invoiceDB: Invoice) => {
        oneCRA.invoice_id = invoiceDB._id;
        await this.CRAService.updateCRA(oneCRA).then(async (CRA: CRA) => {
          const index = this.CRAs.findIndex(c => c._id == oneCRA._id);
          CRA.createdAt = this.otherService.formatDate(CRA.createdAt);
          CRA.from = this.otherService.formatDate(CRA.from);
          CRA.to = this.otherService.formatDate(CRA.to);
          CRA.updatedAt = this.otherService.formatDate(CRA.updatedAt);
          this.CRAs[index] = CRA;
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('invoice_generated'),
            life: 3000,
          });
        });
      })
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('set_contract'),
        life: 3000,
      });
    }
  }

  canGenerateInvoice(CRA: CRA): boolean{
    try{
      if(this.isAccounting && CRA.invoice_id == undefined && CRA.status.code == 1){
        return true;
      }else{
        return false;
      }
    }catch (err){
      return false
    }
  }

  canUpdateContract(CRA: CRA): boolean{
    try {
      if(this.isNotSalary && (CRA.contract_id == undefined || CRA.status.code == 0)){
        return true;
      }else{
        return false;
      }
    }catch(err){
      return false
    }
  }
}
