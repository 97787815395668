import {DialogService} from 'primeng/dynamicdialog';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Company} from '../../../core/models/company.model';
import {CompanyService} from '../../../core/service/company.service';
import {AccountService} from '../../../core/service/account.service';
import {CountryService} from '../../../core/service/countryService';
import {TranslateService} from '@ngx-translate/core';
import {OtherService} from '../../../core/service/other.service';
import {UserService} from '../../../core/service/user.service';
import {Router} from "@angular/router";
import {User} from "../../../core/models/user.model";
import {AccountingService} from "../../../core/service/accounting.service";
import {Accounting} from "../../../core/models/accounting.model";
import {ProofService} from "../../../core/service/proof.service";
import {Proof} from "../../../core/models/proof.model";
import {History} from "../../../core/models/otherModels/proofHistory.model";

@Component({
  selector: 'app-routing',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],

})
export class CompanyComponent implements OnInit {
  countries: any[];
  filteredCountries: any[];
  companyDialog: boolean;
  proofDialog: boolean;
  company: Company;
  companies: Company[];
  selectedCompanies: Company[];
  submitted: boolean;
  errorsListDialog: any;
  loading: boolean;
  page: number;
  pageProof: number;
  tabPanelIndex: number;
  proofStatus: any[];
  selectedColumns: any[];
  cols: any[];
  user: User;
  proof: Proof;
  proofs: Proof[];

  @ViewChild('dt') table: Table;

  constructor(
    public dialogService: DialogService,
    private messageService: MessageService,
    private companyService: CompanyService,
    private confirmationService: ConfirmationService,
    private accountService: AccountService,
    private countryService: CountryService,
    private translate: TranslateService,
    public otherService: OtherService,
    private userService: UserService,
    private accountingService: AccountingService,
    private router: Router,
    private proofService: ProofService
  ) {
    this.companies = [];
  }



  async ngOnInit() {
    this.loading = true;
    this.page = 1;
    this.pageProof = 1;
    this.user = this.otherService.getUser();
    this.setCols();
    this.company = new Company();
    this.proof = new Proof();
    this.proofDialog = false;
    this.companyDialog = false;
    this.tabPanelIndex = 0;

    this.countryService.getCountries().then((countries) => {
      this.countries = countries;
    });

    this.errorsListDialog = [
      {
        'key': 'user',
        'value': true
      },
      {
        'key': 'name',
        'value': true
      },
      {
        'key': 'country',
        'value': true
      },
      {
        'key': 'address',
        'value': true
      },
      {
        'key': 'post_code',
        'value': true
      },
      {
        'key': 'town',
        'value': true
      },
      {
        'key': 'phones',
        'value': true
      },
      {
        'key': 'contact_emails',
        'value': true
      },
      {
        'key': 'invoice_emails',
        'value': true
      },
      {
        'key' : 'signer_first_name',
        'value' : true
      },
      {
        'key' : 'signer_last_name',
        'value' : true
      },
      {
        'key' : 'signer_phone_number',
        'value' : true
      },
      {
        'key' : 'signer_email',
        'value' : true
      }
    ];
    await this.loadCompaniesOfUsers();
    this.loading = false;
  }

  async loadCompanies() {
    await this.companyService.getAllCompany(10, this.page, this.user._id).then((companies: Company[]) => {
      this.companies = companies.map(c => {
        let company = c
        company.createdAt = this.otherService.formatDate(c.createdAt);
        return company
      });
    });
  }

  async loadCompaniesOfUsers() {
    if(this.user.roles != undefined){
      let companies = [];
      for (let key of Object.keys(this.user.roles)) {
        companies = companies.concat(this.user.roles[key]);
      }
      let uniqueCompaniesIds = [...new Set(companies)];
      this.companies = [];
      for (let company_id of uniqueCompaniesIds) {
        await this.companyService.getCompanyById(company_id).then((company: Company) => {
          if(company != null){
            company.createdAt = this.otherService.formatDate(company.createdAt);
            this.companies.push(company);
          }
        });
      }
    }
  }

  async defineCompany(company: Company, reload: boolean) {
    try {
      this.otherService.setCompany(company);
      this.messageService.add({
        severity: 'success',
        summary: await this.translate.instant('msg_successful'),
        detail: await this.translate.instant('msg_company_definition'),
        life: 3000,
      });
      if(reload){
        await this.router.navigate(['/accounting']);
      }
    } catch (err) {
      console.log("The company has not been set up correctly: ", err);
      this.messageService.add({
        severity: 'warning',
        summary: await this.translate.instant('msg_warning'),
        detail: await this.translate.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols(){
    this.cols = [
      {
        header: this.translate.instant('company_id'),
        field: "_id"
      },
      {
        header: this.translate.instant('company_name'),
        field: "name"
      },
      {
        header: this.translate.instant('user'),
        field: "user",
        subField: "userName"
      },
      {
        header: this.translate.instant('address'),
        field: "address"
      },
      {
        header: this.translate.instant('country'),
        field: "country"
      },
      {
        header: this.translate.instant('post_code'),
        field: "post_code"
      },
      {
        header: this.translate.instant('town'),
        field: "town"
      },
      {
        header: this.translate.instant('country_id'),
        field: "country_id"
      },
      {
        header: this.translate.instant('accounting_id'),
        field: "accounting"
      },
      {
        header: this.translate.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translate.instant('company_name'),
        field: "name"
      },
      {
        header: this.translate.instant('user'),
        field: "user",
        subField: "userName"
      },
      {
        header: this.translate.instant('country'),
        field: "country"
      }
    ];
  }

  async saveCompany() {
    this.submitted = true;
    this.company.updatedAt = undefined;
    this.company.createdAt = undefined;

    const countryObject = this.company.country;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('country', this.errorsListDialog)].value = countryObject != "";
    this.errorsListDialog[this.otherService.indexErrorsInListFind('user', this.errorsListDialog)].value = this.company.user != null;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('name', this.errorsListDialog)].value = this.company.name != '';
    this.errorsListDialog[this.otherService.indexErrorsInListFind('post_code', this.errorsListDialog)].value = !isNaN(+this.company.post_code) && this.company.post_code != '';

    if(this.company.signer.firstName != undefined || this.company.signer.lastName != undefined || this.company.signer.email != undefined || this.company.signer.phoneNumber != undefined ){
      this.errorsListDialog[this.otherService.indexErrorsInListFind('signer_first_name', this.errorsListDialog)].value = this.company.signer.firstName != "" && this.company.signer.firstName != undefined;
      this.errorsListDialog[this.otherService.indexErrorsInListFind('signer_last_name', this.errorsListDialog)].value = this.company.signer.lastName != "" && this.company.signer.lastName != undefined;
      this.errorsListDialog[this.otherService.indexErrorsInListFind('signer_email', this.errorsListDialog)].value = this.company.signer.email != "" && this.company.signer.email != undefined;
      this.errorsListDialog[this.otherService.indexErrorsInListFind('signer_phone_number', this.errorsListDialog)].value = this.company.signer.phoneNumber != "" && this.company.signer.phoneNumber != undefined;
    }

    try {
      this.company.country = JSON.parse(JSON.stringify(countryObject)).name.toString();
      this.company.country_id = JSON.parse(JSON.stringify(countryObject)).code.toString();
    } catch (err) {
      return;
    }

    if (this.errorsListDialog.find(elemnt => elemnt.value == false)) {
      console.log(this.errorsListDialog);
      return;
    }

    if (this.company.name.trim()) {
      if (this.company.phones != undefined) {
        this.company.phones = this.otherService.convertStringToArray(this.company.phones.toString());
      }
      if (this.company.contact_emails != undefined) {
        this.company.contact_emails = this.otherService.convertStringToArray(this.company.contact_emails.toString());
      }
      if (this.company.invoice_emails != undefined) {
        this.company.invoice_emails = this.otherService.convertStringToArray(this.company.invoice_emails.toString());
      }

      this.company.user = this.user;

      if (this.company._id) {
        await this.companyService.updateCompany(this.company).then(async () => {
          let index = this.companies.findIndex(c => c._id == this.company._id);
          this.companies[index] = this.company;
          await this.otherService.getCompany().then((result: any) => {
            if (result != false && result._id == this.company._id) {
              localStorage.setItem("companyObject", JSON.stringify(this.company));
            }
          });
          this.messageService.add({
            severity: 'success',
            summary: await this.translate.instant('msg_successful'),
            detail: await this.translate.instant('msg_company_updated'),
            life: 3000
          });
        }).catch(err => console.log(err));
      } else {
        await this.companyService.addCompany(this.company).then(async (data: Company) => {
          this.company._id = data._id;
          this.companies.unshift(this.company);
          if(this.user.roles == undefined){
            this.user.roles = {
              companies : [data._id]
            };
          }else if(this.user.roles.companies == undefined){
            this.user.roles.companies = [data._id];
          }else if(this.user.roles.companies.find(element => element == data._id) == undefined){
            this.user.roles.companies.push(data._id);
          }
          await this.userService.updateUser(this.user);
          this.otherService.setUser(this.user);
          this.messageService.add({
            severity: 'success',
            summary: await this.translate.instant('msg_successful'),
            detail: await this.translate.instant('msg_company_added'),
            life: 3000
          });
        });

      }

      this.companies = [...this.companies];
      this.companyDialog = false;
    }
  }

  async validateProof() {
    await this.otherService.validateProof(this.proof._id).then(async (validate: boolean) => {
      if (!validate) {
        const message = await this.translate.instant('proof_not_validated');
        alert(message);
      }
      this.proofDialog = false;
    })
  }

  searchCountry(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      const country = this.countries[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredCountries = filtered;
  }

  async nextPage() {
    if (this.companies.length >= 10) {
      this.page++;
      await this.loadCompanies();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadCompanies();
    }
  }

  async saveProof(){
    if(this.proof.name == ""){
      this.proof.name = undefined;
      return;
    }
    //post proof and patch company proof
    await this.proofService.addProof(this.proof).then(async (proof: Proof) => {
      await this.companyService.addProof(this.proof.company_id, proof._id).then(async () => {
        this.proofDialog = false;
        this.messageService.add({
          severity: 'success',
          summary: await this.translate.instant('msg_successful'),
          detail: await this.translate.instant('proof_added'),
          life: 3000
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translate.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      })
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translate.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    });
  }

  async addProof(company: Company) {
    this.proof = new Proof();
    this.proofStatus = await this.otherService.getStatusProof();
    await this.otherService.loadProofs(this.pageProof, company._id).then((proofs: Proof[]) => {
      this.proofs = proofs;
    });
    this.proof.company_id = company._id;
    this.tabPanelIndex = 0;
    this.proofDialog = true;
  }

  openNew() {
    this.company = new Company();
    this.submitted = false;
    this.companyDialog = true;
    this.errorsListDialog = [
      {
        'key': 'user',
        'value': true
      },
      {
        'key': 'name',
        'value': true
      },
      {
        'key': 'country',
        'value': true
      },
      {
        'key': 'address',
        'value': true
      },
      {
        'key': 'post_code',
        'value': true
      },
      {
        'key': 'town',
        'value': true
      },
      {
        'key': 'phones',
        'value': true
      },
      {
        'key': 'contact_emails',
        'value': true
      },
      {
        'key': 'invoice_emails',
        'value': true
      },
      {
        'key' : 'signer_first_name',
        'value' : true
      },
      {
        'key' : 'signer_last_name',
        'value' : true
      },
      {
        'key' : 'signer_phone_number',
        'value' : true
      },
      {
        'key' : 'signer_email',
        'value' : true
      }
    ];
  }

  deleteSelectedCompanies() {
    this.confirmationService.confirm({
      message: 'Are you sure to remove the selected companies?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        for (let i = 0; i < this.selectedCompanies.length; i++) {
          await this.companyService.deleteCompany(this.selectedCompanies[i]._id)
        }
        this.companies = this.companies.filter(
          (val) => !this.selectedCompanies.includes(val)
        );
        this.selectedCompanies = null;
        this.messageService.add({
          severity: 'success',
          summary: await this.translate.instant('msg_successful'),
          detail: await this.translate.instant('msg_company_deleted'),
          life: 3000,
        });
      },
    });
  }

  editCompany(company: Company) {
    this.company = {...company};
    this.companyDialog = true;
  }

  deleteCompany(company: Company) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de supprimer ' + company.name + '?',
      header: 'Confirmer',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await this.companyService.deleteCompany(company._id);
        this.companies = this.companies.filter(
          (val) => val._id !== company._id
        );
        // this.company = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Company Deleted',
          life: 3000,
        });
      },
    });
  }

  hideDialog() {
    this.companyDialog = false;
    this.submitted = false;
    this.errorsListDialog = [
      {
        'key': 'user',
        'value': true
      },
      {
        'key': 'name',
        'value': true
      },
      {
        'key': 'country',
        'value': true
      },
      {
        'key': 'address',
        'value': true
      },
      {
        'key': 'post_code',
        'value': true
      },
      {
        'key': 'town',
        'value': true
      },
      {
        'key': 'phones',
        'value': true
      },
      {
        'key': 'contact_emails',
        'value': true
      },
      {
        'key': 'invoice_emails',
        'value': true
      },
      {
        'key' : 'signer_first_name',
        'value' : true
      },
      {
        'key' : 'signer_last_name',
        'value' : true
      },
      {
        'key' : 'signer_phone_number',
        'value' : true
      },
      {
        'key' : 'signer_email',
        'value' : true
      }
    ];
  }

  async createAccounting(company: Company) {
    let accounting = new Accounting(company._id);
    await this.accountingService.insertAccounting(accounting).then(async (returningAccounting: Accounting) => {
      company.accounting = returningAccounting._id;
      if(this.user.roles.accounting == undefined){
        this.user.roles.accounting = [company._id];
      }else if(this.user.roles.accounting.find(element => element == company._id) == undefined){
        this.user.roles.accounting.push(company._id);
      }
      await this.userService.updateUser(this.user);
      this.otherService.setUser(this.user);
      await this.companyService.updateCompany(company).then(async () => {
        await this.defineCompany(company, false);
        await this.router.navigate(['/accounting']);
      })
    })
  }

  deleteAccounting(company: Company){
    if(company.accounting != undefined){
      //get the accounting and delete the company from list companies
      company.accounting = undefined;
      this.companyService.updateCompany(company).then(async () => {
        let index = this.companies.findIndex((c => c._id == company._id));
        this.companies[index] = company;
        this.messageService.add({
          severity: 'success',
          summary: await this.translate.instant('msg_successful'),
          detail: await this.translate.instant('unlinked_accounting'),
          life: 3000
        });
      })
    }
  }

  proofFunction(event: any){
    this.proof = new Proof();
    this.tabPanelIndex = event.index;
  }

  closeProofDialog(){
    this.proofDialog = false;
    this.proof = new Proof();
  }
}

