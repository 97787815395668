import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {ResetComponent} from './login/reset/reset.component';
import {SignupComponent} from './login/signup/signup.component';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'reset', component: ResetComponent},
  {path: 'signup', component: SignupComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {
  public routes = routes;
}
