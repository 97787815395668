import { Injectable } from '@angular/core';
import {Route, Router, UrlSegment } from '@angular/router';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';
import { AmplifyService } from './amplify-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor( public authservice: AmplifyService, public router: Router) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      fetch('../../../config/config.json').then(res => {
        res.json().then((res) => {
          Auth.configure(res.amplify.Auth);
        }).then(() => {
          this.authservice.getCurrentUser()
            .then((user) => {
              resolve(true);
            })
            .catch(async (err) => {
              console.log(err);
              await this.router.navigate(['login']);
              resolve(false);
            }) ;  }) .catch((e) => console.log(e));
      }); });
  }

}
