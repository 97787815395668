export class History{
  public files: string[] | undefined;
  public from: Date | string | undefined;
  public to: Date | string | undefined;
  public status: {code: number, name: string} | undefined;

  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor(status?: any) {
    this.files = [];
    this.status = status;
  }
}
