export class User {
  public _id: string | undefined;

  public userName: any;
  public email: string;
  public email_verified: boolean | undefined;
  public sub: string | undefined;
  public clientId: string | undefined;
  public userPoolId: string | undefined;
  public roles: any;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor(username? : any, email?: string) {
    this.userName = username;
    this.email = email;
  }
}

