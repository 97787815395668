import {Company} from "./company.model";
import {Employee} from "./employee.model";

export class Salary {
  public _id: string | undefined;

  public employee_id: string| undefined;
  public company_id: string| undefined;
  public employee: Employee;
  public company: Company;
  public from: string | Date | undefined;
  public to: string | Date | undefined;
  public global_cost: number | undefined;
  public net_salary:number| undefined;
  public leave_earned:number| undefined;
  public leave_took:number| undefined;
  public details:Map<string,string>| undefined;
  public render:string| undefined;
  public file:string| undefined;
  public proofs: string[] | undefined ;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.employee= new Employee();
    this.company = new Company();
    this.details = new Map<string,string>();
    this.proofs = [];
  }
}
