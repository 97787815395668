import {Company} from "./company.model";

export class Account {
  public _id: string | undefined;

  public company: Company;
  public name: string | undefined;
  public country: any | undefined;
  public address: string | undefined;
  public currency: string | undefined;
  public iban: string | undefined;
  public bic: string | undefined;
  public bank_name: string | undefined;
  public files: string[] | undefined;
  public operation_types: string[] | undefined;
  public operation_types_flat: string | undefined;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.company = new Company();
    this.files = [];
    this.operation_types=[];
    this.operation_types_flat="";
  }
}
