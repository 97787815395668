import {DialogService} from 'primeng/dynamicdialog';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {ConfirmationService, MessageService as PrimengService} from 'primeng/api';
import {Message} from '../../../core/models/message.module';
import {MessageService} from '../../../core/service/message.service';
import {TranslateService} from "@ngx-translate/core";
import {Company} from "../../../core/models/company.model";
import {OtherService} from "../../../core/service/other.service";
import {FileService} from "../../../core/service/file.service";
import {AmplifyService} from "../../../core/service/amplify-service";
import {User} from "../../../core/models/user.model";


@Component({
  selector: 'app-routing',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
  providers: [
    DialogService,
    MessageService,
  ],
})
export class MessageComponent implements OnInit {
  message: Message;
  messages: Message[];
  selectedMessages: Message[];
  company: Company;
  messageDialog: boolean;
  conversationDialog: boolean;
  contentFileDialog: boolean;
  loading: boolean;
  page: number;
  headerDialogCode: number;
  header: string;
  status: string[];
  selectedColumns: any[];
  cols: any[];
  oneMessage: {
    from: string,
    origin: string,
    userId: string,
    content: string,
    createAt: string
  };
  user: User
  responsiveOptions:any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  filesToDisplay: any[];

  @ViewChild('dt') table: Table;

  constructor(
    public translateService: TranslateService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private primengService: PrimengService,
    private confirmationService: ConfirmationService,
    private otherService: OtherService,
    public fileService: FileService) {
  }


  async ngOnInit() {
    this.loading = true;
    this.message = new Message();
    this.contentFileDialog = false;
    await this.otherService.getCompany().then((company: Company) => {
      this.company = company;
    }).catch(() => {
      this.company = new Company();
    });

    this.user = this.otherService.getUser();

    //if code header equal to 1 that's mean the dialog should be add else if header code equal to 2 that's mean the dialog should be update
    this.headerDialogCode = 0;
    this.header = "";
    this.oneMessage = {
      from: "",
      origin: "",
      userId: "",
      content: "",
      createAt: ""
    };
    this.page = 1;

    this.status = [
      await this.translateService.instant('unseen'),
      await this.translateService.instant('seen')
    ];
    this.setCols();
    await this.loadMessages();
    this.loading = false;
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols(){
    this.cols = [
      {
        header: this.translateService.instant('origin'),
        field: "origin"
      },
      {
        header: this.translateService.instant('company_id'),
        field: "company_id"
      },
      {
        header: this.translateService.instant('status'),
        field: "status"
      },
      {
        header: this.translateService.instant('messages'),
        field: "messages"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('origin'),
        field: "origin"
      },
      {
        header: this.translateService.instant('status'),
        field: "status"
      },
      {
        header: this.translateService.instant('messages'),
        field: "messages"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
  }

  addToMessages(){
    this.oneMessage.createAt = new Date().toLocaleString();
    this.oneMessage.userId = this.otherService.getUser()._id;
    this.message.messages.push(this.oneMessage);
    this.oneMessage = {
      from: "",
      origin: "",
      userId: "",
      content: "",
      createAt: ""
    };
  }

  async loadMessages() {
    if(this.company._id != undefined){
      await this.messageService.getAllMessages(10, this.page, this.company._id).then((data) => {
        this.messages = data.map(item => {
          let message = item
          message.createdAt = this.otherService.formatDate(item.createdAt);
          return message
        });
      });
    }else{
      this.primengService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  async displayMessages(message: Message) {
    this.message = message;
    this.message.status = this.status[1];
    this.message.updatedAt = undefined;
    this.message.createdAt = undefined;
    await this.messageService.updateMessage(this.message).then(async (message: Message) => {
      await this.loadMessages();
      this.message = message;
    }).catch((err) => {
      console.log(err);
    })
    this.conversationDialog = true;
  }

  async addMessage() {
    if(this.company._id != undefined){
      this.message = new Message();
      this.oneMessage = {
        from: "",
        origin: "",
        userId: "",
        content: "",
        createAt: ""
      };
      this.headerDialogCode = 1;
      this.header = await this.translateService.instant('add_message');
      this.messageDialog = true;
    }else{
      this.primengService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  deleteSelectedMessages() {
    for(let i=0; i< this.selectedMessages.length; i++){
      this.deleteMessage(this.selectedMessages[i]);
    }
  }

  async editMessage(message: Message) {
    this.message = {...message};
    this.headerDialogCode = 2;
    this.header = await this.translateService.instant('update_message');
    this.messageDialog = true;
  }

  deleteMessage(message: Message) {
    this.messageService.deleteMessage(message._id).then(async () => {
      await this.loadMessages();
      this.primengService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('msg_message_deleted'),
        life: 3000,
      });
    }).catch(async (err) => {
      this.primengService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    })
  }

  hideDialog() {
    this.header = "";
    this.headerDialogCode = 0;
    this.messageDialog = false;
  }

  async saveMessage() {
    this.message.updatedAt = undefined;
    this.message.createdAt = undefined;
    this.message.origin = this.otherService.getUser().userName;
    this.message.status = this.status[0];
    if(this.headerDialogCode == 1){
      this.message.company_id = this.company._id;
      await this.messageService.addMessage(this.message).then(async (message: Message) => {
        this.message.createdAt = this.otherService.formatDate(message.createdAt);
        this.message.updatedAt = this.otherService.formatDate(message.updatedAt);
        this.message._id = message._id;
        this.messages.unshift(this.message);
        this.primengService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_message_added'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.primengService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    }else if(this.headerDialogCode == 2){
      await this.messageService.updateMessage(this.message).then(async () => {
        let index = this.messages.findIndex(message => message._id == this.message._id);
        this.messages[index] = this.message;
        this.primengService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_message_updated'),
          life: 3000,
        });
      }).catch(async (err) => {
        console.log(err);
        this.primengService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      })
    }
    this.hideDialog();
  }

  async nextPage() {
    if (this.messages.length >= 10) {
      this.page++;
      await this.loadMessages();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadMessages();
    }
  }

  async onUpload(event) {
    await this.otherService.ngOnUpload(event, this.company, this.user).then((files: string[]) => {
      this.message.files = files;
    });
  }


  async showFiles(message: Message) {
    let array = [];
    for (let fileId of message.files) {
      await this.otherService.showFiles(fileId).then((fileAws: any) => {
        array.push({source: fileAws.source, name: fileAws.name});
      });
    }
    this.filesToDisplay = array;
    this.contentFileDialog = true;
  }
}
