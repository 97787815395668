import {User} from "./user.model";
import {ISigner} from "./otherModels/signer.model";

export class Company {
  public _id: string | undefined;

  public user: User;
  public name: string;
  public country: string;
  public address: string | undefined;
  public post_code: string | undefined;
  public town: string | undefined;
  public country_id: string;
  public accounting: string | undefined;
  public phones: string[] | undefined;
  public contact_emails: string[] | undefined;
  public invoice_emails: string[] | undefined;
  public hr_emails: string[] | undefined;
  public ngSign_token: string | undefined;
  public signer: ISigner | undefined;
  public ngPayment_token: string | undefined;
  public paymentVendor: number | undefined;
  public employee_proof: string[] | undefined;
  public company_proof: string[] | undefined;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.user = new User();
    this.name = "";
    this.country = "";
    this.country_id = "";
    this.signer = <ISigner>{};
  }
}
