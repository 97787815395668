import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Account} from '../models/account.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public accountUrl = '/accounts/';


  constructor(private http: HttpClient) {
  }

  getAllAccount(limit?:any,page?:any, companyId?: any) {
    let params = new HttpParams();
    if(limit != undefined){
      params = params.append('limit', limit);
    }
    if(page != undefined){
      params = params.append('page', page);
    }
    if(companyId != undefined){
      params = params.append('companyId', companyId);
    }
    return this.http.get<any>(this.accountUrl, {params}).toPromise();
  }

  getAccountById(id: any) {
    return this.http.get<any>(this.accountUrl + id).toPromise();
  }

  addAccount(account: Account) {
    return this.http.post(this.accountUrl, account).toPromise();
  }

  updateAccount(account: Account) {
    return this.http.put(this.accountUrl, account).toPromise();
  }

  deleteAccount(accountId: string) {
    return this.http.delete(this.accountUrl + accountId).toPromise();
  }

  getOperationStatistics(accountId:string){
    return this.http.get(this.accountUrl + accountId +"/statistics").toPromise();
  }





  getOperationStatisticsFromTo(accountId:string , from? :any, to? :any){
    console.log("hii")
    let params = new HttpParams();

    if(from != undefined){
      params = params.append('from', from);
    }
    if(to != undefined){
      params = params.append('to', to);
    }
   // return this.http.get<any>(this.accountUrl  + accountId +"/statistics/" ).toPromise();
    return this.http.get<any>(this.accountUrl  + accountId +"/statistics/", {params}).toPromise();

  }
}
