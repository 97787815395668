import { Injectable } from '@angular/core';
import {User} from '../models/user.model';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userURL = '/users/';

  constructor(private http: HttpClient) {}
  getAllUsers(limit: any, page: any) {
    let params = new HttpParams();
    params = params.append('limit', limit);
    params = params.append('page', page);
    return this.http.get<any>(this.userURL, {params}).toPromise();
  }

  ajoutUser(user: User) {
    return this.http.post(this.userURL, user).toPromise();
  }

  getUserById(id: any) {
    return this.http.get<any>(this.userURL + id).toPromise();
  }

  updateUser(user: User) {
    return this.http.put(this.userURL , user).toPromise();
  }

  deleteUser(user: User) {
    return this.http.delete(this.userURL + user._id).toPromise();
  }

  getUserByUsername(username: string){
    let params = new HttpParams();
    params = params.append('username', username);
    return this.http.get<any>(this.userURL, {params}).toPromise();
  }

  getUserByEmail(email: string){
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.get<any>(this.userURL, {params}).toPromise();
  }

}
