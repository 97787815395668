import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarModule} from 'primeng/sidebar';
import {MenuModule} from 'primeng/menu';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CalendarModule} from 'primeng/calendar';
import {ChipsModule} from 'primeng/chips';
import {DropdownModule} from 'primeng/dropdown';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {FileUploadModule} from 'primeng/fileupload';
import {TabMenuModule} from 'primeng/tabmenu';
import {SliderModule} from 'primeng/slider';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {RatingModule} from 'primeng/rating';
import {PasswordModule} from 'primeng/password';
import {CardModule} from 'primeng/card';
import {ToolbarModule} from 'primeng/toolbar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {AvatarModule} from 'primeng/avatar';
import {BadgeModule} from 'primeng/badge';
import {DockModule} from 'primeng/dock';
import {CompanyComponent} from './company/company.component';
import {HomeComponent} from './home/home.component';
import {AccountComponent} from './account/account.component';
import {OperationComponent} from './account/operation/operation.component';
import {MessageComponent} from './message/message.component';
import {ProtectedRoutingModule} from './protected-routing.module';
import {CoreModule} from '../../core/core.module';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CountryService} from '../../core/service/countryService';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CompanyService} from '../../core/service/company.service';
import {AccountService} from '../../core/service/account.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../app.module';
import { InvoiceComponent } from './invoice/invoice.component';
import { PartnerComponent } from './partner/partner.component';
import {OtherService} from '../../core/service/other.service';
import { ContractComponent } from './contract/contract.component';
import { FileComponent } from './file/file.component';
import {TagModule} from "primeng/tag";
import {EditorModule} from "primeng/editor";
import {GalleriaModule} from "primeng/galleria";
import {PdfViewerModule} from "ng2-pdf-viewer";
import { TemplateComponent } from './template/template.component';
import { SalaryComponent } from './salary/salary.component';
import { EmployeeComponent } from './employee/employee.component';
import {RadioButtonModule} from "primeng/radiobutton";
import {CheckboxModule} from "primeng/checkbox";
import { UserComponent } from './user/user.component';
import {CurrencyService} from "../../core/service/currency.service";
import {BicService} from "../../core/service/bic.service";
import {IbanService} from "../../core/service/iban.service";
import { AccountingComponent } from './accounting/accounting.component';
import {ListboxModule} from "primeng/listbox";
import {TabViewModule} from "primeng/tabview";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import { ProofComponent } from './proof/proof.component';
import { CraComponent } from './cra/cra.component';
import {FieldsetModule} from "primeng/fieldset";
import {ExcelService} from "../../core/service/excel.service"
import { DashboardComponent } from './dashboard/dashboard.component';
import {ChartModule} from "primeng/chart";
import { NotificationComponent } from './notification/notification.component';
import {NotificationService} from "../../core/service/notification.service";
import {ServiceWorkerModule} from "@angular/service-worker";
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {SocketService} from "../../core/service/socket.service";
import {OrderListModule} from "primeng/orderlist";
import { ProjectComponent } from './project/project.component';

@NgModule({
  declarations: [
    CompanyComponent,
    HomeComponent,
    AccountComponent,
    OperationComponent,
    MessageComponent,
    InvoiceComponent,
    PartnerComponent,
    ContractComponent,
    FileComponent,
    SalaryComponent,
    EmployeeComponent,
    TemplateComponent,
    UserComponent,
    AccountingComponent,
    ProjectComponent,
    ProofComponent,
    CraComponent,
    DashboardComponent,
    NotificationComponent,
  ],
  bootstrap: [OperationComponent],
  exports: [],
  providers: [
    CurrencyService,
    BicService,
    IbanService,
    CountryService,
    DialogService,
    MessageService,
    CompanyService,
    ConfirmationService,
    AccountService,
    ExcelService,
    OtherService,
    NotificationService,
    SocketService
  ],
    imports: [
        CoreModule.forRoot(),
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SocketIoModule,
        ProtectedRoutingModule,
        BadgeModule,
        AutoCompleteModule,
        FormsModule,
        HttpClientModule,
        CalendarModule,
        ChipsModule,
        DropdownModule,
        InputMaskModule,
        InputNumberModule,
        CascadeSelectModule,
        MultiSelectModule,
        InputTextareaModule,
        InputTextModule,
        // DynamicDialogModule,
        SidebarModule,
        MenuModule,
        TableModule,
        ToastModule,
        ButtonModule,
        RippleModule,
        FormsModule,
        FileUploadModule,
        TabMenuModule,
        SliderModule,
        ContextMenuModule,
        DialogModule,
        ProgressBarModule,
        RatingModule,
        PasswordModule,
        CardModule,
        CommonModule,
        SidebarModule,
        ReactiveFormsModule,
        ToolbarModule,
        OverlayPanelModule,
        AvatarModule,
        DockModule,
        ConfirmDialogModule,
        TagModule,
        EditorModule,
        GalleriaModule,
        PdfViewerModule,
        RadioButtonModule,
        CheckboxModule,
        ListboxModule,
        TabViewModule,
        ProgressSpinnerModule,
        FieldsetModule,
        ChartModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true
        }),
        OrderListModule
    ]
})
export class ProtectedModule {
}

