export class Message {
  public _id: string | undefined;

  public origin: string;
  public company_id  : string;
  public messages : any[];
  public status: string | undefined;
  public files: string[] | undefined;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.messages = [];
    this.company_id = "";
    this.origin = "";
    this.files = [];
  }
}

