import {Component, Input, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {CurrencyService} from '../../../core/service/currency.service';
import {BicService} from '../../../core/service/bic.service';
import {IbanService} from '../../../core/service/iban.service';
import {Account} from '../../../core/models/account.model';
import {AccountService} from '../../../core/service/account.service';
import {Router} from '@angular/router';
import {Company} from '../../../core/models/company.model';
import {CompanyService} from '../../../core/service/company.service';
import {OperationService} from '../../../core/service/operation.service';
import {TranslateService} from "@ngx-translate/core";
import {OtherService} from "../../../core/service/other.service";
import {User} from "../../../core/models/user.model";
import {FileService} from "../../../core/service/file.service";
import {File} from "../../../core/models/file.model";
import {Operation} from "../../../core/models/operation.model";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  providers: [DialogService, OperationService, MessageService, CompanyService, AccountService, ConfirmationService, DynamicDialogConfig],

})
export class AccountComponent implements OnInit {
  filteredCurrencies: any[];
  filteredBics: any[];
  bics: any[];
  filteredIbans: any[];
  ibans: any[];
  currencies: any[];
  companyList: string [];
  account: Account;
  accounts: Account[];
  selectedAccounts: Account[];
  accountDialog: boolean;
  fileDialog: boolean;
  inCompany: boolean;
  company: Company;
  dockItems: MenuItem[];
  user: User;
  contentFileDialog: boolean;

  //files: File[];
  selectedFiles: File[];
  page: number;
  pageFile: number;
  selectedColumns: any[];
  cols: any[];
  errorsListDialog: any[];
  headerDialogCode: number;
  header: string;
  currency: any;
  bic:any;
  iban: string;
  operation_type: string;
  operation_types: string[];
  company_id: string;
  operations: Operation[];
  bank_name: string;
  short_desc: any;
  operation_date: any;
  value_date: any;
  amount: number;
  checkedList:any;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  private operation: Operation;
  private operation_id: Operation;
  private account_id: string;


  constructor(
    public dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private accountService: AccountService,
    private companyService: CompanyService,
    private router: Router,
    private currencyService: CurrencyService,
    private bicService: BicService,
    private ibanService: IbanService,
    private operationService: OperationService,
    public config: DynamicDialogConfig,
    private translateService: TranslateService,
    public otherService: OtherService,
    //private route: ActivatedRoute,
    public fileService: FileService
  ){
    this.operations = [];
    this.bank_name = undefined;
    this.short_desc = undefined;
    this.operation_date = undefined;
    this.value_date = undefined;
    this.amount = undefined;
  }

  async ngOnInit() {
    this.currencies = [];
    this.bics = [];
    this.ibans = [];

    this.errorsListDialog = [
      {
        'key': 'company',
        'value': true
      },
      {
        'key': 'name',
        'value': true
      },
      {
        'key': 'country',
        'value': true
      },
      {
        'key': 'address',
        'value': true
      },
      {
        'key': 'currency',
        'value': true
      },
      {
        'key': 'iban',
        'value': true
      },
      {
        'key': 'bic',
        'value': true
      },
      {
        'key': 'bank_name',
        'value': true
      },
      {
        'key': 'files',
        'value': true
      },
      {
        'key': 'operation_types',
        'value': true
      }
    ];
    this.company = new Company();
    this.fileDialog = false;
    this.page = 1;
    this.pageFile = 1;
    this.user = this.otherService.getUser();
    this.accounts = [];
     this.headerDialogCode = 0;
    this.contentFileDialog = false;
    this.header = "";
    this.account = new Account();
    await this.otherService.getCompany().then((company: Company) => {
      this.company = company;
      this.company_id = company.country;
      console.log(this.company_id)
    }).catch(() => {
      this.company = new Company();
    });
    this.account.country = {"name": this.company.country , "code": ""};
    await this.loadAccounts();
    this.setCols();
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }



  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols(){
    this.cols = [
     /* {
        header: this.translateService.instant('account_id'),
        field: "_id"
      },*/
      {
        header: this.translateService.instant('account_name'),
        field: "name"
      },
      /*{
        header: this.translateService.instant('company_id'),
        field: "company",
        subField: "_id"
      },*/
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      },
      {
        header: this.translateService.instant('country'),
        field: "company",
        subField: "country"
      },
      {
        header: this.translateService.instant('address'),
        field: "address"
      },
      {
        header: this.translateService.instant('currency'),
        field: "currency"
      },
      {
        header: this.translateService.instant('bank_iban'),
        field: "iban"
      },
      {
        header: this.translateService.instant('bank_bic'),
        field: "bic"
      },
      {
        header: this.translateService.instant('bank_name'),
        field: "bank_name"
      },
      {
        header: this.translateService.instant('files'),
        field: "files"
      },
      {
        header: this.translateService.instant('operation_types'),
        field: "operation_types"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
     /* {
        header: this.translateService.instant('account_id'),
        field: "_id"
      },*/
      {
        header: this.translateService.instant('account_name'),
        field: "name"
      },
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      }/*,
      {
        header: this.translateService.instant('country'),
        field: "company",
        subField: "country"
      }*/
    ];
  }


  async prevPageFile() {
    if (this.pageFile > 1) {
      this.pageFile--;
      await this.otherService.loadFiles(this.pageFile, this.company._id);
    }
  }

  async nextPageFile() {
    if (this.files.length >= 10) {
      this.pageFile++;
      await this.otherService.loadFiles(this.pageFile, this.company._id);
    }
  }

  async nextPage() {
    if (this.accounts.length >= 10) {
      this.page++;
      await this.loadAccounts();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadAccounts();
    }
  }

  async loadAccounts() {
    await this.accountService.getAllAccount(10, this.page, this.company._id).then((accounts: Account[]) => {
       this.accounts = accounts;
      //console.log("my accounts",this.accounts);
      this.accounts = accounts.map(item => {
        let account = item
        account.createdAt = this.otherService.formatDate(item.createdAt);
        return account;

      });
    });
  }

  async openNew() {
    this.currencyService.getCurrencies().then((currencies) => {
      this.currencies = currencies;
    });
    this.bicService.getBics().then((bics) => {
      this.bics = bics;
    });
    this.ibanService.getIbans().then((ibans) => {
      this.ibans = ibans;
    });
    await this.otherService.getCompany().then((company: Company) => {
      this.company = company;
    }).catch(() => {
      console.log("company not found");
      this.company = new Company();
    });

    if(this.company._id != undefined){
      this.account = new Account();
      this.account.operation_types =[];
      this.account.operation_types_flat="bank , buy , sale , taxes , salary_charge , manager";
      this.errorsListDialog = [
        {
          'key': 'company',
          'value': true
        },
        {
          'key': 'name',
          'value': true
        },
        {
          'key': 'country',
          'value': true
        },
        {
          'key': 'address',
          'value': true
        },
        {
          'key': 'currency',
          'value': true
        },
        {
          'key': 'iban',
          'value': true
        },
        {
          'key': 'bic',
          'value': true
        },
        {
          'key': 'bank_name',
          'value': true
        },
        {
          'key': 'files',
          'value': true
        },
        {
          'key': 'operation_types',
          'value': true
        }
      ];
      this.headerDialogCode = 1;
      this.header = await this.translateService.instant('add_account');
      this.accountDialog = true;
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  async deleteSelectedAccounts() {
    for(let account of this.selectedAccounts){
      await this.operationService.getAllOperation(10,1,account._id, undefined, undefined, undefined, undefined, undefined).then(async (operations: Operation[]) => {
        if (operations.length > 0) {
          alert("impossible");
        } else {
          this.confirmationService.confirm({
            message: '\n' + await this.translateService.instant('msg_confirm_deletes'),
            header: await this.translateService.instant('confirm'),
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
              await this.accountService.deleteAccount(account._id);
              await this.loadAccounts();
              this.selectedAccounts = null;
              this.messageService.add({
                severity: 'success',
                summary: await this.translateService.instant('msg_successful'),
                detail: await this.translateService.instant('msg_accounts_deleted'),
                life: 3000,
              });
              this.hideDialogAccount();
            },
          });
        }
      })
    }
  }

  async editAccount(account: Account) {
    this.errorsListDialog = [
      {
        'key': 'company',
        'value': true
      },
      {
        'key': 'name',
        'value': true
      },
      {
        'key': 'country',
        'value': true
      },
      {
        'key': 'address',
        'value': true
      },
      {
        'key': 'currency',
        'value': true
      },
      {
        'key': 'iban',
        'value': true
      },
      {
        'key': 'bic',
        'value': true
      },
      {
        'key': 'bank_name',
        'value': true
      },
      {
        'key': 'files',
        'value': true
      },
      {
        'key': 'operation_types',
        'value': true
      }
    ];
    this.account = {...account};
    this.bic = {name: account.bic};
    this.currency = {cc:"",symbol:"",name: account.currency};
    this.headerDialogCode = 2;
    this.header = await this.translateService.instant('update_account');
    this.accountDialog = true;
  }

  async deleteAccount(account: Account) {
    this.confirmationService.confirm({
      message: await this.translateService.instant('msg_confirm_delete') + ' ' + account.name + '?',
      header: await this.translateService.instant('confirm') ,
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await this.accountService.deleteAccount(account._id).then(async () => {
          let index = this.accounts.findIndex(acc => acc._id == account._id);
          this.accounts.splice(index, 1);
        });
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_account_deleted'),
          life: 3000,
        });
      },
    });
    this.hideDialogAccount();
  }

  hideDialogAccount() {
     this.accountDialog = false;
     this.headerDialogCode = 0;
     this.header = "";
     this.bic={name:""};
     this.currency={cc:"",symbol:"",name:""};
   }

  searchCurrency(event) {
    const filteredCurrencies: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.currencies.length; i++) {
      const currency = this.currencies[i];
      if (currency.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filteredCurrencies.push(currency);
      }
    }

    this.filteredCurrencies = filteredCurrencies;
  }
  searchBic(event) {
    const filteredBics: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.bics.length; i++) {
      const bic = this.bics[i];
      if (bic.name.toUpperCase().indexOf(query.toUpperCase()) == 0) {
        filteredBics.push(bic);
      }
    }
    this.filteredBics = filteredBics;
  }
/*  searchIban(event) {
    const filteredIbans: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.ibans.length; i++) {
      const iban = this.ibans[i];
      if (iban.name.toUpperCase().indexOf(query.toUpperCase()) == 0) {
        filteredIbans.push(iban);
      }
    }
    this.filteredIbans = filteredIbans;
  }*/
  private dashboard: Operation[];
  private accountId: string;
  private o: any;


  async saveAccount() {
    this.account.updatedAt = undefined;
    this.account.createdAt = undefined;
    this.account.company = this.company;
    if(this.currency != undefined){
      this.account.currency = JSON.parse(JSON.stringify(this.currency)).name;
    }
    if(this.bic != undefined){
      this.account.bic = JSON.parse(JSON.stringify(this.bic)).name;
    }


    console.log(this.bic)
    if(this.bic.name == 'BNPAFRPPXXX'){
      try {
       this.account.iban=(this.account.iban.replace(/ /g, ''));
        this.errorsListDialog[this.otherService.indexErrorsInListFind('iban', this.errorsListDialog)].value =this.account.iban.length==27;
      }catch (err){
        this.errorsListDialog[this.otherService.indexErrorsInListFind('iban', this.errorsListDialog)].value = false;
      }
    }else if(this.bic.name == 'BSTUTNTTINT'){
      try {
        this.account.iban=(this.account.iban.replace(/ /g, ''));
        this.errorsListDialog[this.otherService.indexErrorsInListFind('iban', this.errorsListDialog)].value = this.account.iban.length < 34 && this.account.iban.length > 7;
      }catch (err){
        this.errorsListDialog[this.otherService.indexErrorsInListFind('iban', this.errorsListDialog)].value = false;
      }
      console.log( this.account.iban.length);
    }

    this.errorsListDialog[this.otherService.indexErrorsInListFind('name',this.errorsListDialog)].value = this.account.name != '' && this.account.name != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('bic',this.errorsListDialog)].value = this.account.bic != '' && this.account.bic != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('bank_name',this.errorsListDialog)].value = this.account.bank_name != '' && this.account.bank_name != undefined;

    if (this.errorsListDialog.find(elemnt => elemnt.value == false)) {
      console.log(this.errorsListDialog);
      return;
    }

    if(this.account.operation_types_flat != ""){
      this.account.operation_types.push(this.account.operation_types_flat.toString());
      console.log(this.account.operation_types);
      console.log("this.operation_type",this.account.operation_types_flat);
    }

    this.account.operation_types=this.account.operation_types_flat.split(',');
    console.log("this.operation_types_flat",this.account.operation_types_flat);


    if(this.headerDialogCode == 1){
      //add account
      this.accountService.addAccount(this.account).then(async (account: Account) => {
        this.account.createdAt = this.otherService.formatDate(account.createdAt);
        this.account.updatedAt = this.otherService.formatDate(account.updatedAt);
        this.account._id = account._id;

        this.accounts.unshift(this.account);
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_account_added'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    }else if(this.headerDialogCode == 2){
      //update account
      this.accountService.updateAccount(this.account).then(async () => {
        let index = this.accounts.findIndex(account => account._id == this.account._id);
        this.accounts[index] = this.account;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_account_updated'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      })
    }
    this.hideDialogAccount();
  }

  async AddFileDialog() {
    this.accountDialog = !this.accountDialog;
    if (!this.fileDialog) {
      this.selectedFiles = [];
      await this.otherService.loadFiles(this.pageFile, this.company._id);
    }
    this.fileDialog = !this.fileDialog;
  }

  async selectFile() {
    for (let file of this.selectedFiles) {
      this.account.files.push(file._id);
    }
    await this.AddFileDialog();
  }

  async showOperations(account: Account) {
   await this.router.navigate([account._id + '/operation']);
  }

  async showChart(account: Account) {
       await this.router.navigate(['account/' + account._id + '/statistics/']);
     }

  async onUpload(event) {
    await this.otherService.ngOnUpload(event, this.company, this.user).then((files: string[]) => {
      this.account.files = files;
    });
  }
  filesToUploads: File[];
  files:string[];
  filesToDisplay: any[];

  async showFiles(account: Account) {
    let array = [];
    for (let fileId of account.files) {
      await this.otherService.showFiles(fileId).then((fileAws: any) => {
        array.push({source: fileAws.source, name: fileAws.name});
      });
    }
    this.filesToDisplay = array;
    this.contentFileDialog = true;
  }

}

