export class Employee{
  public _id: string | undefined;

  public user: string;
  public company: string;
  public name: string;
  public sex : boolean | undefined;
  public address: string | undefined;
  public post_code: string | undefined;
  public town: string | undefined;
  public country_id: string | undefined;
  public phone: string | undefined;
  public email: string | undefined;
  public roles: any[] | undefined;
  public details: Map<string, string> | undefined;
  public contract: string[] | undefined;
  public contract_type: string | undefined;
  public proofs: string[] | undefined ;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.user = "";
    this.company = "";
    this.name = "";
    this.details = new Map<string, string>();
    this.contract = [];
    this.proofs = [];
  }
}
