import {DialogService} from 'primeng/dynamicdialog';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Partner} from '../../../core/models/partner.model';
import {PartnerService} from '../../../core/service/partner.service';
import {AccountService} from '../../../core/service/account.service';
import {CountryService} from '../../../core/service/countryService';
import {OtherService} from '../../../core/service/other.service';
import {Company} from "../../../core/models/company.model";
import {TranslateService} from "@ngx-translate/core";
import {User} from "../../../core/models/user.model";


@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css'],

})
export class PartnerComponent implements OnInit {
  containerHeight = "500";
  countries: any[];
  filteredCountries: any[];
  partner: Partner;
  partners: Partner[];
  selectedPartners: Partner[];
  errorsListDialog: any[];
  partnerDialog: boolean;
  loading: boolean;
  headerDialog = '';
  headerDialogCode: number;
  page: number;
  selectedColumns: any[];
  cols: any[];
  company: Company;
  countryObject: any;
  user: User;

  @ViewChild('dt') table: Table;
  constructor(
    public dialogService: DialogService,
    private messageService: MessageService,
    private partnerService: PartnerService,
    private confirmationService: ConfirmationService,
    private accountService: AccountService,
    private countryService: CountryService,
    private translateService: TranslateService,
    public otherService: OtherService
  ) {
    this.partners = [];
  }

  async ngOnInit() {
    this.loading = true;
    this.partner = new Partner();
    this.setErrorListDialog();
    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.company = result
      }else{
        this.company = new Company();
      }
    });
    this.partners = [];
    this.page = 1;
    this.headerDialogCode = 0;
    this.partner.country = {"name": this.company.country, "code": ""};
    this.user = this.otherService.getUser();
    await this.countryService.getCountries().then((countries) => {
      this.countries = countries;
    });
    this.setCols();
    await this.loadPartners();
    this.loading = false;
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setErrorListDialog(){
    this.errorsListDialog = [
      {
        'key': 'user',
        'value': true
      },
      {
        'key': 'name',
        'value': true
      },
      {
        'key': 'country',
        'value': true
      },
      {
        'key': 'address',
        'value': true
      },
      {
        'key': 'post_code',
        'value': true
      },
      {
        'key': 'town',
        'value': true
      },
      {
        'key': 'phones',
        'value': true
      },
      {
        'key': 'contact_emails',
        'value': true
      },
      {
        'key': 'invoice_emails',
        'value': true
      },
      {
        'key': 'signer_first_name',
        'value': true
      },
      {
        'key': 'signer_last_name',
        'value': true
      },
      {
        'key': 'signer_phone_number',
        'value': true
      },
      {
        'key': 'signer_email',
        'value': true
      }
    ];
  }

  setCols() {
    this.cols = [
      {
        header: this.translateService.instant('user'),
        field: "user",
        subField: "userName"
      },
      {
        header: this.translateService.instant('name'),
        field: "name"
      },
      {
        header: this.translateService.instant('country'),
        field: "country"
      },
      {
        header: this.translateService.instant('address'),
        field: "address"
      },
      {
        header: this.translateService.instant('post_code'),
        field: "post_code"
      },
      {
        header: this.translateService.instant('town'),
        field: "town"
      },
      {
        header: this.translateService.instant('country_id'),
        field: "country_id"
      },
      {
        header: this.translateService.instant('phones'),
        field: "phones"
      },
      {
        header: this.translateService.instant('contact_emails'),
        field: "contact_emails"
      },
      {
        header: this.translateService.instant('invoice_emails'),
        field: "invoice_emails"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('partner_name'),
        field: "name"
      },
      {
        header: this.translateService.instant('user'),
        field: "user",
        subField: "userName"
      },
      {
        header: this.translateService.instant('country'),
        field: "country"
      }
    ];
  }

  async nextPage() {
    if (this.partners.length >= 10) {
      this.page++;
      await this.loadPartners();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadPartners();
    }
  }

  async savePartner() {
    this.partner.company_id = this.company._id;
    this.partner.user = this.user;

    this.errorsListDialog[this.otherService.indexErrorsInListFind('country', this.errorsListDialog)].value = this.countryObject !== undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('user', this.errorsListDialog)].value = this.partner.user !== null;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('name', this.errorsListDialog)].value = this.partner.name !== "";
    this.errorsListDialog[this.otherService.indexErrorsInListFind('post_code', this.errorsListDialog)].value = !isNaN(+this.partner.post_code) && this.partner.post_code !== "";

    if (this.partner.signer.firstName != undefined || this.partner.signer.lastName != undefined || this.partner.signer.email != undefined || this.partner.signer.phoneNumber != undefined) {
      this.errorsListDialog[this.otherService.indexErrorsInListFind('signer_first_name', this.errorsListDialog)].value = this.partner.signer.firstName != "" && this.partner.signer.firstName != undefined;
      this.errorsListDialog[this.otherService.indexErrorsInListFind('signer_last_name', this.errorsListDialog)].value = this.partner.signer.lastName != "" && this.partner.signer.lastName != undefined;
      this.errorsListDialog[this.otherService.indexErrorsInListFind('signer_email', this.errorsListDialog)].value = this.partner.signer.email != "" && this.partner.signer.email != undefined;
      this.errorsListDialog[this.otherService.indexErrorsInListFind('signer_phone_number', this.errorsListDialog)].value = this.partner.signer.phoneNumber != "" && this.partner.signer.phoneNumber != undefined;
    }

    if (this.errorsListDialog.find(elemnt => elemnt.value === false)) {
      console.log(this.errorsListDialog);
      return;
    }

    try {
      this.partner.country = this.countryObject.name.toString();
      this.partner.country_id = this.countryObject.code.toString();
    } catch (err) {
      console.log(err);
    }

    if (this.partner.phones !== undefined) {
      this.partner.phones = this.otherService.convertStringToArray(this.partner.phones.toString()).map(phone => Number(phone));
    }
    if (this.partner.contact_emails !== undefined) {
      this.partner.contact_emails = this.otherService.convertStringToArray(this.partner.contact_emails.toString());
    }
    if (this.partner.invoice_emails !== undefined) {
      this.partner.invoice_emails = this.otherService.convertStringToArray(this.partner.invoice_emails.toString());
    }

    if (this.partner._id != undefined) {
      await this.partnerService.updatePartner(this.partner).then(async (partner: Partner) => {
        let index = this.partners.findIndex(p => p._id == this.partner._id);
        this.partner.updatedAt = partner.updatedAt;
        this.partners[index] = this.partner;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_partner_updated'),
          life: 3000
        });
      }).catch(async err => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    } else {
      await this.partnerService.addPartner(this.partner).then(async (partner: Partner) => {
        this.partner._id = partner._id;
        this.partner.createdAt = this.otherService.formatDate(partner.createdAt);
        this.partner.updatedAt = this.otherService.formatDate(partner.updatedAt);
        this.partners.unshift(this.partner);
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_partner_created'),
          life: 3000
        });
      }).catch(async err => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    }
    this.partnerDialog = false;
  }

  searchCountry(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      const country = this.countries[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  }

  async addPartner() {
    if (this.company._id != undefined) {
      this.partner = new Partner();
      this.partner.country = {"name": this.company.country, "code": "AF"};
      this.headerDialog = await this.translateService.instant('add_partner');
      this.headerDialogCode = 1;
      this.setErrorListDialog();
      this.partnerDialog = true;
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  async deletePartners() {
    this.confirmationService.confirm({
      message: 'Are you sure to remove the selected partners?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        for (let i = 0; i < this.selectedPartners.length; i++) {
          await this.deleteOnePartner(this.selectedPartners[i]);
        }
        this.selectedPartners = [];
      },
    });
    this.partnerDialog = false;
  }

  async updatePartner(partner: Partner) {
    this.setErrorListDialog();
    this.partner = {...partner};
    this.partnerDialog = true;
  }

  hideDialog() {
    this.partnerDialog = false;
  }

  async deleteOnePartner(partner: Partner) {
    this.partnerService.deletePartner(partner._id).then(async () => {
      let index = this.partners.findIndex(p => p._id == partner._id);
      this.partners.splice(index, 1);
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('msg_partner_deleted'),
        life: 3000,
      });
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    });
    this.partnerDialog = false;
  }

  async loadPartners() {
    if(this.company._id != undefined){
      await this.otherService.loadPartners(this.page, this.company._id).then((partners: Partner[]) => {
        this.partners = partners;
      });
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }


}

