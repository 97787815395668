import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Employee} from "../../../core/models/employee.model";
import {TranslateService} from "@ngx-translate/core";
import {OtherService, ProofStatus} from "../../../core/service/other.service";
import {EmployeeService} from "../../../core/service/employee.service";
import {Company} from "../../../core/models/company.model";
import {MessageService} from "primeng/api";
import {CountryService} from "../../../core/service/countryService";
import {User} from "../../../core/models/user.model";
import {UserService} from "../../../core/service/user.service";
import {Proof} from "../../../core/models/proof.model";
import {ProofService} from "../../../core/service/proof.service";
import {CompanyService} from "../../../core/service/company.service";
import {History} from "../../../core/models/otherModels/proofHistory.model";
import {FileService} from "../../../core/service/file.service";

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  disable: boolean;
  headerDialog: string;
  headerDialogCode: number;
  employees: Employee[];
  selectedEmployees: Employee[];
  page: number;
  selectedColumns: any[];
  cols: any[];
  company: Company;
  employeeDialog: boolean;
  detailsDialog: boolean;
  errorsListDialog: any[];
  proofErrorListDialog: any[];
  employee: Employee;
  countries: any[];
  filteredCountries: any[];
  country: any;
  details: Map<string, string>;
  justDisplayDetails: boolean;
  isNotSalary: boolean;
  key: string
  value: string;
  header: string;
  detailsKeys: any;
  selectedRoles: number[] = [3];
  roles: any[];
  user: User;
  loading: boolean;
  pageProof: number;
  filesToDisplay: any[];
  contentFileDialog: boolean;
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  proof: Proof;
  proofs: Proof[];
  proofStatus: { code: number, name: string }[];
  checkUploadedProof: boolean;
  @ViewChild('fileUpload') fileUpload: any;
  index: number;
  history: History;

  constructor(private countryService: CountryService,
              private userService: UserService,
              private translateService: TranslateService,
              public fileService: FileService,
              public otherService: OtherService,
              private employeeService: EmployeeService,
              private companyService: CompanyService,
              private proofService: ProofService,
              private messageService: MessageService) {
  }

  async ngOnInit() {
    this.checkUploadedProof = false;
    this.disable = false;
    this.page = 1;
    this.loading = true;
    this.employeeDialog = false;
    this.headerDialogCode = 0;
    this.headerDialog = "";
    this.proof = new Proof();
    this.user = this.otherService.getUser();
    this.isNotSalary = (this.user.roles.rh != undefined || this.user.roles.companies != undefined || this.user.roles.accounting != undefined);

    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.company = result
      } else {
        this.company = new Company();
      }
    });
    this.roles = [
      {
        code: 0,
        name: await this.translateService.instant('owner')
      },
      {
        code: 1,
        name: await this.translateService.instant('accounting')
      },
      {
        code: 2,
        name: await this.translateService.instant('rh')
      },
      {
        code: 3,
        name: await this.translateService.instant('salary')
      }
    ]
    this.countryService.getCountries().then((countries) => {
      this.countries = countries;
    });
    //if code header equal to 1 that's mean the dialog should be add else if header code equal to 2 that's mean the dialog should be update
    this.headerDialogCode = 0;
    this.header = "";
    this.key = "";
    this.value = "";
    this.employee = new Employee();
    this.proof = new Proof();
    let status = await this.otherService.getStatusProof();
    this.history = new History(status[0]);
    this.detailsKeys = [];
    this.pageProof = 1;
    this.details = new Map<string, string>();
    await this.loadEmployees();
    this.setErrorListDialog();
    this.setProofErrorListDialog();
    this.setCols();
    this.loading = false;
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols() {
    this.cols = [
      {
        header: this.translateService.instant('employee_name'),
        field: "name"
      },
      {
        header: this.translateService.instant('sex'),
        field: "sex"
      },
      {
        header: this.translateService.instant('address'),
        field: "address"
      },
      {
        header: this.translateService.instant('post_code'),
        field: "post_code"
      },
      {
        header: this.translateService.instant('town'),
        field: "town"
      },
      {
        header: this.translateService.instant('country_id'),
        field: "country_id"
      },
      {
        header: this.translateService.instant('phone'),
        field: "phone"
      },
      {
        header: this.translateService.instant('email'),
        field: "email"
      },
      {
        header: this.translateService.instant('contract_type'),
        field: "contract_type"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('employee_name'),
        field: "name"
      },
      {
        header: this.translateService.instant('sex'),
        field: "sex"
      },
      {
        header: this.translateService.instant('email'),
        field: "email"
      },
      {
        header: this.translateService.instant('phone'),
        field: "phone"
      }
    ];
  }

  async nextPage() {
    if (this.employees.length >= 10) {
      this.page++;
      await this.loadEmployees();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadEmployees();
    }
  }

  setProofErrorListDialog(){
    this.proofErrorListDialog = [
      {
        key: "proof_name",
        value: true
      },
      {
        key : "from",
        value: true
      },
      {
        key : "to",
        value: true
      },
      {
        key: "files",
        value: true
      }
    ]
  }

  setErrorListDialog() {
    this.errorsListDialog = [
      {
        key: "company",
        value: true
      },
      {
        key: "user",
        value: true
      },
      {
        key: "name",
        value: true
      },
      {
        key: 'proofs',
        value: true
      },
      {
        key: "email",
        value: true
      }
    ]
  }

  searchCountry(event) {
    const filtered = [];
    for (let i = 0; i < this.countries.length; i++) {
      const country = this.countries[i];
      if (country.name.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  }

  async loadEmployees() {
    if (this.company._id != undefined) {
      await this.otherService.loadEmployees(this.page, this.company._id).then((employees: Employee[]) => {
        this.employees = employees;
      });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  async editEmployee(employee: Employee) {
    this.disable = false;
    this.setProofErrorListDialog();
    this.headerDialogCode = 2;
    this.employee = employee;
    this.selectedRoles = [];
    for (let role of employee.roles) {
      this.selectedRoles.push(role.code);
    }
    this.country = {"name": this.employee.country_id, "code": this.employee.country_id};
    this.header = await this.translateService.instant('update_employee');
    this.employeeDialog = true;
    this.proofStatus = await this.otherService.getStatusProof();
    this.proofs = [];
    for (let proof_id of this.employee.proofs) {
      await this.proofService.getProofById(proof_id).then((proof: Proof) => {
        this.proofs.push(proof);
      });
    }
    await this.loadEmployees();
  }

  async addEmployee() {
    this.disable = true;
    if (this.company._id != undefined) {
      this.setErrorListDialog();
      this.setProofErrorListDialog();
      this.selectedRoles = [3];
      this.headerDialogCode = 1;
      this.header = await this.translateService.instant('add_employee');
      this.details = new Map<string, string>();
      this.employee = new Employee();
      this.country = undefined;
      this.detailsKeys = [];
      this.employeeDialog = true;
    } else {
      console.log("no company")
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
    await this.loadEmployees();
  }

  deleteSelectedEmployees() {
    for (let employee of this.selectedEmployees) {
      if (!this.deleteOneEmployee(employee)) {
        break;
      }
    }
  }

  async deleteOneEmployee(employee: Employee) {
    await this.employeeService.deleteEmployee(employee._id).then(async () => {
      let index = this.employees.findIndex(e => e._id == employee._id);
      this.employees.splice(index, 1);
      await this.userService.getUserByEmail(employee.email).then(async (user: User[]) => {
        user[0].roles = {}
        user[0].createdAt = undefined;
        user[0].updatedAt = undefined;
        await this.userService.updateUser(user[0]);
      })
      return true;
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
      return false;
    })
  }

  async saveEmployee() {
    let checkProof = await this.saveProofDatabase();
    if(!checkProof){
      this.index = 1;
      return;
    }

    this.errorsListDialog[this.otherService.indexErrorsInListFind('email', this.errorsListDialog)].value = this.employee.email != undefined && this.employee.email != "";
    this.errorsListDialog[this.otherService.indexErrorsInListFind('name', this.errorsListDialog)].value = this.employee.name != undefined && this.employee.name != "";
    this.employee.user = this.otherService.getUser()._id;
    this.employee.company = this.company._id;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('user', this.errorsListDialog)].value = this.employee.user != undefined && this.employee.user != "";
    this.errorsListDialog[this.otherService.indexErrorsInListFind('company', this.errorsListDialog)].value = this.employee.company != undefined && this.employee.company != "";
    if (this.errorsListDialog.find(element => element.value == false)) {
      console.log(this.errorsListDialog);
      this.index = 0;
      return;
    }
    this.loading = true;

    let employeeRoles: any[] = [];
    for (let role of this.selectedRoles) {
      employeeRoles.push(this.roles.find(r => r.code == role));
    }
    this.employee.roles = employeeRoles;
    this.employee.email = this.employee.email.trim().replace(/\s/g, '');

    let employeeCreated = false;
    if (this.headerDialogCode == 1) {
      //add employee
      this.employee.createdAt = undefined;
      this.employee.updatedAt = undefined;
      await this.employeeService.insertEmployee(this.employee).then(async (employee: Employee) => {
        this.employee._id = employee._id;
        this.employee.createdAt = employee.createdAt;
        this.employee.updatedAt = employee.updatedAt;
        this.employees.unshift(this.employee);

        employeeCreated = true;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_employee_added'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      })
    } else if (this.headerDialogCode == 2) {
      //update employee
      await this.employeeService.updateEmployee(this.employee).then(async () => {
        let index = this.employees.findIndex(employee => employee._id == this.employee._id);
        this.employees[index] = this.employee;
        employeeCreated = true;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_employee_updated'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      })
    }

    if (employeeCreated) {
      await this.userService.getUserByEmail(this.employee.email.trim().replace(/\s/g, '')).then(async (oneUser: User[]) => {
        let user;
        oneUser.length == 0 ? user = new User(undefined, this.employee.email.trim().replace(/\s/g, '')) : user = oneUser[0];
        if (user.roles == undefined) {
          user.roles = {};
        }
        for (let role of this.selectedRoles) {
          switch (role) {
            case 0:
              if (user.roles.companies == undefined || user.roles.companies.length == 0) {
                user.roles.companies = [this.company._id];
              } else if (user.roles.companies.find(element => element == this.company._id) == undefined) {
                user.roles.companies.push(this.company._id);
              }
              break;
            case 1:
              if (user.roles.accounting == undefined || user.roles.accounting.length == 0) {
                user.roles.accounting = [this.company._id];
              } else if (user.roles.accounting.find(element => element == this.company._id) == undefined) {
                user.roles.accounting.push(this.company._id);
              }
              break;
            case 2:
              if (user.roles.rh == undefined || user.roles.rh.length == 0) {
                user.roles.rh = [this.company._id];
              } else if (user.roles.rh.find(element => element == this.company._id) == undefined) {
                user.roles.rh.push(this.company._id);
              }
              break;
            case 3:
              if (user.roles.salaries == undefined || user.roles.salaries.length == 0) {
                user.roles.salaries = [this.company._id];
              } else if (user.roles.salaries.find(element => element == this.company._id) == undefined) {
                user.roles.salaries.push(this.company._id);
              }
              break;
          }
        }
        user.createdAt = undefined;
        user.updatedAt = undefined;
        await this.userService.ajoutUser(user);
      });
    }

    this.loading = false;
    await this.hideDialog();
  }

  async hideDialog() {
    this.employeeDialog = false;
    await this.loadEmployees();
  }

  openCloseDialogToAddDetail() {
    this.employeeDialog = !this.employeeDialog;
    this.detailsDialog = !this.detailsDialog;
  }

  addDetail() {
    try {
      this.details.set(this.key.toString().trim(), this.value.toString());
      this.detailsKeys = [...this.details.keys()];
      this.key = "";
      this.value = "";
    } catch (err) {
      console.log(err);
    }
  }

  addDetailsToEmployee() {
    for (let key of this.detailsKeys) {
      console.log(key);
    }
    this.employee.details = this.details;
    this.openCloseDialogToAddDetail();
  }

  async showFiles(proof: Proof) {
    let array = [];
    for (let fileId of proof.histories[proof.histories.length - 1]["h" + (proof.histories.length - 1).toString()].files) {
      await this.otherService.showFiles(fileId).then((fileAws: any) => {
        array.push({source: fileAws.source, name: fileAws.name});
      });
    }
    this.filesToDisplay = array;
    this.contentFileDialog = true;
  }

  async onUpload(event: any) {
    this.checkUploadedProof = false;
    await this.otherService.ngOnUpload(event,this.company,this.user).then((fileId: string[]) => {
      this.history.files = fileId;
      this.errorsListDialog[this.otherService.indexErrorsInListFind('proofs',this.errorsListDialog)].value = true;
      this.checkUploadedProof = true;
    });
  }

  async saveProofDatabase(): Promise<boolean>{
    return new Promise<boolean>(async resolve => {
      await this.otherService.saveProofDatabase(this.proofErrorListDialog, this.history, this.proof, this.proofs, this.company).then(async (proof: Proof) => {
        //update employee proofs
        this.employee.proofs.push(proof._id);
        const index = this.employees.findIndex(employee => employee._id == this.employee._id);
        this.employees[index] = this.employee;
        await this.employeeService.updateEmployee(this.employee);

        //reset data in the UI
        this.proof = new Proof();
        const nameStatus: string = await this.translateService.instant('not_valid');
        this.history = new History({name: nameStatus.toString(), code: ProofStatus.not_valid});
        this.history.from = "";
        this.history.to = "";
        this.checkUploadedProof = false;
        this.fileUpload.clear();
        resolve(true);
      }).catch(() => {
        resolve( false);
      })
    })
  }
}
