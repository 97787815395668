// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  name: 'dev',
  appVersion: require('../../../package.json').version + '',
  production: false,
  endpoint: 'http://facturation-dev-webapp.s3-website-eu-west-1.amazonaws.com',
  timeStamp: '23/02/2022',
  googleAnalyticsId: 'UA-000000000-0'
};
