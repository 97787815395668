import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {Auth} from 'aws-amplify';

if (environment.production) {
  enableProdMode();
}
fetch('./config/config.json').then(res => {
  res.json().then(( result: any ) => {
    Auth.configure(result.amplify.Auth);

    if(result.apiUrl.toString().indexOf("localhost") != -1){
      localStorage.setItem('BASE_URL', result.apiUrl.toString().slice(0, -1));
    }else{
      localStorage.setItem('BASE_URL', result.apiUrl);
    }

    if(result.websocketUrl != undefined && result.websocketUrl.toString().indexOf("localhost") != -1){
      localStorage.setItem('BASE_URL_SOCKET', result.websocketUrl.toString().slice(0, -1));
    }else{
      localStorage.setItem('BASE_URL_SOCKET', result.websocketUrl);
    }

    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.log(err));
  });
}) .catch((e) => console.log(e));

