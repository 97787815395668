import {Component, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CompanyComponent} from './company/company.component';
import {HomeComponent} from './home/home.component';
import {AccountComponent} from './account/account.component';
import {OperationComponent} from './account/operation/operation.component';
import {FileComponent} from "./file/file.component";
import {MessageComponent} from './message/message.component';
import {AuthGuardService} from "../../core/service/auth-guard.service";
import {InvoiceComponent} from "./invoice/invoice.component";
import {PartnerComponent} from "./partner/partner.component";
import {ContractComponent} from "./contract/contract.component";
import {TemplateComponent} from "./template/template.component"
import {SalaryComponent} from "./salary/salary.component";
import {EmployeeComponent} from "./employee/employee.component"
import {UserComponent} from "./user/user.component";
import {AccountingComponent} from "./accounting/accounting.component";
import {ProofComponent} from "./proof/proof.component";
import {CraComponent} from "./cra/cra.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {NotificationComponent} from "./notification/notification.component";
import { ProjectComponent } from './project/project.component';


export const routes: Routes = [

  {
    path: '', children :[
      {path: 'dashboard', canLoad: [AuthGuardService],component: DashboardComponent},
      {path: 'account/:accountId/statistics', canLoad: [AuthGuardService],component: DashboardComponent},
    //  {path: 'account/:accountId/statistics/:from/:to', canLoad: [AuthGuardService],component: DashboardComponent},
      {path: 'company', canLoad: [AuthGuardService],component: CompanyComponent},
      {path: '', component: HomeComponent},
      {path: 'account', canLoad: [AuthGuardService],component: AccountComponent},
      {path: ':id/operation', canLoad: [AuthGuardService],component: OperationComponent},
      {path: 'file', canLoad: [AuthGuardService],component: FileComponent},
      {path: 'message', canLoad: [AuthGuardService],component: MessageComponent},
      {path: 'invoice', canLoad: [AuthGuardService],component: InvoiceComponent},
      {path: 'partner', canLoad: [AuthGuardService], component: PartnerComponent},
      {path: 'contract', canLoad: [AuthGuardService], component: ContractComponent},
      {path: 'template', canLoad: [AuthGuardService], component: TemplateComponent},
      {path: 'salary', canLoad: [AuthGuardService], component: SalaryComponent},
      {path: 'employee', canLoad: [AuthGuardService], component: EmployeeComponent},
      {path: 'accounting', canLoad: [AuthGuardService], component: AccountingComponent},
      {path: 'user', canLoad: [AuthGuardService], component: UserComponent},
      {path: 'proof', canLoad: [AuthGuardService], component: ProofComponent},
      {path: 'project', canLoad: [AuthGuardService], component: ProjectComponent},
      {path: 'cra', canLoad: [AuthGuardService], component: CraComponent},
      {path: 'notification', canLoad: [AuthGuardService], component: NotificationComponent},
      { path: '', redirectTo: '/home', pathMatch: 'full' },
    ]
  },
]

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class ProtectedRoutingModule {
}
