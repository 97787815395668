import {Company} from "./company.model";
import {User} from "./user.model";

export class File{
  public _id: string | undefined;

  public company: Company;
  public type: string | undefined;
  public name: string | undefined;
  public hash: string | undefined;
  public path: string | undefined;
  public amount: number | undefined;
  public owner: User;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.company = new Company();
    this.owner = new User();
  }
}
