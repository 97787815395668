import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/service/auth-guard.service';



export const routes: Routes = [
  {
    path: 'login',
    data: {
      layout: 'empty'
    },
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
  },
  { path: 'home', canLoad: [AuthGuardService], loadChildren: () => import('./modules/protected/protected.module').then(m => m.ProtectedModule) },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})

export class AppRoutingModule { }
