import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Company} from "../models/company.model";
import {Message} from "../models/message.module";


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public companyUrl = '/companies/';


  constructor(private http:HttpClient) {}

  getAllCompany(limit:any, page: any, userId: any){
    let params = new HttpParams();
    params = params.append("limit", limit);
    params = params.append("page", page);
    params = params.append("userId", userId);
    return this.http.get<any>(this.companyUrl, {params}).toPromise();
  }

  getCompanyById(id: any) {
    return this.http.get<any>(this.companyUrl + id).toPromise();
  }

  addCompany(company:Company) {
    return this.http.post(this.companyUrl,company).toPromise();
  }

  updateCompany(company: Company){
    return this.http.put(this.companyUrl, company).toPromise();
  }

  addProof(idCompany: string, idProof: string){
    let params = new HttpParams();
    params = params.append('companyProof', idProof);
    return this.http.patch(this.companyUrl + idCompany, null, {params}).toPromise();
  }

  deleteCompany(companyId: string) {
    return this.http.delete(this.companyUrl + companyId).toPromise();
  }

  getCompanyByAccounting(accountingId: string){
    let params = new HttpParams();
    params = params.append("accountingId", accountingId);
    return this.http.get<any>(this.companyUrl, {params}).toPromise();
  }
}
