export class PayLink{
  public lastname: string;
  public firstname: string;
  public phone: string;
  public email: string;
  public description: string;
  public amount: number;
  public token: string;
  public hash: string;

  constructor() {
  }
}
