import {Contract} from "./contract.model";
import {Map} from "./otherModels/map.model";
import {Payment} from "./otherModels/payment.model";
import {PayLink} from "./otherModels/payLink.model";

export class Invoice{
  public _id: string | undefined;

  public r_invoice_id: string | undefined;
  public company_id: string;
  public contract_id : string;
  public contract : Contract | undefined;
  public steps: IStep[];
  public number: number | undefined;
  public details: Map[] | undefined;
  public amount: number | undefined;
  public vat: number | undefined;
  public total: number | undefined;
  public render: string | undefined;
  public file: string | undefined;
  public deadline: Date | undefined;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.company_id = "";
    this.contract_id = "";
    this.details = [];
    this.steps = [];
  }
}

export interface IStep{
  status: {code: number, name: string};
  payment: Payment;
  payLink: PayLink;
  createdAt?: string;
  updatedAt?: string;
}
