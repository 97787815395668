import {Component, Input, OnInit} from '@angular/core';
import {File} from '../../../core/models/file.model'
import {Company} from "../../../core/models/company.model";
import {CompanyService} from "../../../core/service/company.service";
import {OtherService} from '../../../core/service/other.service'
import {OverlayPanel} from "primeng/overlaypanel";
import {FileService} from "../../../core/service/file.service";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {AmplifyService} from "../../../core/service/amplify-service";

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit {
  selectedFiles: File[];
  filesToUploads: File[];
  files: File[];
  file: File;
  fileDialog: boolean;
  contentFileDialog: boolean;
  loading: boolean;
  companies: Company[];
  errorsListDialog = [];
  public op: OverlayPanel;
  tmpFile: File;
  fileToDisplay: any;
  types: string[];
  page: number;
  selectedColumns: any[];
  cols: any[];
  constructor(private companyService: CompanyService, public translateService: TranslateService, public otherService: OtherService, private amplifyService: AmplifyService, public fileService: FileService, private messageService: MessageService) { }

  async ngOnInit() {
    this.loading = true;
    this.file = new File();
    this.file.owner = this.otherService.getUser();
    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.file.company = result
      }
    });
    this.page =1;
    this.filesToUploads = [];
    this.selectedFiles = [];
    this.files = [];
    this.file.owner = this.otherService.getUser();
    this.fileDialog = false;
    this.contentFileDialog = false;
    this.setCols();
    this.initializeErrorsList();
    this.types = [
      await this.translateService.instant('purchases'),
      await this.translateService.instant('divers'),
      await this.translateService.instant('sales')
    ]
    await this.loadFiles();
    this.loading = false;
  }

  initializeErrorsList(){
    this.errorsListDialog = [
      {
        'key': 'company_id',
        'value': true
      },
      {
        'key': 'type',
        'value': true
      },
      {
        'key': 'name',
        'value': true
      },
      {
        'key': 'path',
        'value': true
      },
      {
        'key': 'owner',
        'value': true
      }
    ];

  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols(){
    this.cols = [
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      },
      {
        header: this.translateService.instant('name'),
        field: "name"
      },
      {
        header: this.translateService.instant('type'),
        field: "type"
      },
      {
        header: this.translateService.instant('path'),
        field: "path"
      },
      {
        header: this.translateService.instant('amount'),
        field: "amount"
      },
      {
        header: this.translateService.instant('owner'),
        field: "owner",
        subField: "userName"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      },
      {
        header: this.translateService.instant('name'),
        field: "name"
      },
      {
        header: this.translateService.instant('type'),
        field: "type"
      },
      {
        header: this.translateService.instant('path'),
        field: "path"
      },
      {
        header: this.translateService.instant('owner'),
        field: "owner",
        subField: "userName"
      }
    ];
  }

  async addFile() {
    await this.otherService.getCompany().then((result: any) => {
      if(result != false){
        this.file.company = result;
      }else{
        throw new Error();
      }
      this.initializeErrorsList();
      this.filesToUploads = [];
      this.fileDialog = true;
    }).catch(async () => {
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    });
  }

  async onUpload(event) {
    this.filesToUploads = event.files;
  }

  deleteFiles(){
    for(let i=0 ; i < this.selectedFiles.length; i++){
      this.deleteOneFile(this.selectedFiles[i]);
    }
  }

  deleteFileFromAWS(file: File): Promise<any>{
    let level = file.path.substr(0,file.path.indexOf("/"));
    return new Promise<any>(((resolve, reject) => {
      this.amplifyService.deleteFile(file.name, file.path.replace(level + "/", ""), level).then((data) => {
        resolve(data);
      }).catch((err) => {
        reject(err);
      })
    }))

  }

  deleteOneFile(file: File){
    this.fileService.deleteFile(file._id).then(async () => {
      let index = this.files.findIndex(f => f._id == file._id);
      this.files.splice(index, 1);
      this.deleteFileFromAWS(file).then(async (data) => {
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_file_deleted'),
          life: 3000,
        });
      }).catch(async (err) => {
        console.log(err);
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      })
    }).catch(async (err) => {
      console.log(err);
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    })
  }

  async showFiles(file: File) {
    await this.otherService.showFiles(file._id).then((data) => {
      this.fileToDisplay = data;
      this.contentFileDialog = true;
    }).catch((err) => {
      console.log(err);
    });
  }

  hideFileDialog(){
    this.fileDialog = false;
    this.file = new File();
    this.file.owner = this.otherService.getUser();
    this.files = [];
  }

  async loadFiles() {
    if(this.file.company._id != undefined){
      await this.fileService.getAllFile(10, this.page, this.file.company._id).then((files) => {
        this.files = files.map(item => {
          let file = item
          file.createdAt = this.otherService.formatDate(item.createdAt);
          return file
        });
      });
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  async nextPage() {
    if (this.files.length >= 10) {
      this.page++;
      await this.loadFiles();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadFiles();
    }
  }

  async saveFile() {
    if(this.file.owner == null){
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_user'),
        life: 3000,
      });
      return;
    }

    this.errorsListDialog[this.otherService.indexErrorsInListFind('name', this.errorsListDialog)].value = this.filesToUploads.length >= 0;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('owner', this.errorsListDialog)].value = this.file.owner != undefined && this.file.owner != null;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('company_id', this.errorsListDialog)].value = this.file.company._id != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('type', this.errorsListDialog)].value = this.file.type != "" && this.file.type != null && this.file.type != undefined;

    if (this.errorsListDialog.find(elemnt => elemnt.value == false)) {
      console.log(this.errorsListDialog);
      return;
    }

    for (let file of this.filesToUploads) {
      let accept = false;
      if (file.type.toString().indexOf("image/") != -1 || file.type.toString().indexOf("pdf") != -1) {
        accept = true;
      } else {
        //don't upload this file
        accept = false;
      }
      //don't accept this file
      this.errorsListDialog[this.otherService.indexErrorsInListFind('name', this.errorsListDialog)].value = accept;
      if (accept) {
        this.file.name = file.name;
        await this.amplifyService.upload(file).then(async (object) => {
          this.file.path = object.path;
          this.file.hash = object.fileHash;
          if (file.type.toString().indexOf("image/") != -1)
          {
            this.file.hash=  this.file.hash + '.'+file.type.substr(6);
          }
          else if(file.type.toString().indexOf("pdf") != -1) {
            this.file.hash=  this.file.hash + '.pdf';
          }
        }, async (err) => {
          console.log(err);
          this.errorsListDialog[this.otherService.indexErrorsInListFind('name', this.errorsListDialog)].value = false;
          this.messageService.add({
            severity: 'Error',
            summary: await this.translateService.instant('msg_error'),
            detail: err.message,
            life: 3000,
          });

        });

        //check if the Dialog for the update data or for the add new data in the database
        await this.fileService.addFile(this.file).then(async (file: File) => {
          this.file._id = file._id;
          this.file.createdAt = this.otherService.formatDate(file.createdAt);
          this.file.updatedAt = this.otherService.formatDate(file.updatedAt);
          this.files.unshift(this.file);
          this.fileDialog = false;
          this.messageService.add({
            severity: 'success',
            summary: await this.translateService.instant('msg_successful'),
            detail: await this.translateService.instant('msg_file_added'),
            life: 3000,
          });
        }).catch(async (err) => {
          console.log(err);
          this.errorsListDialog[this.otherService.indexErrorsInListFind('name', this.errorsListDialog)].value = false;
        })
      }
    }

  }
}
