import {Component, Input, OnInit} from '@angular/core';
import {FileService} from "../../../core/service/file.service";
import {TranslateService} from "@ngx-translate/core";
import {OtherService} from "../../../core/service/other.service";
import {Company} from "../../../core/models/company.model";
import {Proof} from "../../../core/models/proof.model";
import {ProofService} from "../../../core/service/proof.service";
import {MessageService} from "primeng/api";
import {History} from "../../../core/models/otherModels/proofHistory.model";

@Component({
  selector: 'app-proof',
  templateUrl: './proof.component.html',
  styleUrls: ['./proof.component.css']
})
export class ProofComponent implements OnInit {
  page: number
  company: Company;
  proofs: Proof[];
  selectedProofs: Proof[];
  proof: Proof;
  history: History;
  historiesKey: string[];
  histories: any;
  selectedColumns: any[];
  cols: any[];
  headerDialogCode: number;
  headerDialog: string;
  errorsListDialog: any[];
  proofDialog: boolean;
  contentFileDialog: boolean;
  loading: boolean;
  checkUploaded: boolean;
  status: any;
  responsiveOptions:any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  filesToDisplay: any[];

  constructor(public fileService: FileService,
              public translateService: TranslateService,
              public otherService: OtherService,
              private proofService: ProofService,
              private messageService: MessageService) { }

  async ngOnInit() {
    this.loading = true;
    this.page = 1;
    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.company = result
      }else{
        this.company = new Company();
      }
    });
    //if code header equal to 1 that's mean the dialog should be an add function else if header code equal to 2 that's mean the dialog should be an update function
    this.headerDialogCode = 0;
    this.headerDialog = "";
    this.proofDialog = false;
    this.contentFileDialog = false;
    this.historiesKey = [];
    this.status = await this.otherService.getStatusProof();
    this.checkUploaded = false;
    this.setErrorListDialog();
    this.setCols();
    await this.otherService.loadProofs(this.page, this.company._id).then((proofs: Proof[]) => {
      this.proofs = proofs;
    });
    this.loading = false;
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols(){
    this.cols = [
      {
        header: this.translateService.instant('name'),
        field: "name"
      },
      {
        header: this.translateService.instant('date_to'),
        field: "histories",
        subField: "h",
        subSubField: "to"
      },
      {
        header: this.translateService.instant('status'),
        field: "histories",
        subField: "h",
        subSubField: "status",
        subSubSubField: "name"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('name'),
        field: "name"
      },
      {
        header: this.translateService.instant('date_to'),
        field: "histories",
        subField: "h",
        subSubField: "to"
      },
      {
        header: this.translateService.instant('status'),
        field: "histories",
        subField: "h",
        subSubField: "status",
        subSubSubField: "name"
      }
    ];
  }

  setErrorListDialog(){
    this.errorsListDialog = [
      {
        "key": "name",
        "value": true
      },
      {
        "key": "from",
        "value": true
      },
      {
        "key": "to",
        "value": true
      },
      {
        "key": "status",
        "value": true
      },
      {
        "key": "files",
        "value": true
      }
    ]
  }

  changeStatusProof(keyHistory?: any){
    let history: History = this.otherService.changeStatusProof(this.proof, this.histories, this.status, keyHistory);
    this.history.status = history.status;
  }

  async saveHistoryProof(){
    let date_to = new Date(this.history.to).toISOString();
    let date_from = new Date(this.history.from).toISOString();
    this.errorsListDialog[this.otherService.indexErrorsInListFind('from', this.errorsListDialog)].value = !(date_from > date_to) && this.history.from != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('to', this.errorsListDialog)].value = !(date_from > date_to) && this.history.to != undefined;

    if (this.errorsListDialog.find(elemnt => elemnt.value == false)) {
      console.log(this.errorsListDialog);
      return;
    }

    this.history.to = date_to;
    this.history.from = date_from;
    await this.proofService.addHistory(this.proof._id, this.history).then(async () => {
      await this.loadProofs();
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('proof_updated'),
        life: 3000,
      });
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    });
    this.proofDialog = false;
    this.checkUploaded = false;

  }

  async loadProofs() {
    if(this.company._id != undefined){
      await this.otherService.loadProofs(this.page, this.company._id).then((proofs: Proof[]) => {
        this.proofs = proofs;
      });
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  editProof(proof: Proof){
    this.checkUploaded = false;
    this.proof = proof;
    this.historiesKey = [];
    for(let i=0; i< proof.histories.length; i++){
      this.historiesKey.push("h" + i);
    }
    proof.histories.length > 0 ? this.history = proof.histories[proof.histories.length - 1]['h' + (proof.histories.length - 1)] : this.history = new History(this.status[0]);
    if(this.history.from != undefined){
      this.history.from = new Date(this.history.from);
    }
    if(this.history.to != undefined){
      this.history.to = new Date(this.history.to);
    }
    this.histories = new Map(this.proof.histories.map((value, index) => ['h' + index, new Date(value)]));
    this.proofDialog = true;
  }

  async onUpload(event) {
    let user = this.otherService.getUser();
    await this.otherService.ngOnUpload(event, this.company, user).then((files: string[]) => {
      this.history.files = files;
      this.checkUploaded = true;
    });
  }


  async deleteProof(proof: Proof) {
    await this.proofService.deleteProof(proof._id).then(async () => {
      let index = this.proofs.findIndex(p => p._id == proof._id);
      this.proofs.splice(index, 1);
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('proof_deleted'),
        life: 3000,
      });
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    });
  }

  hideProofDialog(){
    this.proof = new Proof();
    this.proofDialog = false;
    this.checkUploaded = false;
  }

  async deleteSelectedProofs() {
    for (let proof of this.selectedProofs) {
      await this.deleteProof(proof);
    }
  }

  async nextPage() {
    if (this.proofs.length >= 10) {
      this.page++;
      await this.loadProofs();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadProofs();
    }
  }

  async showFiles(proof: Proof) {
    let array = [];
    for (let fileId of proof.histories[proof.histories.length - 1]["h"+ (proof.histories.length - 1).toString()].files) {
      await this.otherService.showFiles(fileId).then((fileAws: any) => {
        array.push({source: fileAws.source, name: fileAws.name});
      });
    }
    this.filesToDisplay = array;
    this.contentFileDialog = true;
  }
}
