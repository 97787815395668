import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Invoice} from "../models/invoice.model";
import {PayLink} from "../models/otherModels/payLink.model";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService{
  public invoiceUrl = '/invoices/';

  constructor(private http: HttpClient) {
  }

  getAllInvoices(limit:any,page:any, companyId:any, contractId?: any){
    let params = new HttpParams();
    params = params.append('limit', limit);
    params = params.append('page', page);
    params = params.append('companyId', companyId);
    if(contractId != undefined){
      params = params.append('contractId', contractId);
    }
    return this.http.get<any>(this.invoiceUrl, {params}).toPromise();
  }

  getAllInvoicesByContactId(limit:any,page:any, contractId: any){
    let params = new HttpParams();
    params = params.append('limit', limit);
    params = params.append('page', page);
    params = params.append('contractId', contractId);
    return this.http.get<any>(this.invoiceUrl, {params}).toPromise();
  }

  getInvoiceById(id: string){
    return this.http.get<any>(this.invoiceUrl + id).toPromise();
  }

  updateInvoice(invoice: Invoice){
    return this.http.put(this.invoiceUrl, invoice).toPromise();
  }

  deleteInvoice(id : string){
    return this.http.delete(this.invoiceUrl + id).toPromise();
  }

  insertInvoice(invoice: Invoice){
    return this.http.post(this.invoiceUrl, invoice).toPromise();
  }

  paymentInvoice(invoice: Invoice){
    return this.http.patch(this.invoiceUrl + invoice._id, invoice).toPromise();
  }

  createPaymentLink(invoice: Invoice){
    return this.http.post(this.invoiceUrl + 'create_payment_link', invoice).toPromise();
  }

  checkPayment(id: string, payLink: PayLink){
    let params = new HttpParams();
    params = params.append('hash', payLink.hash);
    return this.http.get<any>(this.invoiceUrl + id +'/check_payment', {params}).toPromise();
  }
}
