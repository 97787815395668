import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent {
  constructor(private translate: TranslateService) {
  }

  async ngOnInit() {
    await this.translate.setDefaultLang('en');
    localStorage.setItem("language", "en");
  }

}
