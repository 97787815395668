import {User} from './user.model';
import {ISigner} from "./otherModels/signer.model";

export class Partner {
  public _id: string | undefined;
  public user: User;
  public name: string;
  public country: any | undefined;
  public address: string | undefined;
  public post_code: string | undefined;
  public town: string | undefined;
  public country_id: string;
  public phones: number[] | undefined;
  public contact_emails: string[] | undefined;
  public invoice_emails: string[] | undefined;
  public hr_emails: string[] | undefined;
  public company_id: string | undefined;
  public signer: ISigner | undefined;
  public details: any | undefined;

  public deletion_date: string |  Date | undefined;
  public createdAt: string | Date | undefined;
  public updatedAt: string | Date | undefined;

  constructor() {
    this.user = new User();
    this.name = '';
    this.country_id = '';
    this.signer = <ISigner>{};
  }


}
