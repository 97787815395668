import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {CRA} from "../models/cra.model";

@Injectable({
  providedIn: 'root'
})
export class CRAService {

  public craUrl = '/cra/';

  constructor(private http:HttpClient) {}

  getAllCRA(limit:any, page: any, companyId: any, employeeId?: any){
    let params = new HttpParams();
    params = params.append("limit", limit);
    params = params.append("page", page);
    params = params.append("companyId", companyId);
    if(employeeId != undefined){
      params = params.append('employeeId', employeeId);
    }
    return this.http.get<any>(this.craUrl, {params}).toPromise();
  }

  getCRAById(id: any) {
    return this.http.get<any>(this.craUrl + id).toPromise();
  }

  addCRA(cra: CRA) {
    return this.http.post(this.craUrl,cra).toPromise();
  }
  updateCRA(cra: CRA){
    return this.http.put(this.craUrl, cra).toPromise();
  }
  deleteCRA(craId: string) {
    return this.http.delete(this.craUrl + craId).toPromise();
  }
}
