import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Contract} from "../../../core/models/contract.model";
import {Map as otherMap} from "../../../core/models/otherModels/map.model";
import {TranslateService} from "@ngx-translate/core";
import {OtherService} from "../../../core/service/other.service";
import {MessageService} from "primeng/api";
import {PartnerService} from "../../../core/service/partner.service";
import {CompanyService} from "../../../core/service/company.service";
import {ContractService} from "../../../core/service/contract.service";
import {Invoice} from "../../../core/models/invoice.model";
import {InvoiceService} from "../../../core/service/invoice.service";
import {Partner} from "../../../core/models/partner.model";
import {Company} from "../../../core/models/company.model";
import {User} from "../../../core/models/user.model";
import {File} from "../../../core/models/file.model";
import {FileService} from "../../../core/service/file.service";
import {Template} from "../../../core/models/template.model";
import {TemplateService} from "../../../core/service/template.service";
import {AmplifyService} from "../../../core/service/amplify-service";
import {IStep} from "../../../core/models/otherModels/step.model";
import {Proof} from "../../../core/models/proof.model";
import {History} from "../../../core/models/otherModels/proofHistory.model";
import {ProofStatus} from "../../../core/service/other.service";
import {ProofService} from "../../../core/service/proof.service";
import {Benefit} from "../../../core/models/otherModels/benefit.model";
import {CurrencyService} from "../../../core/service/currency.service";

export enum ContractStatus {
  created = 0,
  generated = 1,
  to_sign = 2,
  signed = 3,
  closed = 4,
}

enum UploadFile {
  contract = 0,
  proof = 1
}

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {
  selectedContracts: Contract[];
  currencies: any[];
  filteredCurrencies: any[];
  contracts: Contract[];
  contract: Contract;
  contractDialog: boolean;
  checkUploadedContract: boolean;
  checkUploadedProof: boolean;
  headerDialog: string;
  headerDialogCode: number;
  errorsListDialog: any[];
  errorsListDialogBenefit: any[];
  showInvoicesDialog: boolean;
  invoices: Invoice[];
  showDetailsDialog: boolean;
  stepsDialog: boolean;
  steps: IStep[];
  details: otherMap[];
  detail: otherMap;
  justDisplayDetails: boolean;
  partners: Partner[];
  company: Company;
  user: User;
  page:number;
  pageInvoices: number;
  pageTemplate: number;
  index: number;
  contractId: string;
  selectedColumns: any[];
  cols: any[];
  status: any[];
  selectedFile: File;
  uploadedFile: boolean;
  templatesOfContract: Template[];
  templatesOfInvoice: Template[];
  contentFileDialog: boolean;
  fileToDisplay: any;
  loading: boolean;
  responsiveOptions:any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  history: History;
  proof: Proof;
  proofs: Proof[];
  hideProofDialog: boolean
  contractStatus = ContractStatus;
  uploadFile = UploadFile;
  @ViewChild('fileUpload') fileUpload: any;
  benefit: Benefit;
  invoiceTemplate: Template;
  template: Template;

  constructor(private amplifyService: AmplifyService,
              public translateService: TranslateService,
              public otherService:OtherService,
              private messageService: MessageService,
              private partnerService: PartnerService,
              private companyService: CompanyService,
              private contractService: ContractService,
              private invoiceService: InvoiceService,
              public fileService: FileService,
              private proofService: ProofService,
              private currencyService: CurrencyService,
              private templateService: TemplateService) { }

  async ngOnInit() {
    this.contract = new Contract();
    this.benefit = new Benefit();
    this.invoiceTemplate = new Template();
    this.setErrorListDialog();
    this.serErrorListDialogBenefit();
    this.loading = false;
    this.contentFileDialog = false;
    this.stepsDialog = false;
    this.checkUploadedContract = false;
    this.checkUploadedProof = false;
    this.hideProofDialog = false;
    this.contractId = "";
    this.page = 1;
    this.pageInvoices = 1;
    this.pageTemplate = 1;
    this.index = 0;
    this.user = this.otherService.getUser();
    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.company = result
      }else{
        this.company = new Company();
      }
    });
    this.justDisplayDetails = false;
    this.contractDialog = false;
    this.showInvoicesDialog = false;
    this.showDetailsDialog = false;
    this.proofs = [];
    this.selectedContracts = [];
    this.contracts = [];
    this.partners = [];
    this.steps = [];
    this.proof = new Proof();
    this.template = new Template();
    const nameStatus: string = await this.translateService.instant('not_valid');
    this.history = new History({ name: nameStatus.toString(), code: ProofStatus.not_valid});
    this.details = this.contract.details;
    this.detail = new otherMap();
    this.status = [
      {
        name: await this.translateService.instant('created'),
        code: this.contractStatus.created
      },
      {
        name: await this.translateService.instant('generated'),
        code: this.contractStatus.generated
      },
      {
        name: await this.translateService.instant('to_sign'),
        code: this.contractStatus.to_sign
      },
      {
        name: await this.translateService.instant('signed'),
        code: this.contractStatus.signed
      },
      {
        name: await this.translateService.instant('closed'),
        code: this.contractStatus.closed
      }
    ]
    this.setCols();
    await this.loadContracts();
    await this.loadTemplates();
    await this.currencyService.getCurrencies().then((currencies) => {
      this.currencies = currencies;
    });
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols(){
    this.cols = [
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      },
      {
        header: this.translateService.instant('partner_name'),
        field: "partner",
        subField: "name"
      },
      {
        header: this.translateService.instant('name'),
        field: "name"
      },
      {
        header: this.translateService.instant('vat'),
        field: "vat"
      },
      {
        header: this.translateService.instant('file'),
        field: "file"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('company_name'),
        field: "company",
        subField: "name"
      },
      {
        header: this.translateService.instant('partner_name'),
        field: "partner",
        subField: "name"
      },
      {
        header: this.translateService.instant('name'),
        field: "name"
      },
      {
        header: this.translateService.instant('vat'),
        field: "vat"
      }
    ];
  }

  setErrorListDialog(){
    this.errorsListDialog = [
      {
        "key" : "deadline",
        "value" : true
      },
      {
        "key" : "name",
        "value" : true
      },
      {
        "key" : "template",
        "value" : true
      },
      {
        "key" : "companyId",
        "value" : true
      },
      {
        "key" : "partner_id",
        "value" : true
      },
      {
        "key": "company",
        "value" : true
      },
      {
        "key": "partner",
        "value" : true
      },
      {
        "key" : "vat",
        "value" : true
      },
      {
        "key" : "status",
        "value" : true
      },
      {
        "key" : "contract",
        "value": true
      },
      {
        "key": "from",
        "value" : true
      },
      {
        "key" : "to",
        "value" : true
      },
      {
        "key" : "files",
        "value" : true
      },
      {
        "key" : "proof_name",
        "value" : true
      }
    ];
  }

  async loadContracts() {
    if(this.company._id != undefined){
      await this.otherService.loadContracts(this.page, this.company._id).then((contracts: Contract[]) => {
        this.contracts = contracts;
      });
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  async loadTemplates() {
    await this.templateService.getAllTemplates( this.company._id, 'C').then((templates: Template[]) => {
      this.templatesOfContract = templates.map(item => {
        let template = item
        template.createdAt = this.otherService.formatDate(item.createdAt);
        return template
      });
    });
    await this.templateService.getAllTemplates( this.company._id, 'I').then((templates: Template[]) => {
      this.templatesOfInvoice = templates.map(item => {
        let template = item
        template.createdAt = this.otherService.formatDate(item.createdAt);
        return template
      });
    })
  }

  async addProof(contract: Contract) {
    this.contract = contract;
    this.hideProofDialog = true;
    this.headerDialog = await this.translateService.instant('add_proof');
    this.checkUploadedProof = false;
    this.contractDialog = true;
  }

  async addContract(){
    if(this.company._id != undefined){
      this.contract = new Contract();
      this.hideProofDialog = false;
      this.setErrorListDialog();
      this.checkUploadedContract = false;
      this.checkUploadedProof = false;
      this.headerDialog = await this.translateService.instant('add_contract');
      this.contract.company = this.company;
      await this.partnerService.getAllPartner(10,1, this.company._id).then((partners: Partner[]) => {
        this.partners = partners;
      });
      this.contract.steps[this.contract.steps.length - 1].status = this.status[0];
      this.contractDialog = true;
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  deleteContracts() {
    for(let i=0; i < this.selectedContracts.length; i++){
      this.deleteOneContract(this.selectedContracts[i]);
    }
  }

  async nextPage() {
    if (this.contracts.length >= 10) {
      this.page++;
      await this.loadContracts();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadContracts();
    }
  }

  async prevPageInvoice() {
    if (this.pageInvoices > 1) {
      this.pageInvoices--;
      await this.loadInvoices(this.contractId);
    }
  }

  async nextPageInvoice() {
    if (this.invoices.length >= 10) {
      this.pageInvoices++;
      await this.loadInvoices(this.contractId);
    }
  }

  addDetailsToContract(): void {
    if(this.contract.details.length == 0){
      this.contract.details = this.details;
      this.details = [];
      this.showDetailsDialog = false;
      this.justDisplayDetails = false;
      return;
    }
    let findIt = false;
    for(let i=0; i < this.details.length; i++){
      let j = -1;
      while(!findIt && j < this.contract.details.length){
        j++;
        if(this.details[i].key == this.contract.details[j].key){
          this.contract.details[j].value = this.details[i].value;
          findIt = true;
        }
      }
      if(!findIt){
        this.contract.details.push(this.details[i]);
      }
    }
    this.showDetailsDialog = false;
    this.justDisplayDetails = false;
    this.contractDialog = true;
  }

  async hideContractDialog() {
    this.contractDialog = false;
    this.showDetailsDialog = false;
    this.justDisplayDetails = false;
    this.hideProofDialog = false;
    this.contract = new Contract();
    this.proof = new Proof();
    const nameStatus: string = await this.translateService.instant('not_valid');
    this.history = new History({name: nameStatus.toString(), code: ProofStatus.not_valid});
    this.setErrorListDialog();
    this.proofs = [];
    this.template = new Template();
  }

  displayDetailsOfContract(details: otherMap[]): void {
    this.details = details;
    this.justDisplayDetails = true;
    this.showDetailsDialog = true;
  }

  hideDetailsDialog(): void {
    if(!this.justDisplayDetails){
      this.contractDialog = true;
    }
    this.showDetailsDialog = false;
    this.justDisplayDetails = false;
  }

  async saveContract() {
    let checkBenefit = this.checkBenefitData();
    //add contract
    this.contract.company_id = this.contract.company._id;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('companyId', this.errorsListDialog)].value = this.contract.company._id != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('name', this.errorsListDialog)].value = this.contract.name != undefined && this.contract.name != "";
    this.errorsListDialog[this.otherService.indexErrorsInListFind('partner_id', this.errorsListDialog)].value = this.contract.partner._id != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('template', this.errorsListDialog)].value = this.template._id != undefined || this.contract.file != undefined;
    this.errorsListDialog[this.otherService.indexErrorsInListFind('contract', this.errorsListDialog)].value = this.uploadedFile || (this.contract.render != undefined && this.contract.render != "");
    if(this.contract.vat != undefined){
      this.errorsListDialog[this.otherService.indexErrorsInListFind('vat', this.errorsListDialog)].value = this.contract.vat <= 100 && this.contract.vat >= 0;
    }
    if(this.contract.deadline != undefined){
      this.errorsListDialog[this.otherService.indexErrorsInListFind('deadline', this.errorsListDialog)].value = !isNaN(this.contract.deadline) && this.contract.deadline <= 365 && this.contract.deadline >= 0;
    }

    if (!checkBenefit || this.errorsListDialog.find(elemnt => elemnt.value == false)) {
      console.log(this.errorsListDialog);
      return;
    }

    if(this.proofs.length > 0){
      for(let proof of this.proofs){
        this.contract.proofs.push(proof._id);
      }
    }

    if(this.contract.file == undefined){
      this.contract.render = this.otherService.generateRender(this.template, this.contract, 'C');
    }

    this.benefit.invoiceTemplate = this.invoiceTemplate._id;
    this.benefit.deadline? this.benefit.deadline = new Date(this.benefit.deadline).toISOString() : this.benefit.deadline = undefined;
    this.contract.benefit = this.benefit;

    await this.contractService.insert(this.contract, this.template._id).then(async (contract: Contract) => {
      this.contract.createdAt = this.otherService.formatDate(contract.createdAt);
      this.contract.updatedAt = this.otherService.formatDate(contract.updatedAt);
      this.contract.steps[this.contract.steps.length - 1].createdAt = this.otherService.formatDate(contract.steps[contract.steps.length - 1].createdAt);
      this.contract._id = contract._id;
      this.contract.render = contract.render;
      this.contracts.unshift(this.contract);
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('msg_contract_added'),
        life: 3000,
      });
    }).catch(async (err) => {
      console.log(err);
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    });
    this.hideContractDialog();
  }

  async loadInvoices(id: string) {
    await this.invoiceService.getAllInvoicesByContactId(10, this.pageInvoices, id).then((invoices) => {
      this.invoices = invoices;
    }).catch((err) => {
      console.log("error getting invoices: ", err.message);
    });
  }

  async displayInvoices(contract: Contract) {
    this.contractId = contract._id;
    await this.loadInvoices(contract._id);
    this.showInvoicesDialog = true;
  }

  hideInvoicesDialog(): void{
    this.showInvoicesDialog = false;
    this.invoices =[];
  }

  detailsDialog(): void {
    this.details = this.contract.details;
    this.showDetailsDialog = true;
    this.justDisplayDetails = false;
    this.contractDialog = false;
  }

  displayStatusOfContract(steps: IStep[]){
    this.steps = steps.map(item => {
      let step = item
      item.createdAt != undefined ? step.createdAt = this.otherService.formatDate(item.createdAt) : step.createdAt = undefined;
      return step
    });
    this.stepsDialog = true;
  }

  addDetail(): void{
    const existingKeyIndex = this.contract.details.findIndex((detail) => {
      return this.detail.key == detail.key;
    });
    if(existingKeyIndex != -1){
      //modify value
      this.details[existingKeyIndex].value = this.detail.value;
    }else{
      if(this.detail.key != "" && this.detail.value != ""){
        this.details.push(this.detail);
      }
    }
    this.detail = new otherMap();
  }

  async onUpload(event: any, whichFile: number) {
    this.uploadedFile = false;
    await this.otherService.ngOnUpload(event,this.company,this.user).then((fileId: string[]) => {
      if(whichFile == this.uploadFile.contract){
        this.contract.file = fileId[0];
        this.contract.steps.push({...this.contract.steps[this.contract.steps.length - 1]});
        this.contract.steps[this.contract.steps.length - 1].status = this.status[1];
        this.errorsListDialog[this.otherService.indexErrorsInListFind('contract',this.errorsListDialog)].value = true;
        this.checkUploadedContract = true;
      }else{
        this.history.files = fileId;
        this.errorsListDialog[this.otherService.indexErrorsInListFind('files',this.errorsListDialog)].value = true;
        this.checkUploadedProof = true;
      }
      this.uploadedFile = true;
    });
  }

  async sign(contract: Contract) {
    let step = new IStep();
    step.sigConf = contract.steps[contract.steps.length - 1].sigConf;
    step.status = this.status[3];
    step.createdAt = contract.steps[contract.steps.length - 1].createdAt;
    contract.steps.push(step);
    await this.contractService.sign(contract).then(async (data) => {
      if(data.errorCode == 0){
        let index = this.contracts.findIndex(c => c._id == contract._id);
        this.contracts[index] = contract;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('msg_signed_successful'),
          life: 3000,
        });
      }else {
        console.log("nooo");
      }
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    })
  }

  async toSign(contract: Contract) {
    contract.company = this.company;
    if(contract.company.signer != undefined){
      this.loading = true;
      await this.contractService.toSign(contract).then(async () => {
        await this.loadContracts();
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
      this.loading = false;
    }else {
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_signer'),
        life: 3000,
      });
    }
  }

  deleteOneContract(contract: Contract): void{
    contract.steps.push({...contract.steps[contract.steps.length - 1]});
    contract.steps[contract.steps.length - 1].status = this.status[this.status.length - 1];
    this.contractService.update(contract).then(async () => {
      let index = this.contracts.findIndex(cont => cont._id == contract._id);
      this.contracts[index] = contract;
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('msg_contract_deleted'),
        life: 3000,
      });
    }).catch(async (err) => {
      console.log(err);
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    })
  }

  async generateContractFile(contract: Contract) {
    if(contract.render){
      contract.file = await this.otherService.uploadFromRender(contract.render, contract, 'C' ,this.user, this.company);
      const statusName = await this.translateService.instant('generated');
      let step = new IStep()
      step.status = {name: statusName.toString(), code: this.contractStatus.generated}
      contract.steps.push(step);
      await this.contractService.update(contract).then(async (res: Contract) => {
        let index = this.contracts.findIndex(c => c._id == contract._id);
        contract.createdAt = res.createdAt;
        contract.updatedAt = res.updatedAt;
        this.contracts[index] = contract;
        this.messageService.add({
          severity: 'success',
          summary: await this.translateService.instant('msg_successful'),
          detail: await this.translateService.instant('contract_generated'),
          life: 3000,
        });
      }).catch(async (err) => {
        this.messageService.add({
          severity: 'Error',
          summary: await this.translateService.instant('msg_error'),
          detail: err.message,
          life: 3000,
        });
      });
    }
  }

  async showFiles(contract: Contract) {
    if (contract.file != undefined) {
      await this.otherService.showFiles(contract.file).then((data) => {
        this.fileToDisplay = data;
        this.contentFileDialog = true;
      }).catch((err) => {
        console.log(err);
      });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_file'),
        life: 3000,
      });
    }
  }

  changeStatusProof(keyHistory?: any) {
    let histories = new Map(this.proof.histories.map((value, index) => ['h' + index, new Date(value)]));
    let history: History = this.otherService.changeStatusProof(this.proof,histories,this.status, keyHistory);
    this.history.status = history.status;
  }

  checkStatus(contract: Contract, codeStatus?: number): boolean{
    if(codeStatus){
      let check = false;
      try{
        check = contract.steps.length > 0 && contract.steps[contract.steps.length - 1].status.code === codeStatus;
      }catch (err){
        check = false;
      }finally {
        return check;
      }
    }else {
      return true
    }
  }

  serErrorListDialogBenefit(){
    this.errorsListDialogBenefit = [
      {
        key: "name",
        value: true
      },
      {
        key: "reminder",
        value: true
      },
      {
        key: "invoiceTemplate",
        value: true
      },
      {
        key: "rate",
        value: true
      },
      {
        key: "deadline",
        value: true
      }
    ]
  }

  checkBenefitData(): boolean{
    this.serErrorListDialogBenefit();
    if(this.benefit.reminder == true || this.benefit.name != undefined || this.benefit.invoiceTemplate != undefined || this.benefit.rate != undefined){
      this.errorsListDialogBenefit[this.otherService.indexErrorsInListFind('invoiceTemplate', this.errorsListDialogBenefit)].value = this.invoiceTemplate._id != undefined;
      this.errorsListDialogBenefit[this.otherService.indexErrorsInListFind('rate', this.errorsListDialogBenefit)].value = this.benefit.rate != undefined;
      this.errorsListDialogBenefit[this.otherService.indexErrorsInListFind('name', this.errorsListDialogBenefit)].value = this.benefit.name != undefined;
      if (this.errorsListDialogBenefit.find(elemnt => elemnt.value == false)) {
        console.log(this.errorsListDialogBenefit);
        this.index = 2;
        return false;
      }
    }
    return true;
  }

  async saveProofDatabase(): Promise<boolean>{
    return new Promise<boolean>(async resolve => {
      await this.otherService.saveProofDatabase(this.errorsListDialog, this.history, this.proof, this.proofs, this.company).then(async (proof: Proof) => {
        //update contract proofs
        this.contract.proofs.push(proof._id);
        const index = this.contracts.findIndex(contract => contract._id == this.contract._id);
        this.contracts[index] = this.contract;
        await this.contractService.update(this.contract);

        //reset data in the UI
        this.proof = new Proof();
        const nameStatus: string = await this.translateService.instant('not_valid');
        this.history = new History({name: nameStatus.toString(), code: ProofStatus.not_valid});
        this.history.from = "";
        this.history.to = "";
        this.checkUploadedProof = false;
        this.fileUpload.clear();
        resolve(true);
      }).catch(() => {
        resolve( false);
      })
    })
  }

  generateRender(){
    if(this.template._id != undefined){
      this.contract.render = this.otherService.generateRender(this.template, this.contract, 'C')
    }
  }

  searchCurrency(event) {
    const filteredCurrencies: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.currencies.length; i++) {
      const currency = this.currencies[i];
      if (currency.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filteredCurrencies.push(currency);
      }
    }
    this.filteredCurrencies = filteredCurrencies;
  }

  disableUploadFileContract(): boolean{
    try{
      if(this.template._id != undefined || this.contract.render != undefined){
        return true
      }else{
        return false
      }
    }catch (err){
      return false
    }
  }

  checkIfClosed(contract : Contract) : boolean{
    let result = false;
    if(contract.steps.length > 0 && contract.steps[contract.steps.length - 1].status.code == this.contractStatus.closed){
      result = true;
    }
    return result;
  }

}
