import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Proof} from "../models/proof.model";

@Injectable({
  providedIn: 'root'
})
export class ProofService{
  public proofUrl = '/proofs/';

  constructor(private http: HttpClient) { }

  getAllProof(limit:any, page: any, companyId: any){
    let params = new HttpParams();
    params = params.append("limit", limit);
    params = params.append("page", page);
    params = params.append("companyId", companyId);
    return this.http.get<any>(this.proofUrl, {params}).toPromise();
  }

  getProofById(id: any) {
    return this.http.get<any>(this.proofUrl + id).toPromise();
  }

  addProof(proof: Proof) {
    return this.http.post(this.proofUrl, proof).toPromise();
  }

  updateProof(proof: Proof) {
    return this.http.put(this.proofUrl, proof).toPromise();
  }

  deleteProof(id: string) {
    return this.http.delete(this.proofUrl + id).toPromise();
  }

  addHistory(id: string, history: any){
    return this.http.patch(this.proofUrl + id, history).toPromise();
  }

  validateProof(id: string,status: any){
    return this.http.patch(this.proofUrl + id + "/status", status).toPromise();
  }
}
