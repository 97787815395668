import {Component, Input, OnInit} from '@angular/core';
import {Accounting} from "../../../core/models/accounting.model";
import {TranslateService} from "@ngx-translate/core";
import {OtherService} from "../../../core/service/other.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {AccountingService} from "../../../core/service/accounting.service";
import {Company} from "../../../core/models/company.model";
import {CompanyService} from "../../../core/service/company.service";
import {User} from "../../../core/models/user.model";
import {Router} from "@angular/router";
import {Map} from "../../../core/models/otherModels/map.model";


@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.css']
})
export class AccountingComponent implements OnInit {
  manyAccounting: Accounting[];
  selectedManyAccounting: Accounting[]
  accounting: Accounting;
  company: Company;
  selectedColumns: any[];
  cols: any[];
  page: number;
  pageCompany: number;
  companiesDialog: boolean;
  accountingDialog: boolean;
  RHMapDialog: boolean;
  accountingMapDialog: boolean;
  headerDialog: string;
  headerDialogCode: number;
  companies: Company[];
  selectedCompanies: Company[];
  user: User;
  status: any[];

  RHMap: Map;
  RHs: Map[];
  keyRH: string;
  valueRH: string;

  accountingMap: Map;
  allAccounting: Map[];
  keyAccounting: string;
  valueAccounting: string;

  justDisplayMap: boolean;
  loading: boolean;
  heightDialog: string;


  constructor(private router: Router,
              private confirmationService: ConfirmationService,
              private companyService: CompanyService,
              private translateService: TranslateService,
              public otherService: OtherService,
              private accountingService: AccountingService,
              private messageService: MessageService) { }

  async ngOnInit() {
    this.page = 1;
    this.pageCompany = 1;
    this.companiesDialog = false;
    this.justDisplayMap = true;
    this.RHMapDialog = false;
    this.accountingMapDialog = false;
    this.loading = true;

    this.RHs = [];
    this.allAccounting = [];

    this.companies = [];
    this.selectedCompanies = [];
    //if code header equal to 1 that's mean the dialog should be an add else if header code equal to 2 that's mean the dialog should be an update
    this.headerDialogCode = 0;
    this.headerDialog = "";
    this.initializeHeight();
    await this.otherService.getCompany().then((result: any) => {
      if (result != false) {
        this.company = result
      }else{
        this.company = new Company();
      }
    });
    // this.status = [
    //   {
    //     name: await this.translateService.instant(''),
    //     code: 0
    //   },
    //   {
    //     name: await this.translateService.instant(''),
    //     code: 1
    //   }
    // ]
    this.user = this.otherService.getUser();
    this.setCols();
    await this.loadAccounting();
    this.loading = false;
  }

  @Input() get selectedColumnsFunction(): any[] {
    return this.selectedColumns;
  }

  set selectedColumnsFunction(val: any[]) {
    this.selectedColumns = this.cols.filter(col => val.includes(col));
  }

  setCols(){
    this.cols = [
      {
        header: this.translateService.instant('company_id'),
        field: "company"
      },
      {
        header: this.translateService.instant('status'),
        field: "status"
      },
      {
        header: this.translateService.instant('validity'),
        field: "validity"
      },
      {
        header: this.translateService.instant('rh'),
        field: "rh"
      },
      {
        header: this.translateService.instant('accounting'),
        field: "accounting"
      },
      {
        header: this.translateService.instant('createdAt'),
        field: "createdAt"
      }
    ];
    this.selectedColumns = [
      {
        header: this.translateService.instant('status'),
        field: "status"
      },
      {
        header: this.translateService.instant('validity'),
        field: "validity"
      }
    ];
  }

  async loadAccounting() {
    if(this.company._id != undefined){
      await this.otherService.loadAccounting(this.page, this.company._id).then((accounting: Accounting[]) => {
        this.manyAccounting = accounting;
      })
    }else{
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('warning_company_load'),
        life: 4000,
      });
    }
  }

  initializeHeight(){
    this.heightDialog = '300px';
  }

  upHeight(){
    if(this.heightDialog == '300px'){
      this.heightDialog = '650px';
    }else{
      this.heightDialog = '300px';
    }
  }

  async nextPage() {
    if (this.manyAccounting.length >= 10) {
      this.page++;
      await this.loadAccounting();
    }
  }

  async prevPage() {
    if (this.page > 1) {
      this.page--;
      await this.loadAccounting();
    }
  }

  hideAccountingDialog(){
    this.accounting.updatedAt = this.otherService.formatDate(this.accounting.updatedAt);
    this.accounting.createdAt = this.otherService.formatDate(this.accounting.createdAt);
    this.accounting.validity = this.otherService.formatDate(this.accounting.validity);
    this.accountingDialog = false;
  }

  async saveAccounting() {
    //update this.accounting in the DB
    if (this.accounting.validity != undefined) {
      this.accounting.validity = new Date(this.accounting.validity).toISOString();
    }
    await this.accountingService.updateAccounting(this.accounting).then(async (accounting: Accounting) => {
      let index = this.manyAccounting.findIndex(a => a._id == this.accounting._id);
      accounting.createdAt = this.otherService.formatDate(accounting.createdAt);
      accounting.updatedAt = this.otherService.formatDate(accounting.updatedAt);
      accounting.validity = this.otherService.formatDate(accounting.validity);
      this.manyAccounting[index] = accounting;
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('msg_accounting_updated'),
        life: 3000,
      });
    }).catch(async (err) => {
      this.messageService.add({
        severity: 'Error',
        summary: await this.translateService.instant('msg_error'),
        detail: err.message,
        life: 3000,
      });
    });

    this.accounting = undefined;
    this.selectedCompanies = [];
    this.accountingDialog = false;
  }

  async defineAccounting(accounting: Accounting) {
    try {
      localStorage.setItem("accountingObject", JSON.stringify(accounting));
      this.messageService.add({
        severity: 'success',
        summary: await this.translateService.instant('msg_successful'),
        detail: await this.translateService.instant('msg_company_definition'),
        life: 3000,
      });
      await this.router.navigate(['/home']);
    } catch (err) {
      console.log("The company has not been set up correctly: ", err);
      this.messageService.add({
        severity: 'warning',
        summary: await this.translateService.instant('msg_warning'),
        detail: await this.translateService.instant('msg_warning_company'),
        life: 3000,
      });
    }
  }

  async addCompanies() {
    //show companiesDialog
    await this.otherService.loadCompanies(this.pageCompany, this.user._id).then((companies: Company[]) => {
      this.companies = companies;
    });
    this.companiesDialog = true;
  }

  addAccountingMap(){
    try{
      this.accountingMap = new Map();
      this.accountingMap.key = this.keyAccounting;
      this.accountingMap.value = this.valueAccounting;
      this.allAccounting.push(this.accountingMap);
      this.keyAccounting = "";
      this.valueAccounting = "";
    }catch(err){
      console.log(err);
    }
  }

  addRHMap(){
    try{
      this.RHMap = new Map();
      this.RHMap.key = this.keyRH;
      this.RHMap.value = this.valueRH;
      this.RHs.push(this.RHMap);
      this.keyRH = "";
      this.valueRH = "";
    }catch (err){
      console.log(err);
    }
  }

  showRHMapDialog(){
    this.RHMap = new Map();
    this.justDisplayMap = false;
    this.accountingDialog = false;
    this.RHMapDialog = true;
  }

  showAccountingMapDialog(){
    this.accountingMap = new Map();
    this.justDisplayMap = false;
    this.accountingDialog = false;
    this.accountingMapDialog = true;
  }

  displayMap(map: any, field: string){
    this.justDisplayMap = true;
    if(field == "rh"){
      this.RHs = map;
      this.RHMapDialog = true;
    }else if(field == "accounting"){
      this.accountingMap = map;
      this.accountingMapDialog = true;
    }
  }

  async deleteAccounting(accounting: Accounting) {
    this.confirmationService.confirm({
      message: await this.translateService.instant('msg_confirm_delete_accounting') + '?',
      header: await this.translateService.instant('confirm') ,
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await this.companyService.getCompanyByAccounting(accounting._id).then(async (companies: Company[]) => {
          for (let i = 0; i < companies.length; i++) {
            companies[i].accounting = "";
            await this.companyService.updateCompany(companies[i]);
          }
          await this.accountingService.deleteAccounting(accounting._id).then(async () => {
            let index = this.manyAccounting.findIndex(a => a._id == accounting._id);
            this.manyAccounting.splice(index, 1);
            this.messageService.add({
              severity: 'success',
              summary: await this.translateService.instant('msg_successful'),
              detail: await this.translateService.instant('msg_accounting_deleted'),
              life: 3000,
            });
          })
        });
      },
    });
  }

  hideCompaniesDialog(){
    this.companiesDialog = false;
  }

  async saveCompanies() {
    //add many companies selected to array of accounting.companies
    if(this.selectedCompanies.length > 0){
      for (let i = 0; i < this.selectedCompanies.length; i++) {
        if(!this.accounting.companies.find(company_id => this.selectedCompanies[i]._id == company_id)){
          this.accounting.companies.push(this.selectedCompanies[i]._id);
        }
      }
    }
    this.hideCompaniesDialog();
  }

  async updateAccounting(accounting: Accounting) {
    this.accounting = accounting;
    this.accounting.validity = new Date(accounting.validity);
    if (this.accounting.rh != undefined) {
      this.RHs = this.accounting.rh;
    }
    if (this.accounting.accounting != undefined) {
      this.allAccounting = this.accounting.accounting;
    }
    this.headerDialog = await this.translateService.instant('accounting');
    this.accountingDialog = true;
  }

  return(){
    this.accountingDialog = true;
    this.RHMapDialog = false;
    this.accountingMapDialog = false;
  }

  addRHToAccounting(){
    this.accounting.rh = [...this.RHs];
    this.return();
  }

  addAccountingToAccounting(){
    this.accounting.accounting = [...this.allAccounting];
    this.return();
  }

}
