import { Component, OnInit } from '@angular/core';
import {NotificationService} from "../../../core/service/notification.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  public notification: string;
  public notifications: string[] = [];
  public loading: boolean;
  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.loading = false;
    this.notificationService.getMessage().subscribe((message: string) => {
      this.notifications.unshift(message);
    });
  }

  sendMessage(){
    console.log("send message")
  }

}
